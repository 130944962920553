import ProfileCreate from './ProfileCreate'
import ProfileList from './ProfileList'
import ProfileShow from './ProfileShow'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import {ProfileEdit} from "./ProfileEdit";

export default {
	list: ProfileList,
	create: ProfileCreate,
	edit: ProfileEdit,
	show: ProfileShow,
	icon: BusinessCenterIcon
}
