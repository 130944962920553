import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { get } from "lodash";

const useStyles = makeStyles(() => ({
  hamezaveType: {
    width: "3rem",
    filter: "invert(1)",
  },
  pazType: {
    width: "2.5rem",
  },
}));

const MemberTypeField = ({ source, record = {} }) => {
  const type = get(record, source);
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center">
      {type === "hamezave" ? (
        <img
          src={`./assets/images/main_logo_white.png`}
          className={classes.hamezaveType}
          alt={"mezave"}
        />
      ) : (
        <img src={`./assets/images/PAZ.png`} alt={"paz"} className={classes.pazType} />
      )}
    </Grid>
  );
};

export default MemberTypeField;
