import React, {useEffect, useState} from "react";
import {
	ArrayField,
	AutocompleteInput,
	Confirm,
	Datagrid,
	FunctionField,
	NumberInput,
	ReferenceField,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	SingleFieldList,DeleteButton,
	TextField,
	TextInput,
	useGetOne,
	useNotify,
	usePermissions,
	useRefresh,
	useTranslate
} from "react-admin";
import {makeStyles} from '@material-ui/core/styles'
import CustomPage from "../components/CustomPage";
import CreateToolBarWithCustomSave from "../components/CreateToolBarWithCustomSave";
import {
	Box,
	Button,
	Chip,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Modal,
	Paper,
	Select
} from "@material-ui/core";
import {blackColor, greyColor} from "../constants";
import httpClient from "../DataProvider/httpClient";
import {url} from "../config/connection";
import {Cancel, ControlPoint, Delete, Edit, Save} from "@material-ui/icons";
import {useForm} from "react-final-form";


const getByFridgeByStructure = async (fridgeStructureId) => {
	const {json} = await httpClient (url + '/fridge/getByFridgeStructure/' + fridgeStructureId, {
		method: 'GET'
	})
	return json
}
const getByPlanogramByStructure = async (fridgeStructureId) => {
	const {json} = await httpClient (url + '/planogram/getPlanogramByStructure/' + fridgeStructureId, {
		method: 'GET'
	})
	return json
}

const CurrentMiniProfileEdit = ({record, translate, notify, resetMiniProfileRecord, profileId, miniProfiles}) => {
	const existingPlanogramIds = miniProfiles.map (m => m?.planogramId)
	const form = useForm ()
	const [fridgeChoices, setChoices] = useState (null)
	const [included, setIncluded] = useState ([])
	const [, setFridgeStructureId] = useState (0)
	const [show, setShowForm] = useState (false)
	const [planogramChoices, setPlanogramChoices] = useState ([])

	const setForm = async (fridgeStructureId) => {

		setIncluded ([])
		setChoices ([])
		let fridgeChoices = []
		let planogramChoices = []
		if (fridgeStructureId > 0) {
			setFridgeStructureId (0)
			setFridgeStructureId (fridgeStructureId)
			fridgeChoices = await getByFridgeByStructure (fridgeStructureId)
			planogramChoices = await getByPlanogramByStructure (fridgeStructureId)

		}
		else if (record?.planogram?.fridgeStructureId) {
			setFridgeStructureId (0)
			setFridgeStructureId (record?.planogram?.fridgeStructureId)
			fridgeChoices = await getByFridgeByStructure (record?.planogram?.fridgeStructureId)
			planogramChoices = await getByPlanogramByStructure (record?.planogram?.fridgeStructureId)

		}
		if (!record?.id) {
			planogramChoices = planogramChoices.filter (p => !existingPlanogramIds.includes (p.id))
		}
		else {
			planogramChoices = planogramChoices.filter (p => !existingPlanogramIds.includes (p.id))
			planogramChoices.push (record?.planogram)
		}
		planogramChoices = planogramChoices.filter (p => !p.name.includes ('fridge'))
		setPlanogramChoices (planogramChoices)
		if (fridgeChoices.length > 0) {
			const newChoices = fridgeChoices.map (f => {
				return {id: f.id, serial: f.serial, profileId: f.profileId}
			})
			const filteredChoices = newChoices.filter (f => f?.profileId === null || f.profileId === record?.id)
			setChoices (filteredChoices)

		}
		if (record?.fridges?.length > 0) {
			const currFridges = record.fridges
			const includedFridges = currFridges.map (f => {
				return f.serial
			})

			setIncluded (includedFridges)
		}

	}
	useEffect (() => {

		setForm ()

		return () => {

		};
	}, [record]);

	const changePlanogram = (e) => {
		form.change ('planogramId', e.target.value)
	}

	const EditToolbar = () => {
		const refresh = useRefresh ()
		const form = useForm ()
		const saveMiniProfile = async () => {
			const body = form.getState ().values
			try {
				await httpClient (url + '/mini-profile/' + body.id, {method: 'PATCH', body: JSON.stringify (body)})
				setIncluded ([])
				resetMiniProfileRecord ({})
				refresh ()
			} catch (e) {
				notify ('failed to save ' + e, 'error')
			}

		}
		return <div style={{padding: '10px 0px'}}>
			<Button startIcon={<Save/>} style={{margin: '0 15px'}} variant={'contained'}
			        onClick={(e) => saveMiniProfile (e)} color="primary">
				{translate ('general.save')}
			</Button>
			<Button startIcon={<Cancel/>} style={{margin: '0 5px'}} variant={'outlined'} onClick={() => {
				resetMiniProfileRecord ({})
				setIncluded ([])
			}} color="inhoerit">
				{translate ('general.cancel')}
			</Button>

		</div>
	}
	const CreateToolbar = () => {
		const refresh = useRefresh ()
		const form = useForm ()
		const saveMiniProfile = async () => {
			form.change ('profileId', profileId)
			const body = form.getState ().values

			try {
				await httpClient (url + '/mini-profile', {method: 'POST', body: JSON.stringify (body)})
				setShowForm (!show)
				refresh ()
			} catch (error) {

				notify ('Failed to save ' + error, 'error')
			}

		}
		return <div style={{padding: '10px 0px'}}>
			<Button startIcon={<Save/>} style={{margin: '0 15px'}} variant={'contained'}
			        onClick={(e) => saveMiniProfile (e)} color="primary">
				{translate ('general.save')}
			</Button>
			<Button startIcon={<Cancel/>} style={{margin: '0 5px'}} variant={'outlined'}
			        onClick={() => setShowForm (!show)} color="inherit">
				{translate ('general.cancel')}

			</Button></div>
	}

	const showForm = () => {
		setShowForm (true)
	}

	if (record.id > 0) {
		return <div>
			<Button onClick={() => showForm ()} color={'default'}
			        endIcon={<ControlPoint/>}> {translate ('ra.action.add')}</Button>
			<Modal open={true} className={'flex-column justify-center'}>
				<Box>
					<Paper>
						<SimpleForm toolbar={<EditToolbar/>} record={record} onSubmit={''}>

							<ReferenceInput
								label={translate ('reference.fridgeStructure')}
								source="planogram.fridgeStructureId"
								reference="fridge-structure"
								enableGetChoices={() => false}
								disabled
							>
								<AutocompleteInput optionText="name" validate={required ()} optionValue="id"/>
							</ReferenceInput>
							<SelectInput choices={planogramChoices} initialValue={record?.planogram?.id}
							             source={'planogramId'} validate={required ()}
							             onChange={e => changePlanogram (e)} label={translate ('reference.planogram')}/>
							<MultiSelect translate={translate} choices={fridgeChoices || []}
							             includedFridges={included}/>


						</SimpleForm>
					</Paper>
				</Box>
			</Modal></div>
	}
	else {
		return <div>

			<Button onClick={() => showForm ()} color={'secondary'} variant={'contained'}
			        endIcon={<ControlPoint/>}> {translate ('ra.action.add')}</Button>
			{show && <Modal className={'flex-column justify-center'} open={true}>
				<Box>
					<Paper>
						<SimpleForm toolbar={<CreateToolbar/>} record={record} onSubmit={''}>
							<ReferenceInput
								label={translate ('reference.fridgeStructure')}
								source="fridgeStructure.id"
								reference="fridge-structure"
								onChange={(e) => setForm (e)}
							>
								<AutocompleteInput validate={required ()} optionText="name" optionValue="id"/>
							</ReferenceInput>

							<SelectInput choices={planogramChoices} label={translate ('reference.planogram')}
							             source={'planogram'} validate={required ()}
							             onChange={e => changePlanogram (e)}/>
							<MultiSelect translate={translate} choices={fridgeChoices || []}
							             includedFridges={included}/>


						</SimpleForm></Paper>
				</Box>
			</Modal>
			}</div>
	}
}
const MultiSelect = ({translate, includedFridges, choices}) => {
	const [selectedFridges, setSelectedFridges] = useState ([])
	const [selectAll, setSelectAll] = useState (false)
	const form = useForm ()

	useEffect (() => {
		setSelectedFridges (includedFridges)
		form.change ('serials', includedFridges)

	}, [includedFridges]);

	const handleChange = (event) => {
		const {
			target: {value}
		} = event;
		const hasAll = value.find (v => v === 'all')
		const unselect = value.find (v => v === 'unselect')
		if (hasAll === 'all') {
			const serials = choices.map (f => f.serial)
			setSelectedFridges (serials)
			setSelectAll (true)
			form.change ('serials', serials)
		}
		else if (unselect) {
			setSelectAll (false)
			setSelectedFridges ([]);
			form.change ('serials', [])

		}
		else {
			setSelectedFridges (value);
			form.change ('serials', value)

		}

	}

	if (choices.length > 0) {
		return <div className={'flexcol'}>
			<FormControl marginNormal fullWidth>
				<InputLabel id="fridges-label">{translate ('reference.fridges')}</InputLabel>
				<Select style={{minWidth: '100%'}} autoWidth={false} variant={'filled'} onChange={handleChange} multiple
				        value={selectedFridges} validate={required ()}

				        renderValue={selected => {
							if(selected.length>10){
								return  <div>{translate('general.moreThan10')}</div>
							}
					        return <ul className={'flex-align'}>{selected.map (i => <span style={{
						        margin: '2px 0',
						        backgroundColor: greyColor,
						        color: blackColor,
						        padding: '1%',
						        textAlign: 'center',
						        borderRadius: 20
					        }}>{i}</span>)}</ul>
				        }}>
					<MenuItem value={!selectAll ? 'all' : 'unselect'}>
						{!selectAll ? <ListItemText primary="Select All"/> :
							<ListItemText primary="Unselect All"/>}
					</MenuItem>

					{choices.map (fridge => {
						              const hasProfile = fridge?.profile?.id ? 'has a profile' : ''
						              let color = hasProfile ? 'red' : ''
						              return (<MenuItem style={{color: color, minWidth: '100%'}} key={fridge?.id}
						                                value={fridge?.serial}>
							              {fridge.serial}
						              </MenuItem>)
					              }
					)}

				</Select>
			</FormControl>
		</div>

	}
	else {
		return translate ('resources.profile.noFridges')

	}

}


export const ProfileEdit = props => {
	const refresh = useRefresh ()
	const profileId = props.id
	const translate = useTranslate ()
	const notify = useNotify ()
	const {permissions} = usePermissions ()
	const [miniProfileRecord, setMiniProfileRecord] = useState ({})
	const {data, loading} = useGetOne (props.resource, props.id);
	const [idToDelete, setIdToDelete] = useState (0)

	const useStyles = makeStyles (() => ({
		root: {
			flexWrap: 'wrap',
			flip: false
		},
		columnItem: {
			maxWidth: '90vw',
			'& > ul': {
				display: 'inline-flex',
				flexWrap: 'wrap',
				maxWidth: '90vw',

				'& > li': {
					border: 'none',
					flexDirection: 'column',
					margin: '1rem'
				}
			}


		}, editDiv: {
			float: 'right'
		},
		"flex-column": {
			display: "flex",
			flexDirection: 'column'
		}
	}))
	const classes = useStyles ()

	useEffect (() => {

		return () => {
		};
	}, []);


	const editMiniProfile = (record) => {
		setMiniProfileRecord (record)
	}

	const approveDelete = async () => {
		await httpClient (url + '/mini-profile/' + idToDelete, {method: 'DELETE'})
		refresh ()
	}
	const [open, setOpen] = useState (false);
	const handleClick = (e) => {
		setOpen (true);
		setIdToDelete (e.id)
	}
	const handleDialogClose = () => setOpen (false);

	const handleConfirm = () => {
		approveDelete ()
		setOpen (false);
	};

	return (<CustomPage>
			<SimpleForm redirect="show" record={data}

			            className={classes.root}
			            toolbar={
				            <div>

					            <CreateToolBarWithCustomSave
						            saveurl={`/profile/${profileId}/edit`}
						            successmsg={translate ('summary.profile.edit.successMsg')}
						            delete=<DeleteButton record={data} redirect={'/profile'}/>
					            title={translate ('summary.profile.edit.failMsg')}
					            goto={`/profile/${profileId}/show`}/>

				            </div>
			            }
			            onSubmit={''}>
				<TextInput source="name" validate={required ()} label={translate ('resources.profile.profileName')}
				/>


				<TextInput
					label={translate ('resources.profile.desiredApk')}
					source="desiredApk"
					validate={required ()}
					autoComplete="off"
				/>
				<TextInput
					label={translate ('resources.profile.desiredFirmware')}
					source="desiredFirmware"
					validate={required ()}
					autoComplete="off"
				/>
				<NumberInput source={'maxPurchasingAmount'} defaultValue={300} validate={required ()}
				             label={translate ('resources.profile.purchaseLimit')}/>

				<ArrayField source={"miniProfiles"} fullWidth label={''} fieldKey="id">
					<Datagrid>

						<ReferenceField
							allowEmpty={true}
							label={translate ('reference.fridgeStructure')}
							source="planogram.fridgeStructureId"
							reference="fridge-structure"
							link="show"
						>
							<TextField source="name" label={translate ('reference.fridgeStructure')}/>
						</ReferenceField>
						<ReferenceField
							allowEmpty={true}
							label={translate ('resources.profile.desiredPlanogram')}
							source="planogram.id"
							reference="planogram"
							link="show"
						>
							<TextField source="name"/>
						</ReferenceField>
						<ArrayField label={translate ('resources.profile.fridges')} source={"fridges"}>
							<SingleFieldList linkType={false}>
								<FunctionField render={record => <Chip style={{margin: 5}} label={record.serial}/>}/>
							</SingleFieldList>
						</ArrayField>
						{permissions === ("admin") ? <FunctionField
							render={record => {
								return <Button
									color="primary"
									size="small"
									onClick={() => editMiniProfile (record)}
									startIcon={<Edit/>}
								/>
							}
							}/> : ""} {permissions === ("admin") ? <FunctionField
						render={record => {
							return <Button
								color="primary"
								size="small"
								onClick={() => handleClick (record)}
								startIcon={<Delete/>}
							/>
						}
						}/> : ""}
					</Datagrid>

				</ArrayField>

				<CurrentMiniProfileEdit resetMiniProfileRecord={setMiniProfileRecord} translate={translate}
				                        notify={notify} profileId={profileId} miniProfiles={data?.miniProfiles || []}
				                        record={miniProfileRecord || {}}/>
			</SimpleForm>

			<Confirm
				isOpen={open}
				loading={loading}
				title={''}
				content={translate ('ra.message.are_you_sure')}
				onConfirm={handleConfirm}
				onClose={handleDialogClose}
				classes={classes.root}
			>

			</Confirm>
		</CustomPage>

	)
}
