import React from "react";
import {BooleanField, Datagrid, FunctionField, ReferenceField, TextField, useTranslate} from "react-admin";
import {AcUnit, TableChart} from "@material-ui/icons";
import RedirectButton from "../components/RedirectButton";
import FridgeStatus from "./FridgeStatus";
import StatusByFridge from "./StatusByFridge";
import {FridgeCreate} from "./FridgeCreate";
import {FridgeEdit} from "./FridgeEdit";
import {FridgeShow} from "./FridgeShow";
import {FridgeList} from "./FridgeList";

export const GetFridgeGenerationList = (translate) => {
    return [
      {id: "generation 1", generation: translate ("generation.generation_1")},
      {
          id: "generation 1 parallel production",
          generation: translate ("generation.generation_2"),
      },
      {id: "generation 1.1", generation: translate ("generation.generation_3")},
      {
          id: "generation 1.1 rollback",
          generation: translate ("generation.generation_4"),
      },
  ];
};

export const FridgeTitle = ({ record }) => <span>{" "+record.serial +" "}</span>;

const FridgeDatagrid = (props) => {
  const translate = useTranslate();
  return (
    <Datagrid rowClick="show" optimized {...props}>
      <TextField source="serial" />
      <ReferenceField
        allowEmpty={true}
        label={translate("reference.warehouse")}
        source="warehouseId"
        reference="warehouse"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        label={translate("general.fridgeStructure")}
        render={(record) => {
          return (
            <RedirectButton url={`/location/${record.warehouseId}/show`} icon={<TableChart />} />
          );
        }}
      />
      <FunctionField
        label={translate("general.locationNumber")}
        render={(record) => {
          return record && record.warehouse && record.warehouse.locations ? (
            <span>
              {record.warehouse.locations.filter((location) => !location.deletedAt).length}
            </span>
          ) : null;
        }}
      />
      <TextField source="warehouse.city" label={translate("general.city")} />
      <TextField source="warehouse.street" label={translate("general.street")} />
      <TextField source="warehouse.streetNumber" label={translate("general.streetNumber")} />
      <BooleanField source="active" />
    </Datagrid>
  );
};

export default {
  FridgeStatus: FridgeStatus,
  FridgeDatagrid: FridgeDatagrid,
  StatusByFridge: StatusByFridge,
  list: FridgeList,
  create: FridgeCreate,
  edit: FridgeEdit,
  show: FridgeShow,
  icon: AcUnit,
};
