import React, {useEffect, useState} from "react";
import {
	Datagrid,
	FunctionField,
	NumberField,
	ReferenceField,
	TextField,
	useTranslate
} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import {differenceBOcalaculationOnWeightEvent} from '../utils.service';

const {currency, locales} = require ("../constants");


const useStyles = makeStyles ({
	                              removeHeader: {display: "none"},
	                              promotionCell: {
		                              textAlign: "end",
		                              width: "30%"
	                              }
                              });

const OrderLinesDatagrid = (props) => {
	const [rows, setRows] = useState ([]);
	useEffect (() => {
		const events = props?.events || []
		const start = events.find ((e) => e.eventType === 'START_PURCHASE')?.weightEventData
		const end = events.find ((e) => e.eventType === 'END_PURCHASE')?.weightEventData
		const data = []
		const matchedEvents = () => {
			if (start?.length > 0 && !end) {
				for (const weightDataStart of start) {
					const weightDataEnd = end.find ((x) => weightDataStart.locationId === x.locationId)
					data.push ({
						           locationId: weightDataEnd.locationId,
						           weightDataEnd,
						           weightDataStart

					           })
				}
			}
			setRows (data)

		}
		matchedEvents ()
	}, [JSON.stringify (props.events)]);

	const RowStyle = (record) => {
		if (record.locationId) {
			const row = rows.find (p => p.locationId === record.locationId)
			if (row?.weightDataStart && row?.weightDataEnd) {
				row.product = record.product
				let  {differenceFromPreviousEventBO, mark} = differenceBOcalaculationOnWeightEvent (row);
				if(mark){
					return {'backgroundColor': 'rgb(242 175 175 / 37%)'}

				}
				if (record.quantity !== differenceFromPreviousEventBO && typeof differenceFromPreviousEventBO === 'number') {
					return {'backgroundColor': 'rgb(242 175 175 / 37%)'}
				}else {
					return null
				}
			}
			else {
				return null
			}
		}
		else {
			return null;
		}
	}
	const classes = useStyles ();
	const translate = useTranslate ();
	return (
		<Datagrid optimized rowClick="" {...props} rowStyle={RowStyle}>

			<ReferenceField
				label={translate ("reference.product")}
				source="productId"
				reference="product"
				link=""
				sortable={false}
				headerClassName={classes.removeHeader}
			>
				<TextField source="name"/>
			</ReferenceField>
			<NumberField
				label={translate ("general.pricePerUnit")}
				sortable={false}
				source="pricePerUnit"
				locales={locales}
				options={{style: "currency", currency: currency}}
				headerClassName={classes.removeHeader}
			/>
			<NumberField
				sortable={false}
				source="subtotal"
				locales={locales}
				options={{style: "currency", currency: currency}}
				headerClassName={classes.removeHeader}
			/>
			<FunctionField
				source="productsTaken"
				label={''}
				headerClassName={classes.removeHeader}
				render={(record) => {
					if (record.locationId) {

						const row = rows.find (p => p.locationId === record.locationId)
						if (row?.weightDataStart && row?.weightDataEnd) {
							const {differenceFromPreviousEventBO}  = differenceBOcalaculationOnWeightEvent(row)
							return <div>{record.quantity}<span style={{fontSize:'10px'}}> ({differenceFromPreviousEventBO}) {translate('general.calcBo')}</span></div>

						}
						else {
							return record.quantity
						}
					}
					else {
						return "";
					}
				}}
			/>
			<ReferenceField
				label={translate ("reference.promotion")}
				source="promotionId"
				reference="promotion"
				link={(record) => {
					return `../../../promotion/${record.promotionId}/show`;
				}}
				sortable={false}
				headerClassName={classes.removeHeader}
				cellClassName={classes.promotionCell}
			>
				<FunctionField
					render={(record) => {
						const validFrom = new Date (record.validFrom).toLocaleDateString (locales);
						const validTo = new Date (record.validTo).toLocaleDateString (locales);
						const type = translate (`promotion.type.${record.type}`);
						return `${validTo} - ${validFrom} (${type})`;
					}}
				/>
			</ReferenceField>
		</Datagrid>
	);
};

export default OrderLinesDatagrid;
