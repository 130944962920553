import React, {cloneElement} from "react";
import {
    BooleanField,
    Datagrid,
    Filter,
    List, FunctionField,
    ReferenceManyField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    useTranslate, usePermissions,  TopToolbar, sanitizeListRestProps, ExportButton, useRefresh
} from "react-admin";
import {Close, Description, SettingsInputAntenna} from "@material-ui/icons";
import Fridge from "../Fridges/index";
import httpClient from '../DataProvider/httpClient';
import {url} from '../config/connection';
import {Button} from '@material-ui/core';
import {downloadExcel} from '../utils.service';

const { FridgeDatagrid } = Fridge;


const ReleaseChannelsTitle = ({ record }) => <span>{record.name}</span>;




const ReleaseChannelsShow = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <Show {...props} hasEdit={false} hasCreate={false} title={<ReleaseChannelsTitle />}>
      <TabbedShowLayout>
        <Tab label={translate("general.summary")}>
          <TextField source="displayName" />
            <TextField source="lastHardwareVersion" />
          <BooleanField source="default" />
        </Tab>
        <Tab label={translate("reference.fridges")} path="fridge">
          <ReferenceManyField reference="fridge" target="releaseChannelId" addLabel={false}>
            <FridgeDatagrid {...props} />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};



const ReleaseChannelsGrid = (props) => {
    const translate = useTranslate()
  return (
    <Datagrid optimized rowClick="show" {...props}>
      <TextField source="displayName" />
      <TextField source="name" />
      <TextField source="lastHardwareVersion" />
      <BooleanField source="default" />
        <FunctionField label={translate('reference.fridges'        )}
                       render={(r) => {
                           return <span>{r?.fridges||0}</span>;
                       }}
        />
    </Datagrid>
  );
};

const ReleaseChannelsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="display_name" alwaysOn />
  </Filter>
);
const  ReleaseChannelsTopToolbar = ({
                              currentSort,
                              className,
                              resource,
                              filters,
                              displayedFilters,
                              exporter, // you can hide ExportButton if exporter = (null || false)
                              filterValues,
                              permanentFilter,
                              hasCreate, // you can hide CreateButton if hasCreate = false
                              basePath,
                              selectedIds,
                              onUnselectItems,
                              showFilter,
                              maxResults,
                              total,
                              ...rest
                          }) => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const refresh = useRefresh();
    const getNewHardwareVersions=()=>{
        httpClient(url+'/release-channels/lastHardwareVersion',{method:'get'})
            .then(()=>{

                refresh()
            })
    }

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters &&
            cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: "button",
            })}
            {filters && (
                <Button
                    color="primary"
                    size="small"
                    startIcon={<Close />}
                    onClick={() => rest.setFilters({})}
                >
                    {translate("general.clearFilter")}
                </Button>
            )}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={{ ...filterValues, ...permanentFilter }}
                exporter={()=>downloadExcel(Object.values(rest.data),rest.defaultTitle)}
                maxResults={maxResults}
            />
            {permissions === "admin" && (
                <Button
                    color="primary"
                    size="small"
                    startIcon={<Description />}
                    onClick={() => {
                        getNewHardwareVersions()
                    }}
                >
                    {translate("general.getNewHardwareVersions")}
                </Button>
            )}
        </TopToolbar>
    );
};
const ReleaseChannelsList = ({ permissions, ...props }) => {
  return (
    <List
      filters={<ReleaseChannelsFilter />}
      {...props}
      bulkActionButtons={
        false
      }
      hasCreate={false}
      actions={< ReleaseChannelsTopToolbar hasCreate={false} />}
    >
      <ReleaseChannelsGrid />
    </List>
  );
};

export default {
  list: ReleaseChannelsList,
  // create: ReleaseChannelsCreate,
  // edit: ReleaseChannelsEdit,
  show: ReleaseChannelsShow,
  icon: SettingsInputAntenna,
};
