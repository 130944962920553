import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DateField, FunctionField, ReferenceField } from "react-admin";

const { locales, redColor, orangeColor, yellowColor } = require("../constants");

const useStyles = makeStyles({
  redBackColor: {
    backgroundColor: redColor,
    color: "white",
    borderRadius: "1rem",
    padding: "1px 5px",
  },
  yellowBackColor: {
    backgroundColor: yellowColor,
    borderRadius: "1rem",
    padding: "1px 5px",
  },
  orangeBackColor: {
    backgroundColor: orangeColor,
    borderRadius: "1rem",
    padding: "1px 5px",
  },
});

const ExpirationDateField = (props) => {
  const oldRecord = props.record;
  const { source } = props;
  const expirationClass = function expirationClass(productRecord) {
    const expirationDate = new Date(oldRecord[source]);
    const { expireAlertDays } = productRecord;
    const expireAlertDaysInMilliseconds = expireAlertDays * 24 * 60 * 60 * 1000;
    const alertDate = new Date(expirationDate.getTime() - expireAlertDaysInMilliseconds);
    const today = new Date();
    if (today.getTime() > expirationDate.getTime()) return classes.redBackColor;
    if (today.getTime() > alertDate.getTime()) return classes.orangeBackColor;
    return null;
  };
  const classes = useStyles();
  return (
    <Fragment>
      <ReferenceField
        sortBy={source}
        record={oldRecord}
        source="productId"
        reference="product"
        link=""
        basePath={""}
      >
        <FunctionField
          source={source}
          render={(record) => {
            return (
              <DateField
                source={source}
                record={oldRecord}
                className={`${expirationClass(record)}`}
                locales={locales}
              />
            );
          }}
        />
      </ReferenceField>
    </Fragment>
  );
};

export default ExpirationDateField;
