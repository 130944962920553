import React from "react";
import { WhatsApp } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import { parsePhoneNumberFromString } from "libphonenumber-js";

const WhatsAppField = ({ phoneNumber }) => {
  const phone = phoneNumber;
  let parsedPhone;
  let whatsAppPhone = null;
  if (!phone) {
    return null;
  } else {
    parsedPhone = parsePhoneNumberFromString(phone, "IL");
    if (parsedPhone && parsedPhone.isValid()) {
      whatsAppPhone = parsedPhone.countryCallingCode + parsedPhone.nationalNumber;
    } else {
      parsedPhone = null;
    }
  }
  return (
    (parsedPhone && (
      <a
        onClick={(e) => e.stopPropagation()}
        href={`https://wa.me/${whatsAppPhone}`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "black" }}
      >
        <Grid container direction="row" alignItems="center">
          <WhatsApp style={{ color: "green" }} />
          <span>{phone}</span>
        </Grid>
      </a>
    )) ||
    null
  );
};

export default WhatsAppField;
