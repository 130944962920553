import React, {useEffect, useState} from 'react'
import {
	ArrayInput,
	required,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
	usePermissions,
	useTranslate,AutocompleteInput
} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import CreateToolBarWithCustomSave from '../components/CreateToolBarWithCustomSave'
import httpClient from '../DataProvider/httpClient';
import {url} from '../config/connection';




const FridgeStructureCreate = () => {
	const useStyles = makeStyles (() => ({
		root: {
			direction: 'ltr',
			flip: false
		},
		columnItem: {
			display: 'inline-flex',
			'& > ul': {
				display: 'inline-flex',
				'& > li': {
					border: 'none',
					flexDirection: 'column',
					margin: '1rem'
				}
			}
		}
	}))
	const {permissions} = usePermissions ()
	const [shelfTypes,setShelfTypes]=useState([])


	const translate = useTranslate ()
	const classes = useStyles ()
	useEffect (() => {
		httpClient(url+'/shelf-type',{method:'GET'})
			.then((data)=> setShelfTypes(data.json))
		return () => {
		};
	}, []);

	if (permissions !== 'admin') return <div/>
	else {
		return <SimpleForm
			toolbar={
				<CreateToolBarWithCustomSave
					saveurl={`/fridge-structure`}
					successmsg={translate ('summary.fridgeStructure.create.successMsg')}
					title={translate ('summary.fridgeStructure.create.failMsg')}
					goto={`/fridge-structure`}

				/>
			}
		 onSubmit={''}>
			<TextInput name={"name"} validate={[required ()]}
			           label={translate ('resources.fridge-structure.fridgeStructureName')} source="name"/>
			<ArrayInput
				source="rows"
				label={translate ('general.shelves')}
				className={classes.root}
			>
				<SimpleFormIterator className={classes.root}>
					<ArrayInput
						disableReordering
						source="columns"
						label={translate ('general.column')}
						className={classes.columnItem}
					>
						<SimpleFormIterator>
							<AutocompleteInput validate={required ()} label={translate ('general.shelfSize')}
							           source={'shelfTypeId'}  optionText={'dimensions.shelfName'} optionValue={'id'} choices={shelfTypes}/>
						</SimpleFormIterator>
					</ArrayInput>
				</SimpleFormIterator>
			</ArrayInput>
		</SimpleForm>
	}

}

export default FridgeStructureCreate


