import React, { Fragment } from "react";
import {
  Button,
  useDataProvider,
  useNotify,
  useRedirect,
  useTranslate,
  useUnselectAll,
} from "react-admin";
import { map, pick, uniq } from "lodash";

const CreateTransactionButton = (props) => {
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();
  const unselectAll = useUnselectAll();

  const handleClick = () => {
    const { selectedIds } = props;
    dataProvider
      .getMany(`inventory`, { ids: selectedIds })

      .then(({ data }) => {
        const uniqWarehouseId = uniq(map(data, "warehouseId"));
        if (uniqWarehouseId.length > 1) {
          notify(translate("error.inventory.twoWarehouses"), "warning");
        } else {
          unselectAll("inventory");
          redirect(
            `/transaction/create/${JSON.stringify({
              type: "bulk",
              items: data.map((d) =>
                pick(d, ["productId", "warehouseId", "expiration_time", "locationId"])
              ),
            })}`
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <Button label={translate("general.createTransactionButton")} onClick={handleClick} />
    </Fragment>
  );
};

export default CreateTransactionButton;
