import React from "react";
import { useRedirect } from "react-admin";
import { Button } from "@material-ui/core";

const RedirectButton = (props) => {
  const redirect = useRedirect();
  const { label } = props;
  return (
    <Button
      style={{ textTransform: "capitalize" }}
      component="span"
      fullWidth={false}
      color="primary"
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        redirect(props.url);
      }}
      startIcon={props.icon ? props.icon : null}
    >
      {label}
    </Button>
  );
};

export default RedirectButton;
