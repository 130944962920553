import React, { Fragment } from "react";
import {
  Datagrid,
  DateField,
  DateTimeInput,
  Filter,
  List,
  TextField,
  useTranslate,
} from "react-admin";

const { locales } = require("../constants");

const FridgeLogsFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <DateTimeInput
        label={translate("general.createdAfter")}
        source="createdAt||$gte"
        alwaysOn
        locales={locales}
        parse={(x)=> {
            return x
        }}
      />
    </Filter>
  );
};

const FridgeLogsList = (props) => {
  const {
    match: {
      params: { fridgeId },
    },
  } = props;
  const translate = useTranslate();
  return (
    <Fragment>
      <List
        title=" "
        filters={<FridgeLogsFilter />}
        bulkActionButtons={false}
        hasCreate={false}
        hasEdit={false}
        hasList={true}
        hasShow={false}
        sort={{ field: "createdAt", order: "DESC" }}
        resource={`fridge-logs`}
        basePath={`fridge-logs`}
        filter={{ "fridgeId||$eq": parseInt(fridgeId, 10) }}
      >
        <Datagrid optimized rowClick="" resource={`fridge-logs`} basePath={`fridge-logs`}>
          <TextField  source="temperature" />
          <TextField  source="humidity" />
          <DateField
            label={translate("general.createdAt")}
            showTime={true}
            options={{
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }}
            source="createdAt"
            locales={locales}

          />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export default {
  FridgeLogsList,
};
