import React, { Fragment } from "react";
import {
    BooleanInput,
    Datagrid,
    DateField,
    DateTimeInput,
    Filter,
    List,
    TextField,
    useTranslate
} from "react-admin";
import {TableCell, TableHead, TableRow} from '@material-ui/core';

const { locales } = require("../constants");

const NetworkHistoryFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <DateTimeInput
        label={translate("general.createdAfter")}
        source="createdAt||$gte"
        alwaysOn
        locales={locales}
        parse={(x)=> {
            return x
        }}
      />
        <BooleanInput
            source="IMEI"
            parse={(val) => {
                if (val) return 'IMEI';
                if (val === false) return ;
                return val;
            }}
            alwaysOn

        />
        <BooleanInput
            source="hardwareVersion"
            parse={(val) => {
                if (val) return 'v';
                if (val === false) return ;
                return val;
            }}
            alwaysOn

        />
    </Filter>
  );
};

const DatagridHeader = ({ children }) => (
    <TableHead>
        <TableRow>
            {children.map( (child) => (
                <TableCell key={child.props.source}>
                    {child.props.source}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
);
const NetworkHistoryList = (props) => {
  const {
    match: {
      params: { fridgeId },
    },
  } = props;
  const translate = useTranslate();
  return (
    <Fragment>
      <List
        title=" "
        filters={<NetworkHistoryFilter />}
        bulkActionButtons={false}
        hasCreate={false}
        hasEdit={false}
        hasList={true}
        hasShow={false}
        sort={{ field: "createdAt", order: "DESC" }}
        resource={`network-history`}
        basePath={`network-history`}
        filter={{ "fridgeId||$eq": parseInt(fridgeId, 10) }}
      >
        <Datagrid optimized rowClick="" resource={`network-history`} basePath={`network-history`}  header={<DatagridHeader /> }>
          <TextField  source="hardwareVersion"  />
          <TextField  source="IMEI"  />
          <TextField  source="WifiMAC" />
          <TextField  source="EthMAC" />
          <TextField  source="publicIp" />
          <TextField  source="privateIp" />
          <DateField
            label={translate("general.createdAt")}
            showTime={true}
            options={{
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }}
            source="createdAt"
            locales={locales}

          />
        </Datagrid>
      </List>
    </Fragment>
  );
};

export default {
  NetworkHistoryList,
};
