import { decryptWithAesKey } from "@akanass/rx-crypto/operators/aes";
import { AES, Hash } from "@akanass/rx-crypto";

export async function createKey(password) {
  const salt = localStorage.getItem("static_data");
  const hash = new Hash();
  return hash.generate(password, salt, 4096, 24, "sha256").toPromise();
}

export function decrypt(encryptedData, key) {
  const salt = localStorage.getItem("static_data");
  const aes = new AES();
  return aes
    .createKey(key, salt)
    .pipe(decryptWithAesKey(Buffer.from(encryptedData, "hex")))
    .toPromise();
}
