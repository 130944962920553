import decodeJwt from "jwt-decode";
import {staging, url} from "../config/connection";
import httpClient from "../DataProvider/httpClient";
// import Cookies from "js-cookie";
import { createKey, decrypt } from "../encrypt.service";

export default {
  login: async ({ username, password }) => {
    try {
      const request = new Request(`${url}/auth/login`, {
        method: "POST",
        body: JSON.stringify({ email: username, password }),
        headers: new Headers({ "Content-Type": "application/json" ,'AB-testing':staging}),
      });
      const response = await fetch(request);
      const data = await response.json();
      const accessToken = data?.accessToken;
      localStorage.setItem("access_token", accessToken);
      if (data?.refreshToken) {
        localStorage.setItem("refresh_token", data?.refreshToken);
      }
      const key = await createKey(password);
      localStorage.setItem("key", key.toString("hex"));
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      return Promise.resolve();
    } catch (ex) {
      throw new Error(ex);
    }
  },
  logout: async () => {
    try {
      if (localStorage.getItem("access_token")) {
        const requestUrl = `${url}/auth/logout`;
        const options = { method: "POST", body: "" };

        await httpClient(requestUrl, options);
      }
    } catch (e) {
      throw e;
    } finally {
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("key");
    }
  },
  checkError: (error) => {
    // ...
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("access_token");

      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("access_token") ? Promise.resolve() : Promise.reject();
  },
  getPermissions: async () => {
    if (!localStorage.getItem("access_token")) {
      return Promise.reject();
    }
    const role = await getRole();
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

async function getRole() {
  const { token } = await decodeJwt(localStorage.getItem("access_token"));
  const key = localStorage.getItem("key");
  let userData = await decrypt(token, key);
  userData = JSON.parse(userData.toString());
  return userData.role;
}
