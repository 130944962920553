import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { useTranslate } from "react-admin";
import { Check, Clear, ExpandMore } from "@material-ui/icons";
import { dateFormat } from "../utils.service";
import { fridgeEvents } from "../constants";

export const MultiFridgeEvent = ({ classes, item, child }) => {
  const translate = useTranslate();
  const date = dateFormat(item.messageDate);
  return (
    <Accordion style={{ width: "100%", padding: 0, margin: 0, boxShadow: "none" }}>
      <AccordionSummary style={{ padding: 0, margin: 0, color: "" }}>
        <Table
          style={{
            borderTop: "2px solid white",
            borderRight: '2px solid white,color:"#E35C36"',
            marginTop: "8px",
            boxShadow: "#BEBEBE 6px -8px 1px 0px  , rgb(0 0 0 / 12%) 10px -13px 0px",
          }}
        >
          <TableRow>
            <TableCell className={classes.date}> {date}</TableCell>
            <TableCell className={classes.uniqueCell}>{translate("general.openToSee")}</TableCell>
            <TableCell className={classes.cell}>
              {item.isError ? <Check/> : <Clear/>}
            </TableCell>
            <Tooltip title={fridgeEvents[item.messageId - 1]?.alert}>
              <TableCell className={classes.cell}>{item.messageId} </TableCell>
            </Tooltip>
            <TableCell className={classes.cell}>
              {item.weightErrorId === -1 ? "" : item.weightErrorId}{" "}
            </TableCell>
            <TableCell className={classes.cell}>
              {item.weightId === -1 ? "" : item.weightId}{" "}
            </TableCell>
            <TableCell className={classes.cell}>{item.name} </TableCell>
            <TableCell className={classes.cell}>{item.completed}</TableCell>
            <TableCell className={classes.cell}>{item.viewed}</TableCell>
            <TableCell className={classes.cell}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>
                  {translate("general.alertNum")} {child.length}
                </span>
                <ExpandMore/>
                <ExpandMore/>
              </div>
            </TableCell>
          </TableRow>
        </Table>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <Table size="small" aria-label="">
          <TableBody className={classes.tbody}>
            <Table size="small" aria-label="">
              <TableBody>{child}</TableBody>
            </Table>
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};
