import React, {cloneElement, Fragment, useEffect, useState} from "react";
import {
	BooleanField,
	BooleanInput,
	BulkDeleteButton,
	CreateButton,
	Datagrid,
	ExportButton,
	Filter,
	FunctionField,
	List,
	NumberField,
	ReferenceInput,
	sanitizeListRestProps,
	SelectInput,
	TextField,
	TextInput,
	TopToolbar,
	useNotify,
	usePermissions,
	useRedirect,
	useTranslate
} from "react-admin";
import RedirectButton from "../components/RedirectButton";
import {Close, Description, RemoveCircleOutline, TableChart} from "@material-ui/icons";
import {perPageInputField} from "../constants";
import {dateFormat, downloadExcel} from '../utils.service';
import {Button} from "@material-ui/core";
import {ReferenceField} from "ra-ui-materialui";
import httpClient from "../DataProvider/httpClient";
import {url} from "../config/connection";

const FridgeTopToolbar = ({
	                          currentSort,
	                          className,
	                          resource,
	                          filters,
	                          displayedFilters,
	                          exporter, // you can hide ExportButton if exporter = (null || false)
	                          filterValues,
	                          permanentFilter,
	                          hasCreate, // you can hide CreateButton if hasCreate = false
	                          basePath,
	                          selectedIds,
	                          onUnselectItems,
	                          showFilter,
	                          maxResults,
	                          total,
	                          ...rest
                          }) => {
	const {permissions} = usePermissions ();
	const translate = useTranslate ();
	const redirect = useRedirect ();
	return (
		<TopToolbar className={className} {...sanitizeListRestProps (rest)}>
			{filters &&
			cloneElement (filters, {
				resource,
				showFilter,
				displayedFilters,
				filterValues,
				context: "button"
			})}
			{filters && (
				<Button
					color="primary"
					size="small"
					startIcon={<Close/>}
					onClick={() => rest.setFilters ({})}
				>
					{translate ("general.clearFilter")}
				</Button>
			)}
			{permissions === "admin" && <CreateButton basePath={basePath}/>}
			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filter={{...filterValues, ...permanentFilter}}
        exporter={()=>downloadExcel(Object.values(rest.data),rest.defaultTitle)}
				maxResults={maxResults}
			/>
			{permissions === "admin" && (
				<Button
					color="primary"
					size="small"
					startIcon={<Description/>}
					onClick={() => {
						redirect ("/fridge/status");
					}}
				>
					{translate ("general.fridgeStatus")}
				</Button>
			)}
		</TopToolbar>
	);
};

const FridgeFilter = (props) => {
	const translate=useTranslate()
	const [fridgeStructures,setFridgeStructures]=useState([])
	const [profiles,setProfiles]=useState([])
	const errorCodes = [
		{id: 1, errorCode: translate (`resources.fridge.errorCode.error1`)},
		{id: 2, errorCode: translate (`resources.fridge.errorCode.error2`)},
		{id: 3, errorCode: translate (`resources.fridge.errorCode.error3`)},
		{id: 4, errorCode: translate (`resources.fridge.errorCode.error4`)},
		{id: 5, errorCode: translate (`resources.fridge.errorCode.error5`)},
		{id: 6, errorCode: translate (`resources.fridge.errorCode.error6`)},
		{id: 7, errorCode: translate (`resources.fridge.errorCode.error7`)},
		{id: 8, errorCode: translate (`resources.fridge.errorCode.error8`)},
		{id: 9, errorCode: translate (`resources.fridge.errorCode.error9`)},
		{id: 10, errorCode: translate (`resources.fridge.errorCode.error10`)},
		{id: 11, errorCode: translate (`resources.fridge.errorCode.error11`)}

	]
	useEffect (() => {
		httpClient(`${url}/fridge-structure/names`,{method:'get'}).then(
			(data)=>setFridgeStructures(data.json)
		)
		httpClient(`${url}/profile/names`,{method:'get'}).then(
			(data=>setProfiles(data.json)
		))

	}, []);

	return <Filter {...props}>
		<TextInput source="serial" alwaysOn/>
		<ReferenceInput
			label={translate ("reference.complexe")}
			source="complexeId"
			reference="complexe"
			perPage={perPageInputField}
		>
			<SelectInput optionText="name"/>
		</ReferenceInput>
		<TextInput
			source="complexe.city"
			label={translate ('general.city')}
			alwaysOn
		/>
		<TextInput
			source="complexe.street"
			label={translate ('general.street')}
		/>
		<TextInput
			source="complexe.streetNumber"
			label={translate ('general.streetNumber')}
		/>
		<SelectInput choices={errorCodes} optionText="errorCode" source="errorCode" alwaysOn/>

		<SelectInput choices={fridgeStructures} optionText="name"   source="fridgeStructureId" label={translate ('reference.fridgeStructure')} alwaysOn/>
		<ReferenceInput
			label={translate ("reference.release-channels")}
			source="releaseChannelId"
			reference="release-channels"
			perPage={perPageInputField}
			alwaysOn
		>
			<SelectInput optionText="displayName"/>
		</ReferenceInput>
		<SelectInput choices={profiles} optionText="name"   source="fridge.profile.profileId" label={translate ('reference.profile')} alwaysOn/>

		<BooleanInput
		source="active"
		alwaysOn
		parse={(val) => {
			if (val === true) return 1;
			if (val === false) return 0;
			return val;
		}}
	/>

	</Filter>

}

const FridgeBulkActions = (props) => {
	const translate = useTranslate ()
	const notify = useNotify ()
	const redirect = useRedirect ()
	const removeFromProfile = async (ids) => {
		try {
			for (const id of ids) {
				await httpClient (`${url}/profile/removeFridge/${id}`, {method: 'POST'})
				notify (translate ('summary.profile.remove.successMsg'), 'success')
				redirect ('/')
				redirect ('/fridge')
			}
		} catch (e) {
			notify (translate ('summary.profile.remove.failMsg'), 'error')
		}

	}
	return (
		<Fragment>
			{props.permissions === 'driver' || 'admin' ? <Button color="primary"
			                                                     size="small"
			                                                     startIcon={<RemoveCircleOutline/>}
			                                                     onClick={async () => {
				                                                     await removeFromProfile (props.selectedIds)

			                                                     }}
			>
				{translate ('general.removeProfile')}</Button> : ''}
			{props.permissions === 'admin' ? <BulkDeleteButton {...props} /> : ""}

		</Fragment>
	);
};

const RowStyle = (record) => ({
	'text-decoration': !record.isPlanogramServer?'line-through' :'none'
});
const RowClick = (id, resource, record) => !record.isPlanogramServer ? '' : 'show';

export const FridgeList = ({permissions, ...props}) => {
	const translate = useTranslate ();
	return <List
		{...props}
		filters={<FridgeFilter/>}
		actions={<FridgeTopToolbar/>}
		bulkActionButtons={
			<FridgeBulkActions permissions={permissions}/>
		}
		filter={{
			'isPlanogramServer||$eq':1,
			join: [
				'complexe||id,otherId,name,city,street,streetNumber',
				'warehouse||id,name',
				'fridgeStructure||id,name',
				'currentPlanogram.locations',
				'profile'
			]
		}}
		sort={{field: 'active', order: 'DESC'}}

	>
		<Datagrid rowClick={RowClick} rowStyle={RowStyle}  optimized>
			<TextField source="serial"/>

			<FunctionField
				label={translate ("reference.complexe")}
				render={(record) => {
					if (record?.complexe?.name) {
						let lower = (record?.complexe?.name).replace ("_", " ");
						lower = lower.replace ("COMPLEXE", "complexe");
						return <RedirectButton label={lower} url={`/complexe/${record.complexe.id}/show`}/>;
					}
				}}
			/>
			<TextField label={translate ('reference.fridgeStructure')}
			           source="fridgeStructure.name"/>

			{permissions !== "customerSupport" && (
				<FunctionField
					label={translate ("reference.warehouse")}
					render={(record) => {
						if (record?.warehouse?.name) {
							let lower = (record?.warehouse?.name).replace ("_", " ");
							lower = lower.toLowerCase ();
							return (
								<RedirectButton label={lower} url={`/warehouse/${record.warehouseId}/show`}/>)
						}
						else {
							return ''
						}
					}}
				/>
			)}
			{permissions !== "customerSupport" && (
				<FunctionField
					label={translate ('reference.profile')}
					render={record => {
						if (record?.profile?.profileId) {
							return (
								<ReferenceField reference={'profile'} link={'show'} source={'profile.profileId'}>
									<TextField source={'name'}/>
								</ReferenceField>

							)
						}
					}}
				/>
			)}
			{permissions !== 'customerSupport' && (
				<FunctionField
					label={translate ('general.currentPlanogram')}
					render={record => {

						const desiredPlanogramId = record?.profile?.planogramId
						const privatePlanogramVersion = record.privatePlanogramId
						const fridgePlanogramVersion = record.planogramVersion
						if (fridgePlanogramVersion !== desiredPlanogramId && desiredPlanogramId > 0) {
							return <RedirectButton
								url={`/planogram/${record.currentPlanogramId}/show`}
								icon={<TableChart/>}
							/>
						}
						else if (fridgePlanogramVersion !== privatePlanogramVersion && record.linkedToPrivatePlanogram && record.privatePlanogramId > 0) {
							return <RedirectButton
								url={`/planogram/${record.privatePlanogramId}/show`}
								icon={<TableChart/>}
							/>
						}
						else {
							return (
								<RedirectButton
									url={`/planogram/${record.currentPlanogramId}/show`}
									icon={<TableChart htmlColor={'green'}/>}
								/>
							)
						}

					}}
				/>
			)}

			{permissions !== 'customerSupport' && (
				<FunctionField
					label={translate ('general.desiredPlanogram')}
					render={record => {
						if (record.linkedToPrivatePlanogram && record.privatePlanogramId > 0) {
							return <RedirectButton
								url={`/planogram/${record.privatePlanogramId}/show`}
								icon={<TableChart htmlColor={'green'}/>}

							/>
						}
						const id = record?.profile?.planogram?.id
						if (id) {
							return <RedirectButton
								url={`/planogram/${id}/show`}
								icon={<TableChart htmlColor={'green'}/>}

							/>
						}
						else {
							return ''
						}

					}}
				/>
			)}
			<FunctionField
				label={translate ("general.locationNumber")}
				render={(record) => {
					return record && record?.currentPlanogram?.locations ? (
						<span>
		{
			record.currentPlanogram?.locations?.length
		}
			</span>
					) : null;
				}}
			/>
			<FunctionField
				label={translate ("general.isPlanogramFull")}
				render={(record) => {
					if( record && record?.currentPlanogram?.locations) {
						const isEmpty = record?.currentPlanogram?.locations.find ((p) => p.productId === null)
						if (isEmpty) {
							return <span style={{color:'red'}} >{translate ("general.isPlanogramFull")}</span>
						}else {
							return null
						}

					}else {
						return null
					}
					}}

			/>
			<FunctionField label={translate ('resources.fridge.fields.lastAlert')}
			               render={record => {
				               const id = record.errorCode

				               if (id > 0) {
					               return translate (`resources.fridge.errorCode.error${id}`);
				               }
				               else {
					               return "";
				               }
			               }}
			/>
			<NumberField source="offlineDurationInHours"/>
          <FunctionField
				label={translate ("reference.fillDate")}
              render={(record) => {
                  if (record?.fillDate) {
                      return dateFormat(record.fillDate);

                  } else {
                      return " ";
                  }
				}}
			/>
			<BooleanField source="active"/>
		</Datagrid>
	</List>
}


