import React, { Fragment } from "react";
import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  BulkDeleteButton,
  Create,
  Datagrid,
  Edit,
  Filter,
  FunctionField,
  Labeled,
  List,
  minValue,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  usePermissions,
  useTranslate,
} from "react-admin";
import GenericTopToolbar from "../components/GenericTopToolbar";
import { url } from "../config/connection";
import { S3FileField, S3FileInput } from "../FileUploader";
import { PhotoLibrary } from "@material-ui/icons";
import { InputAdornment } from "@material-ui/core";
import { debounce } from "../constants";

const { currency, currencySymbol, locales } = require("../constants");

const ProductCreate = (props) => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  return (
    <Create {...props}>
      {permissions === "admin" ? (
        <SimpleForm redirect="list" onSubmit={''}>
          <TextInput source="sku" autoComplete="off" validate={[required()]} />
          <TextInput source="barcode" autoComplete="off" validate={[required()]} />
          <TextInput source="name" autoComplete="off" validate={[required()]} />
          <S3FileInput
            apiRoot={url}
            source="imageUrl"
            validate={[required()]}
            uploadOptions={{
              signingUrl: `${url}/s3/sign`,
              s3path: `products`,
              multiple: false,
              accept: "image/*",
            }}
          />
          <NumberInput
            source="cost"
            autoComplete="off"
            InputProps={{
              endAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
            }}
          />
          <NumberInput source="expireAlertDays" validate={[required()]} autoComplete="off" />
          <NumberInput source="weight" validate={[required()]} autoComplete="off" />
          <NumberInput source="StandertDeviatio" validate={[required()]} autoComplete="off" />
          <NumberInput
            source="price"
            validate={[required()]}
            InputProps={{
              endAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
            }}
            autoComplete="off"
          />
          <ReferenceInput
            label={translate("reference.vendor")}
            source="vendorId"
            reference="vendor"
            filterToQuery={(searchText) => {
              if (searchText) return { name: searchText };
            }}
            validate={[required()]}
          >
            <AutocompleteInput optionText="name" debounce={debounce} />
          </ReferenceInput>
          <NumberInput source="humanReachability" validate={[required(), minValue(1)]} min={1} />
          <BooleanInput defaultValue={true} source="vatIncluded" />
          <BooleanInput defaultValue={true} source="active" />
        </SimpleForm>
      ) : (
        <div />
      )}
    </Create>
  );
};

const ProductTitle = ({ record }) => <span>{record.name}</span>;
const ProductEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const ProductEdit = (props) => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  return (
    <Edit {...props} title={<ProductTitle />}>
      {permissions === "admin" && (
        <SimpleForm toolbar={<ProductEditToolbar />} redirect="list" onSubmit={''}>
          <TextInput source="sku" validate={[required()]} autoComplete="off" />
          <TextInput source="barcode" autoComplete="off" validate={[required()]} />
          <TextInput source="name" validate={[required()]} autoComplete="off" />
          <S3FileInput
            apiRoot={url}
            source="imageUrl"
            validate={[required()]}
            uploadOptions={{
              signingUrl: `${url}/s3/sign`,
              s3path: `products`,
              multiple: false,
              accept: "image/*",
            }}
          />
          <NumberInput
            source="cost"
            autoComplete="off"
            InputProps={{
              endAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
            }}
          />
          <NumberInput source="expireAlertDays" validate={[required()]} autoComplete="off" />
          <NumberInput source="weight" validate={[required()]} autoComplete="off" />
          <NumberInput source="StandertDeviatio" validate={[required()]} autoComplete="off" />
          <NumberInput
            source="price"
            validate={[required()]}
            InputProps={{
              endAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
            }}
            autoComplete="off"
          />
          <ReferenceInput
            label={translate("reference.vendor")}
            source="vendorId"
            reference="vendor"
            filterToQuery={(searchText) => {
              if (searchText) return { name: searchText };
            }}
            validate={[required()]}
          >
            <AutocompleteInput optionText="name" debounce={debounce} />
          </ReferenceInput>
          <NumberInput
            source="humanReachability"
            validate={[required(), minValue(1)]}
            min={1}
          />
          <BooleanInput source="vatIncluded" />
          <BooleanInput source="active" />
        </SimpleForm>
      )}
    </Edit>
  );
};

const ProductShow = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <Show {...props} hasEdit={permissions === "admin"} title={<ProductTitle />}>
      <TabbedShowLayout>
        <Tab label={translate("general.summary")}>
          <TextField source="sku" />
          <TextField source="barcode" />
          <TextField source="name" />
          <Labeled label={translate("general.image")}>
            <S3FileField apiRoot={url} source="imageUrl" />
          </Labeled>
          <NumberField
            source="cost"
            options={{
              style: "currency",
              currency: currency,
            }}
          />
          <NumberField source="expireAlertDays" />
          <NumberField source="weight" />
          <NumberField source="StandertDeviatio" />
          <NumberField
            source="price"
            options={{
              style: "currency",
              currency: currency,
            }}
          />
          <NumberField source="humanReachability" />
          <BooleanField source="vatIncluded" />
          <BooleanField source="active" />
        </Tab>
        <Tab label={translate("reference.promotions")} path="promotions">
          <ReferenceManyField
            reference="promotion-item"
            target="productId"
            link=""
            addLabel={false}
          >
            <Datagrid rowClick="" optimized>
              <ReferenceField
                label={translate("general.promotionDates")}
                source="promotionId"
                reference="promotion"
                link={(record) => {
                  return `../../../promotion/${record.promotionId}/show`;
                }}
                sortable={false}
              >
                <FunctionField
                  render={(record) => {
                    const validFrom = new Date(record.validFrom).toLocaleDateString(locales);
                    const validTo = new Date(record.validTo).toLocaleDateString(locales);
                    const type = translate(`promotion.type.${record.type}`);
                    return `${validTo} - ${validFrom} (${type})`;
                  }}
                />
              </ReferenceField>
              <NumberField source="price" />
              <ReferenceField
                label={translate("general.active")}
                source="promotionId"
                reference="promotion"
                link=""
                sortable={false}
              >
                <BooleanField source="active" />
              </ReferenceField>
              <ReferenceField
                label={translate("general.active")}
                source="promotionId"
                reference="promotion"
                link=""
                sortable={false}
              >
                <BooleanField source="ended" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const ProductFilter = (props) => (
  <Filter {...props}>
    <TextInput source="sku" alwaysOn />
    <TextInput source="barcode" alwaysOn />
    <TextInput source="name" alwaysOn />
  </Filter>
);

const ProductDatagrid = (props) => {
  const translate = useTranslate();
  return (
    <Datagrid
     optimized
      rowClick="show"
      {...props}
      expand={
        <Labeled label={translate("general.image")}>
          <S3FileField apiRoot={url} source="imageUrl" />
        </Labeled>
      }
    >
      <TextField source="sku" />
      <TextField source="barcode" />
      <TextField source="name" />
      <NumberField
        source="cost"
        locales={locales}
        options={{ style: "currency", currency: currency }}
      />
      <NumberField source="expireAlertDays" />
      <NumberField source="weight" />
      <NumberField source="StandertDeviatio" />
      <NumberField
        source="price"
        locales={locales}
        options={{ style: "currency", currency: currency }}
      />
      <NumberField source="humanReachability" />
      <BooleanField source="vatIncluded" />
      <BooleanField source="active" />
    </Datagrid>
  );
};

const ProductBulkActions = (props) => {
  return (
    <Fragment>
      <BulkDeleteButton {...props} />
    </Fragment>
  );
};

const ProductList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      filters={<ProductFilter />}
      bulkActionButtons={permissions === "admin" ? <ProductBulkActions /> : false}
      hasCreate={permissions === "admin"}
      actions={<GenericTopToolbar />}
    >
      <ProductDatagrid />
    </List>
  );
};

export default {
  ProductDatagrid: ProductDatagrid,
  list: ProductList,
  create: ProductCreate,
  edit: ProductEdit,
  show: ProductShow,
  icon: PhotoLibrary,
};
