import React, { cloneElement } from "react";
import { useEditController } from "react-admin";
import { Card } from "@material-ui/core";

const CustomEdit = (props) => {
  const controllerProps = useEditController(props);
  const { basePath, redirect, resource, save, saving, version } = controllerProps;
  return (
    <Card>
      {cloneElement(props.children, {
        basePath,
        redirect,
        resource,
        save,
        saving,
        version,
      })}
    </Card>
  );
};

export default CustomEdit;
