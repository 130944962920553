import React from "react";
import { Login, LoginForm } from "ra-ui-materialui";
import { withStyles } from "@material-ui/core/styles";
import logo from "../assets/images/mini-yellow.png";

const styles = {
  login: {
    main: {
      backgroundImage: "radial-gradient(circle at 50% 14em, #fdbc24 0%, #fdbc24 60%, #fdbc24 100%)",
    },
    card: {
      padding: "5px 0 15px 0",
    },
  },
  form: {
    button: {
      height: "3em",
    },
  },
};

const CustomLoginForm = withStyles(styles.form)(LoginForm);

const LoginPage = (props) => (
  <div style={{ backgroundColor: "#fdbc24", textAlign: "center" }}>
    {" "}
    <img src={logo} alt={"logo"} /> <Login loginform={<CustomLoginForm />} {...props} />
  </div>
);

export default withStyles(styles.login)(LoginPage);
