import React, { useEffect, useState } from "react";
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  Error,
  Filter,
  FormDataConsumer,
  FunctionField,
  List,
  Loading,
  minValue,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SaveButton,
  SelectInput,
  ShowView,
  SimpleForm,
  SimpleFormIterator,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  Toolbar,
  useRedirect,
  useTranslate,
} from "react-admin";
import GenericTopToolbar from "../components/GenericTopToolbar";
import { Box, Chip, Grid } from "@material-ui/core";
import { Redeem } from "@material-ui/icons";
import CreateToolBarWithCustomSave from "../components/CreateToolBarWithCustomSave";
import httpClient from "../DataProvider/httpClient";
import { url } from "../config/connection";
import { get, keyBy } from "lodash";
import CustomEdit from "../components/CustomEdit";
import { debounce, perPageInputField } from "../constants";

const { locales, currency } = require("../constants");

const PromotionTypeField = ({ source, record = {} }) => {
  const type = get(record, source);
  const translate = useTranslate();
  return (
    <Grid container direction="row" alignItems="center">
      <span>{translate(`promotion.type.${type}`)}</span>
    </Grid>
  );
};

const PromotionsCreate = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <Create {...props}>
      {permissions === "admin" ? (
        <SimpleForm
          redirect="show"
          toolbar={
            <CreateToolBarWithCustomSave
              saveurl={`/promotion`}
              successmsg="summary.promotion.successMsg"
              title={translate("summary.promotion.title")}
              goto={`/promotion`}
            />
          }
         onSubmit={''}>
          <DateInput source="validFrom" locales={locales} validate={[required()]} />
          <DateInput source="validTo" locales={locales} validate={[required()]} />
          <SelectInput
            validate={[required()]}
            source="type"
            choices={[
              {
                id: "general",
                name: translate(`promotion.type.general`),
              },
              { id: "complexe", name: translate(`promotion.type.complexe`) },
              { id: "member", name: translate(`promotion.type.member`) },
            ]}
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData &&
              formData.type &&
              formData.type !== "general" &&
              formData.type === "member" ? (
                <ReferenceArrayInput
                  label={translate("reference.member")}
                  source="memberIds"
                  reference="member"
                  perPage={perPageInputField}
                  filterToQuery={(searchText) => {
                    if (searchText)
                      return {
                        q: {
                          $or: [
                            { phoneNumber: { $cont: searchText } },
                            { fullName: { $cont: searchText } },
                          ],
                        },
                        isSearch: true,
                      };
                  }}
                >
                  <AutocompleteArrayInput
                    optionText={(record) =>
                      record ? `${record.fullName} - ${record.phoneNumber}` : ``
                    }
                    translateChoice={false}
                    validate={[required()]}
                    debounce={debounce}
                  />
                </ReferenceArrayInput>
              ) : (
                formData.type === "complexe" && (
                  <ReferenceArrayInput
                    label={translate("reference.complexe")}
                    source="complexeIds"
                    reference="complexe"
                    perPage={perPageInputField}
                    filterToQuery={(searchText) => {
                      if (searchText)
                        return {
                          q: {
                            $or: [
                              { name: { $cont: searchText } },
                              { city: { $cont: searchText } },
                              { street: { $cont: searchText } },
                            ],
                          },
                          isSearch: true,
                        };
                    }}
                  >
                    <AutocompleteArrayInput
                      optionText={(record) =>
                        record
                          ? `${record.name} - ${record.city} ${record.street} ${record.streetNumber}`
                          : ``
                      }
                      translateChoice={false}
                      validate={[required()]}
                      debounce={debounce}
                    />
                  </ReferenceArrayInput>
                )
              )
            }
          </FormDataConsumer>
          <ArrayInput label={translate("general.items")} source="items" validate={[required()]}>
            <SimpleFormIterator>
              <FormDataConsumer>
                {({ getSource }) => (
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                      <ReferenceInput
                        fullWidth
                        validate={[required()]}
                        label={translate("reference.product")}
                        source={getSource("productId")}
                        reference="product"
                        filter={{ "active||$eq": true }}
                        filterToQuery={(searchText) => {
                          if (searchText) return { name: searchText };
                        }}
                      >
                        <AutocompleteInput />
                      </ReferenceInput>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <NumberInput
                        fullWidth
                        validate={[required(), minValue(0)]}
                        label={translate("general.price")}
                        source={getSource("price")}
                      />
                    </Grid>
                  </Grid>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      ) : (
        <div/>
      )}
    </Create>
  );
};

const PromotionsTitle = () => {
  const translate = useTranslate();
  return <span>{translate("reference.promotion")}</span>;
};
const PromotionEditToolbar = (props) => {
	return (<Toolbar {...props} >
      <SaveButton />
    </Toolbar>
  );
};

const PromotionsEdit = ({ permissions, ...props }) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const translate = useTranslate();

  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    const requestUrl = `${url}/promotion/${id}`;
    const options = { method: "GET" };
    httpClient(requestUrl, options)
      .then((response) => {
        setRecord(response.json);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [id]);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  return (
    <CustomEdit {...props}>
      {permissions === "admin" && (
        <SimpleForm record={record} redirect="show" toolbar={<PromotionEditToolbar />} onSubmit={''}>
          <DateInput source="validFrom" locales={locales} validate={[required()]} />
          <DateInput source="validTo" locales={locales} validate={[required()]} />
          <SelectInput
            validate={[required()]}
            source="type"
            choices={[
              {
                id: "general",
                name: translate(`promotion.type.general`),
              },
              { id: "complexe", name: translate(`promotion.type.complexe`) },
              { id: "member", name: translate(`promotion.type.member`) },
            ]}
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData &&
              formData.type &&
              formData.type !== "general" &&
              formData.type === "member" ? (
                <ReferenceArrayInput
                  label={translate("reference.member")}
                  source="memberIds"
                  reference="member"
                  perPage={perPageInputField}
                  filterToQuery={(searchText) => {
                    if (searchText)
                      return {
                        q: {
                          $or: [
                            { phoneNumber: { $cont: searchText } },
                            { fullName: { $cont: searchText } },
                          ],
                        },
                        isSearch: true,
                      };
                  }}
                >
                  <AutocompleteArrayInput
                    optionText={(record) =>
                      record ? `${record.fullName} - ${record.phoneNumber}` : ``
                    }
                    translateChoice={false}
                    validate={[required()]}
                    debounce={debounce}
                  />
                </ReferenceArrayInput>
              ) : (
                formData.type === "complexe" && (
                  <ReferenceArrayInput
                    resource="promotion"
                    label={translate("reference.complexe")}
                    source="complexeIds"
                    reference="complexe"
                    perPage={perPageInputField}
                    filterToQuery={(searchText) => {
                      if (searchText)
                        return {
                          q: {
                            $or: [
                              { name: { $cont: searchText } },
                              { city: { $cont: searchText } },
                              { street: { $cont: searchText } },
                            ],
                          },
                          isSearch: true,
                        };
                    }}
                  >
                    <AutocompleteArrayInput
                      optionText={(record) =>
                        record
                          ? `${record.name} - ${record.city} ${record.street} ${record.streetNumber}`
                          : ``
                      }
                      translateChoice={false}
                      validate={[required()]}
                      debounce={debounce}
                    />
                  </ReferenceArrayInput>
                )
              )
            }
          </FormDataConsumer>
          <ArrayInput label={translate("general.items")} source="items" validate={[required()]}>
            <SimpleFormIterator>
              <FormDataConsumer>
                {({ getSource }) => (
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                      <ReferenceInput
                        fullWidth
                        validate={[required()]}
                        label={translate("reference.product")}
                        source={getSource("productId")}
                        reference="product"
                        filter={{ "active||$eq": true }}
                        filterToQuery={(searchText) => {
                          if (searchText) return { name: searchText };
                        }}
                      >
                        <AutocompleteInput debounce={debounce} />
                      </ReferenceInput>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <NumberInput
                        fullWidth
                        validate={[required(), minValue(0)]}
                        label={translate("general.price")}
                        source={getSource("price")}
                      />
                    </Grid>
                  </Grid>
                )}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
          <BooleanInput source="active" />
        </SimpleForm>
      )}
    </CustomEdit>
  );
};

const PromotionsShow = ({ permissions, ...props }) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const translate = useTranslate();
  const redirect = useRedirect();
  const requestUrl = `${url}/promotion/${id}`;
  const options = { method: "GET" };
  const [record, setRecord] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
    httpClient(requestUrl, options)
      .then((response) => {
        setRecord({ ...response.json, id: id });
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [id]);

  const handleComplexeClick = (record) => {
    redirect(`/complexe/${record.id}/show`);
  };

  const handleMemberClick = (record) => {
    redirect(`/member/${record.id}/show`);
  };

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  return (
    <ShowView
      {...props}
      title={<PromotionsTitle />}
      record={record}
      hasEdit={permissions === "admin"}
    >
      <TabbedShowLayout>
        <Tab label={translate("general.summary")}>
          <DateField source="validFrom" locales={locales} />
          <DateField source="validTo" locales={locales} />
          {record.type === "complexe" ? (
            <ReferenceArrayField
              label={translate("reference.complexes")}
              reference="complexe"
              source="complexeIds"
              link="show"
            >
              <SingleFieldList>
                <FunctionField
                  render={(record) =>
                    record ? (
                      <Chip
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleComplexeClick(record);
                        }}
                        style={{ cursor: "pointer", margin: "0em 0.2em" }}
                        label={`${record.name} - ${record.city} ${record.street} ${record.streetNumber}`}
                      />
                    ) : (
                      ``
                    )
                  }
                />
              </SingleFieldList>
            </ReferenceArrayField>
          ) : record.type === "member" ? (
            <ReferenceArrayField
              label={translate("reference.members")}
              reference="member"
              source="memberIds"
              link="show"
            >
              <SingleFieldList>
                <FunctionField
                  render={(record) =>
                    record ? (
                      <Chip
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleMemberClick(record);
                        }}
                        style={{ cursor: "pointer", margin: "0em 0.2em" }}
                        label={`${record.fullName} ${record.phoneNumber}`}
                      />
                    ) : (
                      ``
                    )
                  }
                />
              </SingleFieldList>
            </ReferenceArrayField>
          ) : null}
          <BooleanField source="active" />
          <BooleanField source="ended" />
        </Tab>
        <Tab label={translate("reference.products")} path="products">
          <Datagrid
            optimized
            rowClick=""
            {...props}
            reference="promotion-item"
            target="promotionId"
            currentSort={{ field: "price", order: "ASC" }}
            data={keyBy(record.items, "id")}
            selectedIds={[]}
            ids={record.items.map(({ id }) => id)}
          >
            <ReferenceField
              label={translate("reference.product")}
              source="productId"
              reference="product"
              link=""
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <NumberField
              label={translate("general.price")}
              sortable={false}
              source="price"
              locales={locales}
              options={{ style: "currency", currency: currency }}
            />
          </Datagrid>
        </Tab>
      </TabbedShowLayout>
    </ShowView>
  );
};

const PromotionsFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <DateInput source="validFrom" alwaysOn locales={locales} />
      <DateInput source="validTo" alwaysOn locales={locales} />
      <SelectInput
        alwaysOn
        source="type"
        choices={[
          {
            id: "general",
            name: translate(`promotion.type.general`),
          },
          { id: "complexe", name: translate(`promotion.type.complexe`) },
          { id: "member", name: translate(`promotion.type.member`) },
        ]}
      />
      <BooleanInput
        source="active"
        alwaysOn
        parse={(val) => {
          if (val === true) return 1;
          if (val === false) return 0;
          return val;
        }}
      />
    </Filter>
  );
};

const PromotionsDetailsShow = (props) => {
  const translate = useTranslate();
  const { record } = props;
  return (
    <Box display="flex">
      {record.type === "complexe" ? (
        <ReferenceManyField
          {...props}
          reference="promotion-mapping"
          target="promotionId"
          addLabel={false}
        >
          <Datagrid optimized>
            <ReferenceField
              label={translate("reference.complexe")}
              source="principleId"
              reference="complexe"
              link=""
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      ) : record.type === "member" ? (
        <ReferenceManyField
          {...props}
          reference="promotion-mapping"
          target="promotionId"
          addLabel={false}
        >
          <Datagrid optimized>
            <ReferenceField
              label={translate("reference.member")}
              source="principleId"
              reference="member"
              link=""
              sortable={false}
            >
              <TextField source="fullName" />
            </ReferenceField>
          </Datagrid>
        </ReferenceManyField>
      ) : null}
      <Box mr="0.5em" ml="0.5em"/>
      <ReferenceManyField
        {...props}
        reference="promotion-item"
        target="promotionId"
        addLabel={false}
      >
        <Datagrid optimized>
          <ReferenceField
            label={translate("reference.product")}
            source="productId"
            reference="product"
            link=""
            sortable={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <NumberField
            label={translate("general.price")}
            sortable={false}
            source="price"
            locales={locales}
            options={{ style: "currency", currency: currency }}
          />
        </Datagrid>
      </ReferenceManyField>
    </Box>
  );
};

const PromotionsList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<PromotionsFilter />}
    bulkActionButtons={false}
    sort={{ field: "createdAt", order: "DESC" }}
    hasCreate={permissions === "admin"}
    actions={<GenericTopToolbar />}
  >
    <Datagrid optimized rowClick="show" expand={<PromotionsDetailsShow />}>
      <DateField source="validFrom" locales={locales} />
      <DateField source="validTo" locales={locales} />
      <PromotionTypeField source="type" />
      <BooleanField source="active"  />
      <BooleanField source="ended" />
    </Datagrid>
  </List>
);

export default {
  list: PromotionsList,
  create: PromotionsCreate,
  edit: PromotionsEdit,
  show: PromotionsShow,
  icon: Redeem,
};
