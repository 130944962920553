import React from "react";
import SaveWithModalButton from "./SaveWithModalButton";
import { Toolbar, useTranslate } from "react-admin";
import { omit } from "lodash";

const CreateToolBarWithCustomSave = (props) => {
  const translate = useTranslate();
  return (
    <Toolbar className={'flex-row flex-str'} {...omit(props, ["saveurl", "successmsg", "title", "goto", "callback", "refresh"])}>
      <SaveWithModalButton
        {...props}
        label={translate("general.save")}
        redirect={props?.redirect? true: false}
        submitOnEnter={true}
      />
        {props.delete}
    </Toolbar>
  );
};

export default CreateToolBarWithCustomSave;
