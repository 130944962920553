import { resolveBrowserLocale, usePermissions, useRedirect, useTranslate } from "react-admin";
import React, { useState } from "react";
import { groupBy } from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Check, Clear, Edit, ExpandMore } from "@material-ui/icons";
import { dateFormat } from "../utils.service";
import ReactJson from "react-json-view";
import httpClient from "../DataProvider/httpClient";
import { MultiFridgeEvent } from "./MultiFridgeEvent";
import { fridgeEvents } from "../constants";
import {url} from '../config/connection'

const language = localStorage.getItem("admin-language") || resolveBrowserLocale();
const direction = language === "he" ? "rtl" : "ltr";
const useStyles = makeStyles({
  head: {
    fontWeight: "bolder",
  },
  row: {
    width: "100%",
    borderBottom: "none",
    borderTop: "1px solid rgba(224,224,224,1)",
  },
  MuiTableCell: {
    paddingLeft: "0px",
  },
  uniqueCell: { minWidth: "30vw", overflow: "hidden", padding: "5px 0" },
  uniqueCellBold: {
    minWidth: "30vw",
    overflow: "hidden",
    padding: "5px 0",
    fontWeight: "bolder",
  },
  cell: { width: "12vw", padding: "5px 0" },
  cellBold: { width: "12vw", padding: "5px 0", fontWeight: "bolder" },

  lastCell: { minWidth: "50px" },
  tbody: {},
  table: {
    border: "none",
    backgroundColor: "rgba(224,224,224,0)",
  },
  mainAccordion: {
    border: "none",
    backgroundColor: "rgba(224,224,224,0)",
    "&:hover": {
      border: "2px solid silver",
    },
  },
  innerTable: {
    border: "none",
    backgroundColor: "slateblue",
    padding: 0,
    margin: 0,
    boxShadow: "none",
  },
  summary: {
    borderTop: "1px solid rgba(224,224,224,1)",
  },
  visible: {
    opacity: 0,
    height: 0,
    lineHeight: 0,
    padding: "0px",
    border: "none",
  },
  boxHead: {
    fontWeight: "bolder",
    padding: 2,
  },
  boxHeadColor: {
    color: "white",
    backgroundColor: "white",
    height: 0,
    margin: 0,
    padding: 0,
    fontSize: 0,
    lineHeight: 0,
    border: "none",
  },

  visibleCell: {
    opacity: 0,
    lineHeight: 0,
    height: 0,
    padding: "0 16px",
    border: "none",
  },
  flex: {
    display: "flex",
    alignItems: "center",
  },
  date: {
    minWidth: "6vw",
    padding: "5px",
  },
  dateBolder: {
    minWidth: "6vw",
    padding: "5px",
    fontWeight: "bolder",
  },
});

const FridgeEventHead = ({ classes }) => {
  const translate = useTranslate();
  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.row}>
          <TableCell className={classes.dateBolder}>{translate("general.createdAt")}</TableCell>
          <TableCell className={classes.uniqueCellBold}>{translate("general.message")}</TableCell>
          <TableCell className={classes.cellBold}>{translate("general.isError")}</TableCell>
          <TableCell className={classes.cellBold}>{translate("general.alert")}</TableCell>
          <TableCell className={classes.cellBold}>{translate("general.weightErrorId")}</TableCell>
          <TableCell className={classes.cellBold}>{translate("general.weightId")}</TableCell>
          <TableCell className={classes.cellBold}>{translate("general.fullName")}</TableCell>
          <TableCell className={classes.cellBold}>{translate("general.completed")}</TableCell>
          <TableCell className={classes.cellBold}>{translate("general.viewed")}</TableCell>
          <TableCell className={classes.cellBold}>{translate("ra.action.edit")}</TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};
const SingleFridgeEvent = ({ classes, currItem }) => {
  const translate = useTranslate();
  const permissions = usePermissions();
  return (
    <TableBody className={classes.tbody}>
      <TableRow className={classes.boxHeadColor}>
        <TableCell className={classes.boxHeadColor}>{translate("general.messageDate")}</TableCell>
        <TableCell className={classes.boxHeadColor}>{translate("general.message")}</TableCell>
        <TableCell className={classes.boxHeadColor}>{translate("general.isError")}</TableCell>
        <TableCell className={classes.boxHeadColor}>{translate("general.alert")}</TableCell>
        <TableCell className={classes.boxHeadColor}>{translate("general.weightErrorId")}</TableCell>
        <TableCell className={classes.boxHeadColor}>{translate("general.weightId")}</TableCell>
        <TableCell className={classes.boxHeadColor}>{translate("general.fullName")}</TableCell>
        <TableCell className={classes.boxHeadColor}>{translate("general.completed")}</TableCell>
        <TableCell className={classes.boxHeadColor}>{translate("general.viewed")}</TableCell>
        {permissions === "admin" ? (
          <TableCell className={classes.boxHeadColor}>{translate("ra.action.edit")}</TableCell>
        ) : (
          ""
        )}
      </TableRow>
      <Item error={currItem} />
    </TableBody>
  );
};

export const MsgPanel = (props) => {
  const uniqueCell = props.classes.uniqueCell;
  const message = props.message;
  if (!message) return <div />;
  let currMessage = JSON.parse(message);

  return (
    <TableCell className={uniqueCell}>
      <ReactJson
        displayDataTypes={false}
        theme={"summerfruit:inverted"}
        displayObjectSize={false}
        name={false}
        src={currMessage}
      />
    </TableCell>
  );
};

export const Item = ({ error,key }) => {
  const { permissions } = usePermissions();

  const [show, showMsg] = useState(false);
  let {
    completed,
    messageDate,
    id,
    weightErrorId,
    isError,
    member,
    message,
    messageId,
    weightId,
    viewed,
  } = error;
  let name = "";
  if (member?.fullName) {
    name = member?.fullName;
  }
  const redirect = useRedirect();
  const date = dateFormat(messageDate);

  const updateError = async (error, key, value) => {
    if (permissions === "admin") {
      let { id } = error;
      error[key] = value === 1 ? 0 : 1;
      value = value !== 1;
      const data = {};
      data[key] = value;
      await httpClient(`${url}/fridge-event/${id}`, {
        method: "PUT",
        body: JSON.stringify(data),
      });
    }
  };

  const editedMessage = message.length > 100 ? message.slice(0, 100) + "..." : message;

  const classes = useStyles();

  return (
    <TableRow key={key} className={classes.row} onClick={() => showMsg(!show)}>
      <TableCell className={classes.date}> {date}</TableCell>
      {!show ? (
        <TableCell className={classes.uniqueCell}>
          <div className={classes.uniqueCell}>
            <span>{editedMessage}</span>
            {editedMessage ? <ExpandMore /> : ""}
          </div>
        </TableCell>
      ) : (
        <MsgPanel classes={classes.cell} className={"msg"} message={message} />
      )}
      <TableCell className={classes.cell}>{isError ? <Check /> : <Clear />}</TableCell>
      <Tooltip title={fridgeEvents[messageId - 1]?.alert}>
        <TableCell className={classes.cell}>{messageId} </TableCell>
      </Tooltip>
      <TableCell className={classes.cell}>{weightErrorId === -1 ? "" : weightErrorId} </TableCell>
      <TableCell className={classes.cell}>{weightId === -1 ? "" : weightId} </TableCell>
      <TableCell className={classes.cell}>{name} </TableCell>
      <TableCell
        className={classes.cell}
        onClick={() => updateError(error, "completed", completed)}
      >
        {(completed === true ||completed===1)? <Check /> : <Clear />}
      </TableCell>
      <TableCell className={classes.cell} onClick={() => updateError(error, "viewed", viewed)}>
        {(viewed === true||viewed === 1) ? <Check /> : <Clear />}
      </TableCell>
      {permissions === "admin" ? (
        <TableCell
          style={{ paddingLeft: 0 }}
          className={classes.cell}
          onClick={() => redirect(`/fridge-event/${id}/edit`)}
        >
          <Edit />
        </TableCell>
      ) : (
        ""
      )}
    </TableRow>
  );
};

export const ErrorDataGrid = ({ children, ...rest }) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const translate = useTranslate();
  const fridges = groupBy(rest.data, "fridgeId");
  let rows = [];
  let count = 0;
  for (const [, alertsArray] of Object.entries(fridges)) {
    const serial = alertsArray[0].fridge.serial;
    const fridge = alertsArray[0].fridge;
    const alertIds = alertsArray.map((item) => item.messageId);
    const street = fridge?.complexe?.street || "";
    const city = fridge.complexe.city || "";
    const streetNumber = fridge?.complexe?.streetNumber || "";
    const address = city + " " + street + " " + streetNumber;

    alertsArray.sort((a, b) => new Date(b.messageDate) - new Date(a.messageDate));
    alertsArray.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    let nextMsgId = 0;
    let currMsgId = "";
    let prevMsgId = -1;
    let length = alertsArray.length - 1;
    let items = [];
    const renderedItems = [];

    const isSame = alertIds.filter((e) => e === alertIds[0]).length === alertIds.length;
    if (isSame) {
      const values = alertsArray.map((error) => {
        return <Item key={error.id} error={error} />;
      });
      rows.push(
        <React.Fragment>
          <Accordion className={classes.table}>
            <AccordionSummary className={classes.summary}>
              <Table className={classes.table}>
                {count === 0 ? (
                  <TableRow>
                    <TableCell className={classes.head}>{translate("general.serial")}</TableCell>
                    <TableCell className={classes.head}>
                      {translate("reference.complexe")}
                    </TableCell>
                    <TableCell className={classes.head}>{translate("general.alertNum")}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow className={classes.visible}>
                    <TableCell className={classes.visibleCell}>
                      {translate("general.serial")}
                    </TableCell>
                    <TableCell className={classes.visibleCell}>
                      {translate("reference.complexe")}
                    </TableCell>
                    <TableCell className={classes.visibleCell}>
                      {translate("general.alertNum")}
                    </TableCell>
                  </TableRow>
                )}
                <TableBody className={classes.table}>
                  <TableRow>
                    <TableCell className={classes.table}>{serial}</TableCell>
                    <TableCell style={{ width: "12vw" }} className={classes.table}>
                      {address}
                    </TableCell>
                    <TableCell className={classes.table}>{alertsArray?.length || 0}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </AccordionSummary>

            <AccordionDetails style={{ padding: 0 }}>
              <AccordionSummary>
                <Table size="small" aria-label="">
                  <TableHead>
                    <FridgeEventHead classes={classes} />

                    <MultiFridgeEvent
                      item={alertsArray[0]}
                      child={values}
                      classes={classes}
                      permissions={permissions}
                    />
                  </TableHead>
                </Table>
              </AccordionSummary>
            </AccordionDetails>
          </Accordion>
        </React.Fragment>
      );
      count++;
      continue;
    } else {
      for (let i = 0; i <= length; i++) {
        const currItem = alertsArray[i];
        currMsgId = alertsArray[i]?.messageId;
        nextMsgId = alertsArray[i + 1]?.messageId;
        prevMsgId = alertsArray[i - 1]?.messageId;
        //if firs item
        if (i === 0) {
          renderedItems.push(<FridgeEventHead key={i} classes={classes} />);
          if (currMsgId !== nextMsgId) {
            renderedItems.push(<SingleFridgeEvent key={currItem.id} classes={classes} currItem={currItem} />);
            items = [];
          }
          //continue to next Item
          else if (currMsgId === nextMsgId) {
            items.push(currItem);
          }
        }
        else if (i === length && prevMsgId !== currMsgId) {
          renderedItems.push(<SingleFridgeEvent key={currItem.id} classes={classes} currItem={currItem} />);

        } else {
          //if item is equal to prev and next push and continue
          if (nextMsgId === currMsgId && currMsgId === prevMsgId) {
            items.push(currItem);
          }
          //if  item is different from prevItem and nextItem create a single row
          else if (nextMsgId !== currMsgId && currMsgId !== prevMsgId) {
            items = [];
            renderedItems.push(<SingleFridgeEvent key={currItem.id} classes={classes} currItem={currItem} />);
          } else if (nextMsgId === currMsgId && currMsgId !== prevMsgId) {
            items = [];
            items.push(currItem);

          }
          //if  item is similar to prevItem and different from nextItem create multi row
          else if (nextMsgId !== currMsgId && currMsgId === prevMsgId) {
            items.push(currItem);
            const innerRows = items.map((error) => {
              return <Item error={error} key={error.id} />;
            });

            renderedItems.push(
              <MultiFridgeEvent
                item={currItem}
                classes={classes}
                child={innerRows}
                permissions={permissions}
              />
            );
            items = [];

          }
        }
      }
    }

    rows.push(
      <Accordion  className={classes.mainAccordion}>
        <AccordionSummary className={classes.summary}>
          <Table className={classes.table}>
            {count === 0 ? (
              <TableHead>
                <TableRow>
                  <TableCell className={classes.head}>{translate("general.serial")}</TableCell>

                  <TableCell className={classes.head}>{translate("reference.complexe")}</TableCell>

                  <TableCell className={classes.head}>{translate("general.alertNum")}</TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow className={classes.visible}>
                  <TableCell className={classes.visibleCell}>
                    {translate("general.serial")}
                  </TableCell>

                  <TableCell className={classes.visibleCell}>
                    {translate("reference.complexe")}
                  </TableCell>

                  <TableCell className={classes.visibleCell}>
                    {translate("general.alertNum")}
                  </TableCell>
                </TableRow>
              </TableHead>
            )}

            <TableBody className={classes.table}>
              <TableRow>
                <TableCell className={classes.table}>{serial}</TableCell>

                <TableCell style={{ width: "12vw" }} className={classes.table}>
                  {address}
                </TableCell>

                <TableCell className={classes.table}>{alertsArray?.length || 0}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionSummary>

        <AccordionDetails style={{ padding: 0 }}>
          <AccordionSummary>
            <div>{renderedItems.map((item) => item)}</div>
          </AccordionSummary>
        </AccordionDetails>
      </Accordion>
    );

    count++;
  }
  return <div style={{ direction }}>{rows.map((row) => row)}</div>;
};

export default ErrorDataGrid;
