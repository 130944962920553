import React, {Fragment, useState} from "react";
import { url } from "../config/connection";
import httpClient from "../DataProvider/httpClient";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {  CondOperator , RequestQueryBuilder } from "@nestjsx/crud-request";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useMediaQuery,
  Tooltip,
} from "@material-ui/core";
import {
  ArrayInput,
  DateInput,
  Error,
  FormDataConsumer,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  SimpleFormIterator,
  Toolbar,
  useNotify,

  useRedirect,
  useTranslate,
} from "react-admin";
import { Edit } from "@material-ui/icons";
import { useForm } from "react-final-form";
import {  uniqBy } from "lodash";

const { redColor } = require("../constants");

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  buttons: {
    color: "white",
    backgroundColor: "#ff3d00",
    "&:hover": {
      background: "#ff3d0080",
    },
    "&:disabled": {
      backgroundColor: "#848484e6",
      color: "white",
    },
  },
  errorText: {
    color: redColor,
    fontSize: "1.2rem",
  },
  addButton: {
    color: "#2ab535",
    float: "right",
  },
  floatRight: {
    float: "right",
  },
  totalText: {
    fontSize: "1.4rem",
  },
}));

const EditFormToolBar = (props) => {
  const form = useForm();
  const translate = useTranslate();
  const notify = useNotify();
  const closeFunction = props.close;
  const {  locationId } = props;
  const [isSaving, setIsSaving] = useState(null);

  const handleSave = () => {
    const state = form.getState();
    if (!state.hasValidationErrors) {
      setIsSaving(true);
      let values = state.values.inventory;
      const uniqExpDate = uniqBy(values, "expiration_time");
      if (uniqExpDate.length !== values.length) {
        form.change("error", translate("error.planogram.sameExpirationTime"));
        setIsSaving(false);
      } else {
        const requestUrl = `${url}/inventory/bulkQuantityChange`;
        values = values.map((value) => ({
          ...value,
          locationId: props.locationId,
        }));

        const options = {
          method: "POST",
          body: JSON.stringify({
            inventories: values,
            planogramId: props.planogramId,
          }),
        };
        httpClient(requestUrl, options)
          .then(() => {
            form.change("error", null);
            setIsSaving(false);
            closeFunction(
                props.planogramId,
              values.map((value) => ({ ...value, locationId: locationId }))
            );
          })
          .catch((error) => {
            const { message } = error;
            notify(translate(message), "warning");
            console.log(error);
          });
      }
    }
  };


  return (
    <Toolbar {...props}>
      <SaveButton
        {...props}
        label={translate("general.save")}
        saving={isSaving}
        handleSubmitWithRedirect={handleSave}
        onSave={handleSave}
        redirect={false}
        submitOnEnter={true}
      />
      <Button onClick={closeFunction}>{translate("general.cancel")}</Button>
    </Toolbar>
  );
};

export default function EditProductButton(props) {
  const [open, setOpen] = useState(false);
  const [location] = useState(props.location);
  const [error, setError] = useState(null);
  const [record, setRecord] = useState({});
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const redirect = useRedirect();

  const handleClick = () => {
    const queryString = RequestQueryBuilder.create({
      filter: {
        field: "locationId",
        operator: CondOperator.EQUALS,
        value: location.id,
      },
    }).query();
    const requestUrl = `${url}/inventory?${queryString}"`;
    const options = { method: "GET" };
    httpClient(requestUrl, options)
      .then((response) => {
        setRecord({ inventory: response.json });
        setOpen(true);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleClose = () => {
    closeDialog();
    redirect(`/`);
    redirect(`/planogram/${props.planogramId}/show`);
  };

  if (error) return <Error error={error} />;

  return (
    <Fragment>
      <Tooltip
        title={translate("general.editExpDates")}
        aria-label={translate("general.editExpDates")}
      >
        <IconButton
          variant="contained"
          color="primary"
          className={`${classes.buttons} ${classes.buttonEdit}`}
          onClick={handleClick}
        >
          <Edit />
        </IconButton>
      </Tooltip>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        fullWidth
        onClose={closeDialog}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {translate("title.editProductInLocation", {
            product: location?.product?.name,
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <SimpleForm
              toolbar={
                <EditFormToolBar
                  warehouseId={location.warehouseId}
                  locationId={location.id}
                  close={handleClose}
                  quantity={location.quantity}
                />
              }
              record={record}
             onSubmit={''}>
              <ArrayInput label={null} source="inventory">
                <SimpleFormIterator disableRemove>
                  <FormDataConsumer>
                    {({ getSource, scopedFormData }) => {
                      return (
                        <Box display="flex" fullWidth>
                          <DateInput
                            validate={[required()]}
                            label={translate("general.expiration_time")}
                            source={getSource("expiration_time")}
                            record={scopedFormData}
                            resource="inventory"
                          />
                          <Box mr="0.5em"/>
                          <NumberInput
                            validate={[required()]}
                            label={translate("general.quantity")}
                            source={getSource("quantity")}
                            record={scopedFormData}
                            resource="inventory"
                            max={location.quantity}
                            min={0}
                          />
                          <Box ml="0.5em" mr="0.5em"/>
                        </Box>
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
              <Box fullWidth>
                <span className={classes.totalText}>
                  {translate("general.total")} {location.quantity}
                </span>
              </Box>
              <FormDataConsumer>
                {({ formData,  }) =>
                  formData &&
                  formData.error && (
                    <Box fullWidth>
                      <span className={classes.errorText}>{formData.error}</span>
                    </Box>
                  )
                }
              </FormDataConsumer>
            </SimpleForm>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
