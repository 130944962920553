import React, { cloneElement } from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  ExportButton,
  Filter,
  FunctionField,
  Labeled,
  List,
  ReferenceManyField,
  required,
  sanitizeListRestProps,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
  useTranslate,
} from "react-admin";
import { Apartment, Close } from "@material-ui/icons";
import { Button, Grid } from "@material-ui/core";
import { get, isNil } from "lodash";
import {downloadExcel} from '../utils.service';

const nl2br = function nl2br(str, replaceMode, isXhtml) {
  const breakTag = isXhtml ? "<br />" : "<br>";
  const replaceStr = replaceMode ? "$1" + breakTag : "$1" + breakTag + "$2";
  return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
};

const MoreInfoField = ({ source, record = {} }) => {
  let moreInfo = get(record, source);
  if (isNil(moreInfo)) return null;
  moreInfo = nl2br(moreInfo, false, true);
  return <span dangerouslySetInnerHTML={{__html: moreInfo}}/>;
};

const ClassificationField = ({ source, record = {} }) => {
  const classification = get(record, source);
  const translate = useTranslate();
  if (isNil(classification)) return null;
  return (
    <Grid container direction="row" alignItems="center">
      <span>{translate(`classification.${classification}`)}</span>
    </Grid>
  );
};

const ComplexeCreate = ({ permissions, ...props }) => {
  return (
    <Create {...props}>
      {permissions === "admin" ? (
        <SimpleForm redirect="show" onSubmit={''}>
          <TextInput source="name" autoComplete="off" validate={[required()]} />
          <TextInput source="city" autoComplete="off" validate={[required()]} />
          <TextInput source="street" autoComplete="off" validate={[required()]} />
          <TextInput source="streetNumber" autoComplete="off" validate={[required()]} />
          <BooleanInput source="active" />
        </SimpleForm>
      ) : (
        <div/>
      )}
    </Create>
  );
};

const ComplexeTitle = ({ record }) => <span>{record.name}</span>;

const ComplexeEdit = ({ permissions, ...props }) => {
  return (
    <Edit {...props} title={<ComplexeTitle />}>
      {permissions === "admin" ? (
        <SimpleForm redirect="list" onSubmit={''}>
          <TextInput source="name" autoComplete="off" validate={[required()]} />
          <TextInput source="city" autoComplete="off" validate={[required()]} />
          <TextInput source="street" autoComplete="off" validate={[required()]} />
          <TextInput source="streetNumber" autoComplete="off" validate={[required()]} />
          <TextField source="otherId" />
          <BooleanInput source="active" />
        </SimpleForm>
      ) : (
        <div/>
      )}
    </Edit>
  );
};

const ComplexeShow = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <Show {...props} title={<ComplexeTitle />} hasEdit={permissions === "admin"}>
      <TabbedShowLayout>
        <Tab label={translate("general.summary")}>
          <TextField source="name" />
          <TextField source="shortName" />
          <TextField source="city" />
          <TextField source="street" />
          <TextField source="streetNumber" />
          <TextField source="otherId" />
          <Labeled label={translate("general.moreInfo")}>
            <MoreInfoField source="moreInfo" />
          </Labeled>
          <TextField source="zipCode" />
          <TextField source="postalCode" />
          <Labeled label={translate("general.classification")}>
            <ClassificationField source="classification" />
          </Labeled>
          <BooleanField source="active" />
        </Tab>
        <Tab label={translate("reference.fridges")} path="fridges">
          <ReferenceManyField
            {...props}
            reference="fridge"
            target="complexeId"
            addLabel={false}
            link="show"
          >
            <Datagrid rowClick="show" optimized>
              <TextField source="serial" label={translate("general.serial")} />
              <BooleanField source="active" label={translate("general.active")} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const ComplexeFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      <TextInput source="city" alwaysOn />
      <TextInput source="street" />
      <TextInput source="streetNumber" />
      <TextInput source="otherId" />
      <BooleanInput
        source="active"
        parse={(val) => {
          if (val === true) return 1;
          if (val === false) return 0;
          return val;
        }}
      />
      <BooleanInput
        alwaysOn
        label={translate(`general.pazComplexe`)}
        source="otherId||$gte"
        parse={(val) => {
          if (val === true) return "0";
          return "";
        }}
      />
      <SelectInput
        alwaysOn
        emptyText={translate(`general.all`)}
        label={translate(`general.fridgeCount`)}
        source="fridges.id||$isnull"
        choices={[
          {
            id: "0",
            name: translate(`general.zero`),
          },
        ]}
      />
    </Filter>
  );
};

const ComplexeToolbar = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {filters && (
        <Button
          color="primary"
          size="small"
          startIcon={<Close />}
          onClick={() => rest.setFilters({})}
        >
          {translate("general.clearFilter")}
        </Button>
      )}
      {permissions === "admin" && <CreateButton basePath={basePath} />}
      {/*{permissions === 'admin' && <ComplexeConvertButton />}*/}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={()=>downloadExcel(Object.values(rest.data),rest.defaultTitle)}
        maxResults={maxResults}
      />
      {/* <Button
        color='primary'
        size='small'
        startIcon={<Apartment />}
        onClick={() => {

        }}
      >
        {translate('general.fridgeStatus')}
      </Button> */}
    </TopToolbar>
  );
};

ComplexeToolbar.defaultProps = {
  selectedIds: [],
};

const ComplexeList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filters={<ComplexeFilter />}
      hasCreate={permissions === "admin"}
      actions={<ComplexeToolbar />}
      bulkActionButtons={false}
      filter={{
        join: ["fridges||id,deletedAt"],
      }}
    >
      <Datagrid rowClick="show" optimized>
        <TextField source="name" />
        <TextField source="city" />
        <TextField source="street" />
        <TextField source="streetNumber" />
        <TextField source="otherId" />
        <FunctionField
          label={translate("general.associatedFridges")}
          render={(record) => {
            return record && record.fridges && record.fridges.length ? (
              <span>{record.fridges.filter((fridge) => !fridge.deletedAt).length}</span>
            ) : (
              <span> 0 </span>
            );
          }}
        />
        <ClassificationField source="classification" />
        <BooleanField source="active" />
      </Datagrid>
    </List>
  );
};

export default {
  list: ComplexeList,
  create: ComplexeCreate,
  edit: ComplexeEdit,
  show: ComplexeShow,
  icon: Apartment,
};
