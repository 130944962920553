import React, {useEffect, useState} from 'react'
import {url} from '../config/connection'
import {Box, Button, Grid, IconButton, Tooltip} from '@material-ui/core'
import {Add, Cached, Remove} from '@material-ui/icons'
import {
	Confirm,
	EditButton,
	Error,
	Loading,
	useNotify,
	usePermissions,
	useRedirect,
	useRefresh,
	useTranslate
} from 'react-admin'
import CustomPage from '../components/CustomPage'
import {makeStyles} from '@material-ui/core/styles'
import {cloneDeep, flattenDeep, groupBy, minBy} from 'lodash'
import httpClient from '../DataProvider/httpClient'
import {Link} from 'react-router-dom'
import EditProductButton from '../Locations/EditProductButton'
import ChangeProductButton from '../Locations/ChangeProductButton'
import {dateFormat} from "../utils.service";
import ChangeCalibrateForFridgeButton from '../Locations/ChangeCalibrateForFridgeButton';
import ChangeOffsetForFridgeButton from '../Locations/ChangeOffsetForFridgeButton';

const {redColor, orangeColor, yellowColor} = require ('../constants')

const PlanogramShow = props => {
	const {
		match: {
			params: {id}
		}
	} = props
	const {permissions} = usePermissions ()
	const useStyles = makeStyles (() => ({
		root: {
			alignItems: 'center',
			flexWrap: 'nowrap !important',
			direction: 'ltr',
			flip: false
		},
		quantityText: {
			fontSize: '1.4rem'
		},
		bold: {
			fontWeight: 'bold'
		},
		headerdiv: {
			position: 'relative',
			width: '100%'
		},
		headerText: {
			width: '85%',
			margin: 'auto',
			fontSize: '1.4rem'
		},
		inlineGrid: {
			display: 'grid'
		},
		box: {
			flexGrow: '1',
			display: 'inline-box',
			padding: '0.2rem',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			minWidth: '15rem',
			minHeight: '20rem',
			margin: ' 1rem',
			border: '2px solid #80808047'
		},
		redColor: {
			color: redColor
		},
		productImg: {
			maxWidth: '15rem',
			maxHeight: '10rem',
			minHeight: '10rem'
		},
		yellowColor: {
			color: yellowColor
		},
		orangeColor: {
			color: orangeColor
		},
		redBackColor: {
			backgroundColor: redColor,
			color: 'white',
			borderRadius: '1rem',
			padding: '1px 5px'
		},
		yellowBackColor: {
			backgroundColor: yellowColor,
			borderRadius: '1rem',
			padding: '1px 5px'
		},
		orangeBackColor: {
			backgroundColor: orangeColor,
			borderRadius: '1rem',
			padding: '1px 5px'
		},
		blackColor: {
			color: 'black'
		},
		editDiv: {
			float: 'right'
		},
		oneProduct: {},
		buttons: {
			color: 'white',
			backgroundColor: '#ff3d00',
			'&:hover': {
				background: '#ff3d0080'
			}
		},
		buttonPlus: {
			marginRight: '1.5rem',
			marginLeft: '1rem'
		},
		buttonMinus: {
			marginLeft: '1.5rem',
			marginRight: '1rem'
		}, modal: {
			direction: 'rtl'
		}
	}))

	const translate = useTranslate ()
	const classes = useStyles ()
	const redirect = useRedirect ()
	const notify = useNotify ()
	const refresh = useRefresh ()
	const [locations, setLocations] = useState ([])
	const [fridge, setFridge] = useState ({id: ' '})
	const [planogram, setPlanogram] = useState ('')
	const [profile, setProfile] = useState ('')
	const [fridgeStructure, setFridgeStructure] = useState ('')
	const [loading, setLoading] = useState (true)
	const [error, setError] = useState ()


	const [open, setOpen] = useState (false);
	const handleClick = () => setOpen (true);
	const handleDialogClose = () => setOpen (false);

	const handleConfirm = () => {
		replacePlanogram (fridge.id)
		refresh ()
		setOpen (false);
	};


	const changeProductAmount = (locationId, update) => {
		try {
			const locationRows = [...locations]
			const mapping = locationRows.map (row => {
				return row.map ((col) => {
					if (col.id === locationId) {
						return {...col, quantity: col.quantity + update}
					}
					else {
						return col
					}
				})
			})
			setLocations (mapping)
			setLoading (false)
		} catch (error) {
			setError (error)
			setLoading (false)
		}
	}
	const handleCreateTransactionClick = async (type, data) => {
		const locationId = data?.id
		if (type === 'minus' && data.quantity <= 0) {
			notify (translate ('error.planogram.minusZero'), 'warning')
		}
		else {

			try {
				const changeProductAmountUrl = `${url}/inventory/updateByLocation/${locationId}`
				const update = type === "minus" ? -1 : 1
				changeProductAmount (locationId, update)
				await httpClient (changeProductAmountUrl, {
					method: 'PUT',
					body: JSON.stringify ({update, planogramId: props.match.params.id})
				})

			} catch (e) {
				return e
			}

		}
	}
	const manipulateLocation = (results) => {
		let emptyShelves=0
		let log=''
		if (results?.length>0){
			 results.forEach((l)=>{
				if (!l.productId){
					emptyShelves++
					log += `${translate('general.row')} ${l?.row} ,${translate('general.column')}  ${l.column} | `

				}
			})
			log=log.slice(0,log.length-2)
			if (emptyShelves>0){
				notify(
					`${translate('ra.notification.empty_shelves')} ` + log,'warning', {emptyShelves},false,15000,true)
			}
		}
		const groupByRow = groupBy (results, 'row')
		Object.keys (groupByRow).forEach (row => {
			const rowLocations = groupByRow[row]
			const rowIndexInArray = row - 1
			rowLocations.forEach (rowLocation => {
				if (!locations[rowIndexInArray]) {
					locations[rowIndexInArray] = []
				}
				locations[rowIndexInArray][rowLocation.column - 1] = rowLocation
			})
		})
		return cloneDeep (locations)
	}
	const handleChangeProduct = (newPlanogram) => {
		if (newPlanogram.response){
			notify(newPlanogram.response, 'warning')
			return
		}
		if (newPlanogram.id!==Number(id)){
			redirect('/planogram/'+newPlanogram.id+'/show')
		}
		else {
			const newLocations =manipulateLocation(newPlanogram.locations)
			setLocations (newLocations)
		}
	}

	const changeExpirationDate = data => {
		if (data && data.length) {
			data = data.filter (d => d.quantity > 0)
			const min = minBy (data, 'expiration_time')
			if (min) {
				const location = flattenDeep (locations).find (
					location => location.id === min.locationId
				)
				if (location) {
					const cloneLocation = cloneDeep (locations)
					cloneLocation[location.row - 1][location.column - 1].minDate =
						min.expiration_time
					setLocations (cloneLocation)
				}
			}
		}
	}

	const expirationClass = function expirationClass (
		expirationDate,
		expireAlertDays
	) {
		const expireAlertDaysInMilliseconds = expireAlertDays * 24 * 60 * 60 * 1000
		const alertDate = new Date (
			expirationDate.getTime () - expireAlertDaysInMilliseconds
		)
		const today = new Date ()
		if (today.getTime () > expirationDate.getTime ()) return classes.redBackColor
		if (today.getTime () > alertDate.getTime ()) return classes.orangeBackColor
		return null
	}


	useEffect (() => {
		const manipulateLocation = (results) => {
			const groupByRow = groupBy (results, 'row')
			Object.keys (groupByRow).forEach (row => {
				const rowLocations = groupByRow[row]
				const rowIndexInArray = row - 1
				rowLocations.forEach (rowLocation => {
					if (!locations[rowIndexInArray]) {
						locations[rowIndexInArray] = []
					}
					locations[rowIndexInArray][rowLocation.column - 1] = rowLocation
				})
			})
			return cloneDeep (locations)
		}
		httpClient (`${url}/planogram/${id}`, {method: 'GET'})
			.then (response => {
				const {name, fridge, fridgeStructure, profile} = response.json
				const returnLocation = response.json.locations
				if (returnLocation.length === 0) {
					//todo: redirect to??
				}
				else {
					setProfile (profile)
					setPlanogram (name)
					setFridgeStructure (fridgeStructure)
					const newLocations = manipulateLocation (returnLocation)
					setLocations (newLocations)
					if (fridge) {
						setFridge ({...fridge})
					}
				}
				setLoading (false)
			})
			.catch (error => {
				setError (error)
				setLoading (false)
			})

	}, [id])
	const replacePlanogram = async (fridgeId) => {
		try {
			const payload = JSON.stringify ({id: fridgeId})
			setLoading (true)
			const {json}=await httpClient (url + `/fridge/switchPlanogram`, {method: 'POST', body:payload})
				notify (translate ('summary.planogram.replace.successMsg'), 'success')
				setLoading (false)
				redirect ('/planogram/' + json?.planogramId + '/show')
		} catch (e) {
			setLoading (false)
			notify (translate ('summary.planogram.replace.failMsg') + ' ' + e.message, 'warning')
		}
	}
	if (loading) return <Loading/>
	if (error) return <Error error={error}/>
	if (!locations) return null
	return (
		<CustomPage title={translate ('title.planogramShow', {name: planogram})} style={profile?.name} >
			<div className={classes.editDiv}>

				{fridge.profileId >= 1 && fridge?.id >= 1 && (permissions === "admin" || permissions === "driver") ?
					<Button
						onC
						color="primary"
						size="small"
						component={Link}
						onClick={handleClick}
						startIcon={<Cached/>}
					>
						{translate ('general.changePlanogram')}
					</Button> : ''}
				{fridge.profileId >= 1 && fridge?.id >= 1 && (permissions === "admin" || permissions === "driver") ?
					<Confirm
						isOpen={open}
						loading={loading}
						title={translate ('general.changePlanogram')}
						content={translate ('ra.message.are_you_sure')}
						onClose={handleDialogClose}
						onConfirm={handleConfirm}
						classes={classes.modal}


					/>

					: ''}

				{permissions === 'admin' && (

					<EditButton to={`/planogram/${id}`}/>
				)}
			</div>
			<Grid container>
				<Grid item xs={12} className={classes.oneProduct}>
					<Grid container className={'flex-column'} spacing={2}>
						{planogram ? <div className={'flex-column'}>
							{fridge?.id < 1 && <div className={'flex-column'}><h2>    {planogram}

							</h2>
								{fridgeStructure?.name &&
									<span>{translate ('reference.fridgeStructure')} {fridgeStructure?.name}</span>}
							</div>
							}
							{fridge?.id >= 1 &&
								<div className={'flex-column'}>
									<h2>{translate ('reference.fridge') + ' ' + fridge?.serial +' - '+ fridge.complexe.name}
									</h2>
									{fridgeStructure?.name &&
										<span>{translate ('reference.fridgeStructure')} {fridgeStructure?.name}</span>}
									<span>{translate ('reference.fillDate')}: {dateFormat (fridge.fillDate)}</span>
								</div>}


							{profile && <h4> {translate ('resources.profile.profileName')} - {profile?.name}</h4>}
							{profile &&
								<h4 style={{color: 'red'}}>{profile && translate ('resources.planogram.msg')} </h4>}
						</div> : ''}
					</Grid>
				</Grid>
			</Grid>
			{locations.map ((rows, idx) => {
				return (
					<Grid key={idx} container className={classes.root} spacing={2}>
						<span style={{fontSize: '2rem'}}>{idx + 1}</span>
						{rows.map ((column, index) => {
							const prec = column.quantity / column.standard_quantity
							const minDate = new Date (column.minDate)
							const image = column?.product?.imageUrl
								? `${url}/s3/uploads/${column.product.imageUrl}`
								: null
							return (
								<Box key={`${idx}-${index}`} className={classes.box}
								     style={{width: column.shelfType.dimensions.width}}>
									<Grid container className={classes.inlineGrid}>
										<Grid item xs={12} className={classes.oneProduct}>
											<div className={`${classes.headerdiv}`}>
												<div
													className={`${classes.bold} ${classes.headerText}`}
												>
													{column?.name}
												</div>
												{permissions === 'admin' && (
													<ChangeProductButton
														location={column}
														planogramId={`${id}`}
														disabled={column.quantity > 0}
														onClose={handleChangeProduct}
													/>
												)}
											</div>
											<span>{column?.product?.name}</span>
											<br/>
											{image && <img
												onClick={()=>redirect(`/product/${column?.product?.id}/show`)}
												alt={column?.product?.name}
												 src={`${image}`} className={classes.productImg}/>}
											<br/>
											<span
												className={`${classes.quantityText} ${classes.bold}`}
											>
                        <span
	                        className={`${
		                        prec <= 0
			                        ? classes.redColor
			                        : prec <= 0.2
				                        ? classes.yellowColor
				                        : prec > 0.2 && prec <= 0.5
					                        ? classes.orangeColor
					                        : classes.blackColor
	                        }`}
                        >

                          {column.quantity || 0}
                        </span>
                        /{column.standard_quantity || 0}
                      </span>
											<br/>
											{column.minDate && column.quantity > 0 && (
												<span>
                          {translate ('general.closestExpDate')}:{' '}
													<span
														className={expirationClass (
															minDate,
															column.expireAlertDays
														)}
													>
                            {minDate.getDate ()}/{minDate.getMonth () + 1}/
														{minDate.getFullYear ()}
                          </span>
                        </span>
											)}
											<br/>
										</Grid>
										{(fridge?.id >= 1 && permissions === 'admin' && column.productId > 0) && (
											<Grid item xs={12}>
												<div style={{alignContent: 'space-between'}}>
													<Tooltip
														title={translate ('general.addQuantity')}
														aria-label={translate ('general.addQuantity')}
													>
														<IconButton
															className={`${classes.buttons} ${classes.buttonPlus}`}
															variant="contained"
															color="secondary"
															onClick={() => {
																handleCreateTransactionClick ('plus', column)
															}}
														>
															<Add/>
														</IconButton>
													</Tooltip>
													<EditProductButton
														location={column}
														planogramId={id}
														onClose={changeExpirationDate}
													/>
													<Tooltip
														title={translate ('general.removeQuantity')}
														aria-label={translate ('general.removeQuantity')}
													>
														<IconButton
															className={`${classes.buttons} ${classes.buttonMinus}`}
															variant="contained"
															color="secondary"
															onClick={() => {
																handleCreateTransactionClick ('minus', column)
															}}
														>
															<Remove/>
														</IconButton>
													</Tooltip>

												</div>
												{column && <div>
													<span>{translate ('general.absoluteWeight')}: {column?.tareWeightCurrent.toFixed (2)}</span>
													<br/>
													<span>{translate ('general.amountOfProducts')}: {column?.amountOfProductByWeights} </span>
													<br/>
													<span>{translate ('general.tareGram')}: {column?.tareGramOffset} </span>
													<br/>
													{column?.weightOffset > 0 ?
														<span>{translate ('general.weightOffset')}: {((column.weightOffset - 1) * 100).toFixed (2)}%</span> : ''}
													<br/>
													{permissions === 'admin' && <span> <ChangeCalibrateForFridgeButton
														location={column}
														fridgeId={fridge?.id}
														onClose={handleChangeProduct}
													/></span>}
													{(permissions === 'admin' && column?.weightOffset) ?
														<span> <ChangeOffsetForFridgeButton
															location={column}
															onClose={handleChangeProduct}
														/></span> : ''}
												</div>}
											</Grid>
										)}
									</Grid>
								</Box>
							)
						})}
					</Grid>
				)
			})}
		</CustomPage>
	)
}

export default PlanogramShow
