import React, {useEffect, useState} from "react";
import {
	Error,
	Loading,
	maxValue,
	minValue, NumberInput,
	required,PasswordInput,
	SimpleForm, BooleanInput, useNotify,
	useTranslate,  Confirm
} from "react-admin";
import { Settings} from "@material-ui/icons";
import CustomPage from "../components/CustomPage";
import httpClient from "../DataProvider/httpClient";
import {url} from "../config/connection";
import CreateToolBarWithCustomSave from '../components/CreateToolBarWithCustomSave';


const SettingsEdit = (props) => {

	const notify = useNotify ()
	const translate = useTranslate ();
	const [recordSettings, setRecordSettings] = useState ({});
	const [settingData, setSettingData] = useState ({});
	const [record] = useState ({});
	const [loading, setLoading] = useState (false);
	const [error, setError] = useState ();
	const [isOpen, setOpen] = useState (false);


	useEffect (() => {
		const options = {method: "GET"};
		const requestUrl = `${url}/settings/getSettings`;
		httpClient (requestUrl, options)
			.then ((response) => {
				const settings = response.json;
				let cpuUsage = settings?.cpuUsage || 0
				let batteryTemp = settings?.batteryTemp || 0
				let roundingThreshold = settings?.roundingThreshold || 0
				let offlineMode = Boolean (parseInt (settings?.offlineMode)) || 0
				setRecordSettings ({batteryTemp: batteryTemp, cpuUsage: cpuUsage, offlineMode, roundingThreshold})
				setLoading (false);
			})
			.catch ((error) => {
				setError (error);
				setLoading (false);
			});
	}, []);

	function handleConfirm () {
		setOpen (!isOpen);
		httpClient (`${url}/settings/setSettings`, {method: "PATCH", body: JSON.stringify (settingData)})
			.then (() =>
				       notify ("summary.settings.setSettings", 'success')
			).catch (() => {
			notify ("summary.settings.setThresholds", 'warning')

		})

	}

	function handleDialogClose () {
		setOpen (!isOpen);
	}

	function openConfirmAndSetData (data) {
		setOpen (true)
		setSettingData (data)

	}

	if (loading) return <Loading/>;
	if (error) return <Error error={error}/>;
	return (
		<CustomPage {...props} title={translate ("resources.settings.name")}>
			<SimpleForm
				record={recordSettings}
				save={(e) => openConfirmAndSetData (e)}
			>
				<NumberInput
					source="cpuUsage"
					validate={[required (), maxValue (100), minValue (0)]}
				/>
				<NumberInput
					source="batteryTemp"
					validate={[required (), maxValue (100), minValue (0)]}
				/>
				<NumberInput
					source="roundingThreshold"
					validate={[required (), maxValue (1000), minValue (0)]}
				/>
				<BooleanInput source="offlineMode"/>
				{isOpen ? (
					<Confirm
						isOpen={isOpen}
						loading={loading}
						content={translate ("ra.message.are_you_sure")}
						onClose={handleDialogClose}
						onConfirm={handleConfirm}
						title={''}

					/>
				) : (
					""
				)}
			</SimpleForm>
			<SimpleForm

				record={record}
				toolbar={
					<CreateToolBarWithCustomSave
						saveurl={`/settings/updateAlgoRetailToken`}
						successmsg="summary.settings.updateAlgoRetailToken"
						title={translate("summary.promotion.title")}
					/>
				}
				onSubmit={''}>
				<PasswordInput
					label={translate ("general.algoRetailToken")}
					source="algoToken"
					autoComplete="off"
					validate={[required ()]}
				/>

			</SimpleForm>
		</CustomPage>
	);
};

export default {
	list: SettingsEdit,
	icon: Settings
};
