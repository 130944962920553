import React from "react";
import {
  DateField,
  Filter,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useTranslate,
} from "react-admin";
import TransactionCreate from "./TransactionCreate";
import TransactionListActions from "./TransactionListActions";
import { SwapVert } from "@material-ui/icons";
import TenantDataGrid from "../components/TenantDataGrid";
import { perPageInputField } from "../constants";

const { locales } = require("../constants");

const TransactionTitle = ({ record }) => <span>{record.name}</span>;

const TransactionShow = (props) => {
  const translate = useTranslate();
  return (
    <Show {...props} title={<TransactionTitle />} actions={null}>
      <TabbedShowLayout>
        <Tab label={translate("general.summary")}>
          <ReferenceField
            label={translate("reference.transaction-type")}
            source="transactionTypeId"
            reference="transaction-type"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label={translate("reference.user")}
            source="userId"
            reference="user"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        </Tab>
        <Tab label={translate("reference.products")} path="products">
          <ReferenceField
            label={translate("reference.product")}
            source="productId"
            reference="product"
          >
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="quantity" />
          <DateField source="expiration_time" locales={locales} />
        </Tab>
        <Tab label={translate("reference.warehouses")} path="warehouses">
          <ReferenceField
            label={translate("general.warehouseFrom")}
            source="warehouseFromId"
            reference="warehouse"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label={translate("general.warehouseTo")}
            source="warehouseToId"
            reference="warehouse"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const TransactionFilter = (props) => {
  const translate = useTranslate();
  const orderTransactionType =   localStorage.getItem("orderTransactionType");
  return (
    <Filter {...props}>
      <ReferenceInput
        label={translate("reference.transaction-type")}
        source="transactionTypeId"
        filter={{ "id||$ne": orderTransactionType }}
        reference="transaction-type"
        perPage={perPageInputField}
        alwaysOn
      >
        <SelectInput />
      </ReferenceInput>
    </Filter>
  );
};

const TransactionList = ({ permissions, ...props }) => {
  const translate = useTranslate()
  const orderTransactionType = window.orderTransactionType

	// const filter = orderTransactionType ? {'transactionTypeId||$ne': null} : ''
  return (
    <List
      {...props}
      hasCreate={permissions === "admin"}
      bulkActionButtons={false}
      filters={<TransactionFilter />}
      actions={<TransactionListActions />}
      filter={{ "transactionTypeId||$ne": orderTransactionType }}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <TenantDataGrid rowClick="show">
        <ReferenceField
          label={translate("reference.transaction-type")}
          source="transactionTypeId"
          reference="transaction-type"
          link=""
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          allowEmpty={false}
          label={translate("general.warehouseFrom")}
          source="warehouseFromId"
          link="show"
          reference="warehouse"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          allowEmpty={false}
          label={translate("general.warehouseTo")}
          source="warehouseToId"
          link="show"
          reference="warehouse"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          allowEmpty={false}
          label={translate("reference.product")}
          source="productId"
          link="show"
          reference="product"
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="quantity" />
        <DateField source="expiration_time" locales={locales} />
        <DateField
          label={translate("general.createdAt")}
          showTime={true}
          options={{
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }}
          source="createdAt"
          locales={locales}
        />
      </TenantDataGrid>
    </List>
  );
};

export default {
  list: TransactionList,
  create: TransactionCreate,
  show: TransactionShow,
  icon: SwapVert,
};
