import React, {useEffect, useState} from "react";
import {
  ArrayInput,
  AutocompleteInput,
  Error,
  Loading,
  maxValue,
  minValue,
  NumberInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useGetOne,
  useTranslate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { url } from "../config/connection";
import CreateToolBarWithCustomSave from "../components/CreateToolBarWithCustomSave";
import { groupBy } from "lodash";
import httpClient from '../DataProvider/httpClient';



const PlanogramEdit = (props) => {
  const [products, setProducts] = useState([]);
  const planogramId = props.id;
  const translate = useTranslate();
  const useStyles = makeStyles(() => ({
    root: {
      direction: "ltr",
      flip: false,
    },
    columnItem: {
      display: "inline-flex",
      "& > ul": {
        display: "inline-flex",
        "& > li": {
          border: "none",
          flexDirection: "column",
          margin: "1rem",
        },
      },
    },
    editDiv: {
      float: "right",
    },
  }));
  useEffect(() => {
    const options = { method: "GET" };
    const requestUrl = `${url}/product?filter[0]=active||$ne||0`;
    httpClient(requestUrl, options)
      .then((response) => {
        setProducts(response.json);
      })

  },[] );
  const classes = useStyles();
  const { data, loading, error } = useGetOne(props.resource, props.id);
  let shelvesGroupedByRows = groupBy(data?.locations, "row");
  const name = data?.name;
  const id = data?.id;
  const fridgeId = data?.fridgeId;
  const fridgeStructure = data?.fridgeStructure;
  const rows = [];
  Object.keys(shelvesGroupedByRows).forEach((row) => {
    const columns = [];
    shelvesGroupedByRows[row].forEach((cell) => {
      columns[cell.column - 1] = {
        id: cell.id,
        hardwareId: cell.hardwareId,
        maximum: cell.maximum,
        productName: cell?.product?.name,
        productUrl: `${url}/s3/uploads/${cell.product?.imageUrl}`,
        shelfId: cell.shelfId,
        shelfType: cell.shelfType,
        productId: cell?.productId,
        standard_quantity: cell?.standard_quantity,
      };
    });
    rows.push({ columns });
  });
  const record = { id, name, rows, fridgeStructure, fridgeId };
  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (data) {
    return (
      <SimpleForm
        record={record}
        toolbar={
          <CreateToolBarWithCustomSave
            saveurl={`/planogram/${planogramId}/edit`}
            successmsg={translate("summary.planogram.edit.successMsg")}
            title={translate("summary.planogram.edit.failMsg")}
            goto={"/fridge"}
          />
        }
        redirect="show"
      >
        <TextInput
          source={"name"}
          optionText="name"
          disabled={record?.fridgeId >= 1}
          validate={[required()]}
          style={{ width: "100%" }}
        />
        <TextInput
          source="fridgeStructure.name"
          label={translate("resources.fridge-structure.name")}
          optionVa
          disabled
          validate={[required()]}
        />
        {record.rows && (
          <ArrayInput
            source="rows"
            label={translate("general.shelves")}
            className={classes.root}
          >
            <SimpleFormIterator
              disableRemove
              disableAdd
              disableReordering
              className={classes.root}
            >
              <ArrayInput
                source="columns"
                label={translate("general.column")}
                className={classes.columnItem}
              >
                <SimpleFormIterator
                  disableRemove
                  disableAdd
                  disableReordering
                  className={classes.columnItem}
                >
                  {data?.fridge && (
                    <NumberInput
                      source={"hardwareId"}
                      validate={[required(), minValue(1), maxValue(32)]}
                      label={translate("general.hardwareId")}
                      isRequired={true}

                    />
                  )}
                  <AutocompleteInput
                    choices={products}
                    label={translate("reference.product")}
                    source={"productId"}
                    isRequired={true}
                  />
                  <NumberInput
                    min={0}
                    label={translate("general.standard_quantity")}
                    source="standard_quantity"
                    validate={[required(), minValue(1)]}
                    isRequired={true}
                    defaultValue={7}


                  />
                  <NumberInput
                    min={0}
                    label={translate("general.maximum")}
                    source="maximum"
                    validate={[required(), minValue(1)]}
                    isRequired={true}
                    defaultValue={25}

                  />
                  <TextInput
                      source={'shelfType.shelfSize'}
                    disabled
                    label={translate("general.shelfSize")}
                      isRequired={true}

                  />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
        )}
      </SimpleForm>
    );
  }
};

export default PlanogramEdit;
