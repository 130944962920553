import React, { cloneElement, Fragment } from "react";
import ReactJson from "react-json-view";
import {
  BooleanField,
  BooleanInput,
  DateField,
  DateTimeInput,
  Edit,
  ExportButton,
  Filter,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  sanitizeListRestProps,
  SaveButton,
  SelectField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
  useUnselectAll,
  useUpdateMany,
} from "react-admin";
import { Button } from "@material-ui/core";
import { CheckBox, CheckCircle, Close, Warning } from "@material-ui/icons";
import ErrorDataGrid from "./CustomEventGrid";
import { fridgeEvents } from "../constants";
import {downloadExcel} from '../utils.service';

const { locales } = require("../constants");

const FridgeTitle = ({ record }) => <span>{record.name}</span>;

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const MsgPanelWithRecord = (props) => {
  const record = useRecordContext(props);
  const message = record.message;
  if (!message) return <div></div>;
  let currMessage = JSON.parse(message);

  return (
    <ReactJson
      displayDataTypes={false}
      style={{ width: "40vw" }}
      theme={"summerfruit:inverted"}
      displayObjectSize={false}
      name={false}
      src={currMessage}
    />
  );
};
const FridgeEventEdit = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <Edit {...props} title={<FridgeTitle />}>
      {permissions === "admin" && (
        <SimpleForm redirect="show" toolbar={<CustomToolbar />} onSubmit={''}>
          <TextField source="fridge.serial" label={translate("general.serial")} />

          <ReferenceField
            label={translate("reference.complexe")}
            source="fridge.complexeId"
            reference="complexe"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <span>{translate("general.message")}</span>
          <MsgPanelWithRecord source="message" />
          <NumberField source="weightId" label={translate("general.weight")} />
          <NumberField source="weightErrorId" label={translate("general.weightErrorId")} />
          <SelectField
            source="messageId"
            label={translate("general.alert")}
            choices={fridgeEvents}
            translateChoice={true}
            optionText="alert"
            optionValue="id"
          />

          <BooleanField source="isError" label={translate("general.isError")}></BooleanField>
          <TextField source="member.fullName" label={translate("general.fullName")} />
          <BooleanInput source="viewed" label={translate("general.viewed")}></BooleanInput>
          <BooleanInput source="completed" label={translate("general.completed")}></BooleanInput>
          <TextInput source="comment" label={translate("general.comment")} />

          <DateField
            label={translate("general.createdAt")}
            showTime={true}
            options={{
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }}
            source="messageDate"
            locales={locales}
          />
        </SimpleForm>
      )}
    </Edit>
  );
};

const FridgeEventShow = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <Show {...props} title={<FridgeTitle />} hasEdit={permissions === "admin"}>
      <SimpleShowLayout>
        <TextField source="fridge.serial" label={translate("general.serial")} />

        <ReferenceField
          label={translate("reference.complexe")}
          source="fridge.complexeId"
          reference="complexe"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <span>{translate("general.message")}</span>

        <MsgPanelWithRecord source="message"></MsgPanelWithRecord>
        <TextField source="message" label={translate("general.message")} />
        <NumberField source="weightId" label={translate("general.weight")} />
        <SelectField
          source="messageId"
          label={translate("general.alert")}
          choices={fridgeEvents}
          translateChoice={true}
          optionText="alert"
          optionValue="id"
        />
        <BooleanField source="isError" label={translate("general.isError")}></BooleanField>
        <TextField source="member.fullName" label={translate("general.fullName")} />
        <BooleanField source="viewed" label={translate("general.viewed")}></BooleanField>
        <BooleanField source="completed" label={translate("general.completed")}></BooleanField>
        <TextField source="comment" label={translate("general.comment")} />

        <DateField
          label={translate("general.messageDate")}
          showTime={true}
          options={{
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }}
          source="messageDate"
          locales={locales}
        />
      </SimpleShowLayout>
    </Show>
  );
};

const FridgeEventTopToolbar = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const transalte = useTranslate();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {filters && (
        <Button
          color="primary"
          size="small"
          startIcon={<Close />}
          onClick={() => rest.setFilters({})}
        >
          {transalte("general.clearFilter")}
        </Button>
      )}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={()=>downloadExcel(Object.values(rest.data),rest.defaultTitle)}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const FridgeFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput source="fridge.serial" label={translate("general.serial")} alwaysOn />
      <TextInput source="messageId||$eq" label={translate("general.alert")} alwaysOn />
      <DateTimeInput
        alwaysOn
        source="messageDate||$gte"
        label={`resources.order.fields.createdAt_gte`}
        locales={locales}
      />
      <DateTimeInput
        alwaysOn
        source="messageDate||$lte"
        label={`resources.order.fields.createdAt_lte`}
        locales={locales}
      />
      <BooleanInput
        source="isError"
        parse={(val) => {
          if (val === true) return 1;
          if (val === false) return 0;
          return val;
        }}
        label={translate("general.isError")}
        alwaysOn
      />
    </Filter>
  );
};

const CustomResetViewsButton = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "fridge-event",
    selectedIds,
    { viewed: true },
    {
      onSuccess: () => {
        refresh();
        notify("Posts updated");
        unselectAll("fridge-event");
      },
      onFailure: () => notify("Error: posts not updated", "warning"),
    }
  );

  return (
    <Button color="primary" size="small" disabled={loading} onClick={updateMany}>
      <CheckBox />
      viewed
    </Button>
  );
};

const CustomResetCompletedButton = ({ selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [updateMany, { loading }] = useUpdateMany(
    "fridge-event",
    selectedIds,
    { completed: true },
    {
      onSuccess: () => {
        refresh();
        notify("updated!");
        unselectAll("fridge-event");
      },
      onFailure: () => notify("Error:  not updated", "warning"),
    }
  );

  return (
    <Button color="primary" size="small" disabled={loading} onClick={updateMany}>
      <CheckCircle />
      COMPLETED
    </Button>
  );
};

const FridgeBulkActions = (props) => {
  return (
    <Fragment>
      <CustomResetCompletedButton {...props} />
      <CustomResetViewsButton {...props} />
    </Fragment>
  );
};

const FridgeEventPagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
);

const FridgeEventList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
        filter={props.filter}
      filters={<FridgeFilter />}
      actions={<FridgeEventTopToolbar />}
      sort={{ field: "messageDate", order: "DESC" }}
      bulkActionButtons={<FridgeBulkActions />}
      perPage={100}
      pagination={
        <FridgeEventPagination rowsPerPageOptions={[50, 100, 200, 500, 1000]} {...props} />
      }
    >
      <ErrorDataGrid/>
    </List>
  );
};

export default {
  edit: FridgeEventEdit,
  list: FridgeEventList,
  show: FridgeEventShow,
  icon: Warning,
};
