import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {
	Create,
	NumberInput,
	required,
	SimpleForm,
	TextInput,
	useTranslate,
} from 'react-admin'


const ProfileCreate = props => {
	const translate = useTranslate ()
	const useStyles = makeStyles (() => ({
		root: {

			flexWrap: 'wrap',
			flip: false
		},
		columnItem: {

			maxWidth: '90vw',


			'& > ul': {
				display: 'inline-flex',
				flexWrap: 'wrap',
				maxWidth: '90vw',

				'& > li': {
					border: 'none',
					flexDirection: 'column',
					margin: '1rem'
				}
			}
		}
	}))

	const classes = useStyles ()

	return <Create {...props}>
		<SimpleForm redirect='edit' className={classes.root} onSubmit={''}>

			<TextInput
				source='name'
				validate={required ()}
				autoComplete='off'
				label={translate ('resources.profile.profileName')}

			/>


			<TextInput

				label={translate ('resources.profile.desiredApk')}
				source='desiredApk'
				validate={required ()}
				autoComplete='off'
			/>
			<TextInput

				label={translate ('resources.profile.desiredFirmware')}
				source='desiredFirmware'
				validate={required ()}
				autoComplete='off'
			/>
			<NumberInput source={'maxPurchasingAmount'} defaultValue={300} 	validate={required ()}
			             label={translate ('resources.profile.purchaseLimit')}/>
		</SimpleForm>
	</Create>

}

export default ProfileCreate
