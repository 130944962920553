import React, { Component } from "react";
import { addField } from "ra-core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import FileList from "./FileList";
import { LinearProgress, useTranslate } from "react-admin";
import AddFileBtn from "./AddFileBtn";
import "react-image-lightbox/style.css";
import { isArray } from "lodash";

const { redColor } = require("../constants");

const NoImage = () => {
  const translate = useTranslate();
  return <span style={{ color: redColor }}>{translate("error.product.noImage")}</span>;
};

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.uploadInput = React.createRef();

    this.state = {
      fileList: [],
      btnLabel: "",
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({
      fileList: this.getFilesFromProps(),
    });
  }

  getFilesFromProps() {
    const { input, record } = this.props;

    if (input) {
      return input.value || [];
    }

    if (record) {
      return [record[this.props.source]] || [];
    }

    return [];
  }

  deleteFile() {
    this.props.input.onChange([]);
    this.setState({ fileList: [] });
  }

  getFileList() {
    return this.state.fileList;
  }

  onFinishFileUpload(result) {
    const newFile = result.fileKey;
    let newFileList;

    if (this.props.multipleFiles) {
      newFileList = [...this.getFileList(), newFile];
    } else {
      newFileList = [newFile];
    }

    this.setState({ fileList: newFileList });
    this.props.input.onChange(newFileList);
  }

  onFileProgress(percentage) {
    if (percentage === 0) {
      return this.setState({ loading: true });
    }
    if (percentage === 100) {
      // const form = useForm()
      return this.setState({ loading: false });
    }
  }

  render() {
    const { classes, disabled, uploadOptions, apiRoot, fileCoverImg } = this.props;
    const { fileList, loading } = this.state;
    const files = !isArray(fileList) ? [fileList] : fileList;
    const s3InputOptions = {
      signingUrlMethod: "GET",
      accept: "*/*",
      onFinish: this.onFinishFileUpload.bind(this),
      onProgress: this.onFileProgress.bind(this),
      uploadRequestHeaders: { "x-amz-acl": "private" },
      signingUrlWithCredentials: false,
      signingUrlQueryParams: { uploadType: "avatar" },
      autoUpload: true,
      className: classes.fileInput,
      id: "filesInput",
      ref: this.uploadInput,
      ...uploadOptions,
    };
    return (
      <div className={classes.wrapper}>
        {!disabled && (files.length === 0 || !files[0]) && (
          <AddFileBtn label="Add File" uploadOptions={s3InputOptions} classes={classes} />
        )}
        {files.length > 0 && files[0] ? (
          <FileList
            apiRoot={apiRoot}
            fileCoverImg={fileCoverImg}
            files={files}
            deleteFile={this.deleteFile.bind(this)}
            classes={classes}
            disabled={disabled}
          />
        ) : (
          <NoImage />
        )}
        {loading && <LinearProgress />}
      </div>
    );
  }
}

const StyledFileUploader = withStyles(styles)(FileUploader);

export const S3FileInput = addField(StyledFileUploader);
export const S3FileField = (props) => <StyledFileUploader {...props} disabled />;
