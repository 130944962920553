import React, { forwardRef } from "react";
import {
  AppBar,
  getResources,
  Layout,
  MenuItemLink,
  useLocale,
  usePermissions,
  UserMenu,
  useSetLocale,
  useTranslate,
} from "react-admin";
import { ListItemIcon, makeStyles, MenuItem, useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import Typography from "@material-ui/core/Typography";
import Language from "@material-ui/icons/Language";
import { BarChart, Settings } from "@material-ui/icons";
import logo from "../assets/images/mini-yellow.png";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.text.secondary,
  },
  icon: {
    minWidth: theme.spacing(5),
  },
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  logo: {
    width: "10rem",
  },
}));

const SwitchLanguage = forwardRef((props, ref) => {
  const locale = useLocale();
  const setLocale = useSetLocale();
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <MenuItem
      ref={ref}
      className={classes.menuItem}
      onClick={() => {
        const newLocale = locale === "en" ? "he" : "en";
        localStorage.removeItem("admin-language");
        localStorage.setItem("admin-language", newLocale);
        setLocale(newLocale);
        props.onClick();
        window.location.reload();
      }}
    >
      <ListItemIcon className={classes.icon}>
        <Language />
      </ListItemIcon>
      {translate("general.switchLanguage")}
    </MenuItem>
  );
});

const CustomUserMenu = (props) => (
  <UserMenu {...props}>
    <SwitchLanguage />
  </UserMenu>
);

const CustomMenu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const translate = useTranslate();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const { permissions } = usePermissions();
  return (
    <React.Fragment>
      {resources.map(
        (resource) =>
          resource.icon && (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={
                (resource.options && resource.options.label) ||
                translate(`resources.${resource.name}.name`, { smart_count: 2 })
              }
              leftIcon={<resource.icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
            />
          )
      )}
      {permissions === "admin" && (
        <MenuItemLink
          to="http://analytics.hamezave.com/"
          ref={() => {}}
          primaryText={translate(`general.reports`)}
          leftIcon={<BarChart />}
          onClick={(e) => {
            e.preventDefault();
            window.open("http://analytics.hamezave.com/");
          }}
          sidebarIsOpen={open}
        />
      )}
      {permissions === "admin" && (
        <MenuItemLink
          to="/settings"
          ref={() => {}}
          primaryText={translate(`reference.settings`)}
          leftIcon={<Settings />}
          sidebarIsOpen={open}
        />
      )}

      {isXSmall && logout}
    </React.Fragment>
  );
};

const CustomAppBar = (props) => {
  const classes = useStyles();
  const version = localStorage.getItem('version')
  return (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
      <Typography variant="h6" color="inherit" className={classes.title} id="react-admin-title" />
      <img alt={"mainlogo"} src={logo} className={classes.logo} />
      <span className={classes.spacer} />
      <span >{version}</span>
    </AppBar>
  );
};

export default (props) => (
  <Layout {...props} appBar={CustomAppBar} menu={CustomMenu} /> //
);
