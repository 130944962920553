import React, { Fragment, useEffect, useState } from "react";
import { url } from "../config/connection";
import httpClient from "../DataProvider/httpClient";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import {
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useTranslate
} from "react-admin";
import { useForm } from "react-final-form";
import { Settings } from "@material-ui/icons";
import { omit } from "lodash";
import { debounce } from "../constants";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  changeProductButton: {

  },
}));

const ChangeCalibrate = (props) => {
  const [saving, setSaving] = useState(false);
  const form = useForm();
  const translate = useTranslate();
  const closeFunction = props.close;
  const saveFunction = props.save;
  const { location } = props;

  const handleSave = function handleSave() {
    setSaving(true);
  };

  useEffect(() => {
    if (saving) {
      const state = form.getState();
      if (!state.hasValidationErrors) {
        const values = state.values;
        const requestUrl = `${url}/location/changeCalibrate`;
        const options = {
          method: "POST",
          body: JSON.stringify({ ...values, locationId: location.id ,fridgeId:props.fridgeId}),
        };
        httpClient(requestUrl, options)
          .then((response) => {
            debugger
            setSaving(false);
            if (saveFunction) saveFunction(response.json);
          })
          .catch((error) => {
            setSaving(false);
            console.log(error);
          });
      } else {
        setSaving(false);
      }
    }
  }, [saving]);

  return (
    <Toolbar {...props}>
      {!saving ? (
        <SaveButton
          label={translate("general.save")}
          handleSubmitWithRedirect={handleSave}
          onSave={handleSave}
          redirect={false}
          submitOnEnter={true}
        />
      ) : (
        <LinearProgress style={{ width: "5rem" }} />
      )}
      <Button onClick={closeFunction}>{translate("general.cancel")}</Button>
    </Toolbar>
  );
};

export default function ChangeCalibrateForFridgeButton(props) {
  const [open, setOpen] = useState(false);
  const [location] = useState(props.location);
  const [record, setRecord] = useState(props.location);
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const openDialog = function openDialog() {
    setOpen(true);
  };

  useEffect(() => {
    setRecord(omit(props.location, "productId"));
  }, [props.location]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (values) => {
    handleClose();
    if (props.onClose && values) {
      props.onClose(values);
    }
  };
  const validateFloat=(value)=>{
    if (value){
      let a=Number.parseFloat(value).toString()
      try {
        let b=a.split('.')
          if (b[0].length>7){
            return translate('ra.validation.float')
          }
          if (b[1].length <= 7&& b[0].length<=7) {
            return undefined
          }
          else {
            return translate('ra.validation.float')
          }

      }catch (e) {

      }

    }
  }
  const calibrateValidation= [required(),validateFloat]
  return (
    <Fragment>
      {!props.disabled ? (
        <Tooltip
          title={translate("title.calibrateUpdate")}
          aria-label={translate("title.calibrateUpdate")}
        >
          <Button
            disabled={props.disabled}
            className={`${classes.changeProductButton}`}
            color="primary"
            variant='outlined'
            size="small"
            onClick={() => {
              openDialog();
            }}
          >
            {translate('general.calibrate')}: {record?.calibrate.toFixed(2)}
          </Button>
        </Tooltip>
      ) : (
        <IconButton
          disabled={props.disabled}
          className={`${classes.changeProductButton}`}
          color="primary"
          size="small"
          onClick={() => {
            openDialog();
          }}
        >
          {" "}
          <Settings />
        </IconButton>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        fullWidth
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {translate("title.calibrateUpdate", {
            product: location.product.name,
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <SimpleForm
              toolbar={
                <ChangeCalibrate
                  location={location}
                  close={handleClose}
                  save={handleSave}
                />
              }
              record={record}
             onSubmit={''}>
              <div>{record.product.name}</div>
              <NumberInput
                fullWidth
                label={translate("general.calibrate")}
                source="calibrate"
                debounce={debounce}
                validate={calibrateValidation}
                onBlur={false}
                onFocus={false}
                formatOnBlur={false}


              />
            </SimpleForm>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
