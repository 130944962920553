import React, { Fragment, useEffect, useState } from "react";
import { url } from "../config/connection";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Error,
  Filter,
  FunctionField,
  List,
  Loading,
  NumberField,
  ReferenceField,
  ReferenceInput,
  TextField,
  useTranslate,
} from "react-admin";
import CreateTransactionButton from "./CreateTransactionButton";
import { makeStyles } from "@material-ui/core/styles";
import httpClient from "../DataProvider/httpClient";
import keyBy from "lodash/keyBy";
import {debounce} from '../constants';
const { locales, redColor, orangeColor, yellowColor } = require("../constants");

const useStyles = makeStyles({
  priceHeader: { fontWeight: "bold" },
  redColor: {
    backgroundColor: redColor,
    color: "white",
  },
  yellowColor: {
    backgroundColor: yellowColor,
  },
  orangeColor: {
    backgroundColor: orangeColor,
  },
  blackColor: {
    color: "black",
  },
  redBackColor: {
    backgroundColor: redColor,
    color: "white",
    borderRadius: "1rem",
    padding: "1px 5px",
  },
  yellowBackColor: {
    backgroundColor: yellowColor,
    borderRadius: "1rem",
    padding: "1px 5px",
  },
  orangeBackColor: {
    backgroundColor: orangeColor,
    borderRadius: "1rem",
    padding: "1px 5px",
  },
});

// const RowAndColumnSelect = props => {
//   const form = useForm()
//   const translate = useTranslate()
//   const dataProvider = useDataProvider()
//   const [locations, setLocations] = useState([])
//   const [isFridge, setIsFridge] = useState(false)
//   const [loading, setLoading] = useState(true)
//   const [error, setError] = useState()
//
//   const setProduct = function setProduct (key) {
//     dataProvider
//       .getOne('location', { id: key })
//       .then(({ data }) => {
//         form.change('productId', data.productId)
//       })
//       .catch(error => {})
//   }
//
//   const checkIfFridge = function checkIfFridge (warehouseId) {
//     const requestUrl = `${url}/fridge/isFridge/${warehouseId}`
//     const options = { method: 'GET' }
//     httpClient(requestUrl, options)
//       .then(response => {
//         const { isFridge, locations } = response.json
//         setLocations(locations)
//         setIsFridge(isFridge)
//         form.change('locationId', null)
//         setLoading(false)
//       })
//       .catch(error => {
//         form.change('locationId', null)
//         setLocations([])
//         setIsFridge(false)
//         setError(error)
//         setLoading(false)
//       })
//   }
//
//   return (
//     <div>
//       <ReferenceInput
//         fullWidth
//         label={translate('reference.warehouse')}
//         source='warehouseId'
//         reference='warehouse'
//         onChange={e => {
//           checkIfFridge(e.target.value)
//         }}
//       >
//         <SelectInput optionText='name' />
//       </ReferenceInput>
//
//       {isFridge &&
//         (loading ? (
//           <LinearProgress />
//         ) : error ? (
//           <Error error={error} />
//         ) : (
//           <SelectInput
//             fullWidth
//             validate={[required()]}
//             label={translate('general.rowandcolumn')}
//             choices={locations}
//             source='locationId'
//             onChange={e => {
//               setProduct(e.target.value)
//             }}
//             optionValue='id'
//             optionText={record => {
//               return `row ${record.row}, column ${record.column}, Hardware Id ${record.hardwareId}`
//             }}
//           />
//         ))}
//     </div>
//   )
// }

// const InventoryCreate = props => {
//   const translate = useTranslate()
//   return (
//     <Create {...props}>
//       <SimpleForm redirect='show'>
//         <RowAndColumnSelect />
//         <ReferenceInput
//           fullWidth
//           label={translate('reference.product')}
//           source='productId'
//           reference='product'
//           filter={{ 'active||$eq': true }}
//           filterToQuery={searchText => {
//             if (searchText) return { name: searchText }
//           }}
//         >
//           <AutocompleteInput optionText='name' />
//         </ReferenceInput>
//         <NumberInput fullWidth source='quantity' />
//         <DateInput fullWidth source='expiration_time' />
//       </SimpleForm>
//     </Create>
//   )
// }

// const InventoryTitle = ({ record }) => <span>{record.name}</span>
// const InventoryEditToolbar = props => (
//     <Toolbar {...props} >
//       <SaveButton/>
//     </Toolbar>
// );
// const InventoryEdit = props => {
//   const translate = useTranslate()
//   return (
//     <Edit {...props} title={<InventoryTitle />}>
//       <SimpleForm toolbar={<InventoryEditToolbar/>} redirect='list'>
//         <RowAndColumnSelect />
//         <ReferenceInput
//           label={translate('reference.product')}
//           source='productId'
//           reference='product'
//           filter={{ 'active||$eq': true }}
//           filterToQuery={searchText => {
//             if (searchText) return { name: searchText }
//           }}
//         >
//           <AutocompleteInput optionText='name' />
//         </ReferenceInput>
//         <NumberInput source='quantity' />
//         <DateInput source='expiration_time' />
//       </SimpleForm>
//     </Edit>
//   )
// }

const ExpirationDateField = (props) => {
  const inventoryRecord = props.record;
  const { source } = props;
  const expirationClass = function expirationClass(productRecord) {
    // const { expiration_time } = inventoryRecord
    const expirationDate = new Date(inventoryRecord[source]);
    const { expireAlertDays } = productRecord;
    const expireAlertDaysInMilliseconds = expireAlertDays * 24 * 60 * 60 * 1000;
    const alertDate = new Date(
      expirationDate.getTime() - expireAlertDaysInMilliseconds
    );
    const today = new Date();
    if (today.getTime() > expirationDate.getTime()) return classes.redBackColor;
    if (today.getTime() > alertDate.getTime()) return classes.orangeBackColor;
    return null;
  };
  const classes = useStyles();
  return (
    <ReferenceField
      sortBy={source}
      record={inventoryRecord}
      source="productId"
      reference="product"
      link=""
      basePath={props.basePath}
    >
      <FunctionField
        source={source}
        render={(record) => {
          return (
            <DateField
              source={source}
              record={inventoryRecord}
              className={`${expirationClass(record)}`}
              locales={locales}
            />
          );
        }}
      />
    </ReferenceField>
  );
};

// const InventoryShow = props => {
//   const translate = useTranslate()
//   return (
//     <Show {...props} title={<InventoryTitle />}>
//       <TabbedShowLayout>
//         <Tab label={translate('general.summary')}>
//           <TextField source='quantity' />
//           <Labeled label={translate('general.expiration_time')}>
//             <ExpirationDateField {...props} />
//           </Labeled>
//         </Tab>
//         <Tab label={translate('reference.products')} path='products'>
//           <ReferenceField
//             label={translate('reference.product')}
//             source='productId'
//             reference='product'
//             link='show'
//           >
//             <TextField source='name' />
//           </ReferenceField>
//         </Tab>
//         <Tab label={translate('reference.warehouse')} path='warehouses'>
//           <ReferenceField
//             label={translate('reference.warehouse')}
//             source='warehouseId'
//             reference='warehouse'
//             link='show'
//           >
//             <TextField source='name' />
//           </ReferenceField>
//           <ReferenceField
//             label={translate('reference.location')}
//             source='locationId'
//             reference='location'
//             link=''
//           >
//             <FunctionField
//               render={record => {
//                 return (
//                   <span>
//                     row {record.row}
//                     <br />
//                     column {record.column}
//                   </span>
//                 )
//               }}
//             />
//           </ReferenceField>
//         </Tab>
//       </TabbedShowLayout>
//     </Show>
//   )
// }

const InventoryFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      {/* <ReferenceInput
       label={translate('reference.warehouse')}
       source='warehouseId'
       reference='warehouse'
       filterToQuery={searchText => {
       if (searchText) return { name: searchText }
       }}
       alwaysOn
       >
       <AutocompleteInput optionText='name' />
       </ReferenceInput> */}
      <ReferenceInput
        label={translate("reference.product")}
        source="productId"
        reference="product"
        filterToQuery={(searchText) => {
          if (searchText) return { name: searchText };
        }}
        alwaysOn
      >
        <AutocompleteInput optionText="name" debounce={debounce} />
      </ReferenceInput>
    </Filter>
  );
};

const InventoryBulkActions = (props) => {
  return (
    <Fragment>
      <CreateTransactionButton {...props} />
    </Fragment>
  );
};
//
// const InventoryList = props => {
//   const translate = useTranslate()
//   console.log(props,4000000000000000)
//   return (
//     <List
//       filters={<InventoryFilter />}
//       bulkActionButtons={<InventoryBulkActions />}
//       {...props}
//     >
//       <Datagrid rowClick='show'>
//         <ReferenceField
//           allowEmpty={true}
//           label={translate('reference.warehouse')}
//           source='warehouseId'
//           reference='warehouse'
//           link='show'
//         >
//           <TextField source='name' />
//         </ReferenceField>
//         <ReferenceField
//           allowEmpty={true}
//           label={translate('reference.product')}
//           source='productId'
//           reference='product'
//           link='show'
//         >
//           <TextField source='name' />
//         </ReferenceField>
//         <NumberField source='quantity' />
//         <ExpirationDateField record={props.record} />
//         <ReferenceField
//           allowEmpty={true}
//           label={translate('reference.location')}
//           source='locationId'
//           reference='location'
//           link=''
//         >
//           <FunctionField
//             render={record => {
//               return (
//                 <span>
//                   row {record.row}, column {record.column}
//                 </span>
//               )
//             }}
//           />
//         </ReferenceField>
//       </Datagrid>
//     </List>
//   )
// }

const EmbeddedInventoryList = (props) => {
  const {
    match: {
      params: { warehouseId },
    },
    record: { isFridge },
  } = props;
  console.log (props)
  const translate = useTranslate();
  return (
    <Fragment>
      <List
        title=" "
        filters={<InventoryFilter />}
        bulkActionButtons={<InventoryBulkActions warehouseId={warehouseId} />}
        hasCreate={false}
        hasEdit={true}
        hasList={true}
        hasShow={true}
        resource={`inventory`}
        basePath={`/inventory`}
        filter={{ "warehouseId||$eq": warehouseId }}
      >
        <Datagrid optimized rowClick="" resource={"inventory"} basePath={`/inventory`}>
          <ReferenceField
            allowEmpty={true}
            label={translate("reference.product")}
            source="productId"
            reference="product"
            link=""
          >
            <TextField source="name" />
          </ReferenceField>
          <NumberField label={translate("general.quantity")} source="quantity" />
          <ExpirationDateField
            label={translate("general.expiration_time")}
            source="expiration_time"
          />
          {isFridge ? (
            <ReferenceField
              allowEmpty={true}
              label={translate("reference.location")}
              source="locationId"
              reference={`location`}
              link=""
            >
              <FunctionField
                render={(record) => {
                  return record ? (
                    <span>
                    {translate("general.row")} {record?.row},{" "}
                    {translate("general.column")} {record?.column}
                  </span>
                  ) : null;
              }}
            />
              </ReferenceField >
          ) : null}}
        </Datagrid>
      </List>
    </Fragment>
  );
};

const ProductsInventoryDatagrid = (props) => {
  const {
    match: {
      params: { warehouseId },
    },
  } = props;
  const translate = useTranslate();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  useEffect(() => {
    const options = { method: "GET" };
    const requestUrl = `${url}/inventory/byProduct/${warehouseId}`;
    httpClient(requestUrl, options)
      .then((response) => {
        setData(response.json);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [warehouseId]);
  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  return (
    <Fragment>
      <Datagrid
        optimized
        data={keyBy(data, "productId")}
        ids={data.map(({ productId }) => productId)}
        currentSort={{}}
        rowClick=""
        selectedIds={[]}
        basePath={`/inventory`}
        resource={`/inventory`}
      >
        <ReferenceField
          allowEmpty={true}
          label={translate("reference.product")}
          source="productId"
          reference="product"
          link=""
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField sortable={false} label={translate("general.quantity")} source="quantity" />
        <ExpirationDateField
          sortable={false}
          label={translate("general.closestExpDate")}
          source="close_exp_date"
        />
      </Datagrid>
    </Fragment>
  );
};

export default {
  ProductsInventoryDatagrid,
  EmbeddedInventoryList,
  // list: InventoryList,
  // create: InventoryCreate,
  // edit: InventoryEdit,
  // show: InventoryShow
};
