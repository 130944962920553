
export default {
	generation: {
		generation_1: "Generation 1",
		generation_2: "Generation 1 (Parallel Production)",
		generation_3: "Generation 1.1",
		generation_4: "Generation 1.1 Rollback"
	},
	ra: {
		action: {
			add_filter: "Add filter",
			add: "Add",
			back: "Go Back",
			bulk_actions: "1 item selected |||| %{smart_count} items selected",
			cancel: "Cancel",
			clear_input_value: "Clear value",
			clone: "Clone",
			confirm: "Confirm",
			create: "Create",
			edit: "Edit",
			delete: "Delete",
			export: "Export",
			list: "List",
			refresh: "Refresh",
			remove_filter: "Remove this filter",
			remove: "Remove",
			save: "Save",
			search: "Search",
      download:"Download",
			show: "Show",
			sort: "Sort",
			undo: "Undo",
			expand: "Expand",
			close: "Close",
			open_menu: "Open menu",
			close_menu: "Close menu",
			unselect: "Unselect"
		},
		boolean: {
			true: "Yes",
			false: "No",
			null: ""
		},
		page: {
			create: "Create %{name}",
			dashboard: "Dashboard",
			edit: "%{name} #%{id}",
			error: "Something went wrong",
			list: "%{name}",
			loading: "Loading",
			not_found: "Not Found",
			show: "%{name} #%{id}",
			empty: "No %{name} yet.",
			invite: "Do you want to add one?"
		},
		input: {
			file: {
				upload_several: "Drop some files to upload, or click to select one.",
				upload_single: "Drop a file to upload, or click to select it."
			},
			image: {
				upload_several: "Drop some pictures to upload, or click to select one.",
				upload_single: "Drop a picture to upload, or click to select it."
			},
			references: {
				all_missing: "Unable to find references data.",
				many_missing:
					"At least one of the associated references no longer appears to be available.",
				single_missing:
					"Associated reference no longer appears to be available."
			},
			password: {
				toggle_visible: "Hide password",
				toggle_hidden: "Show password"
			}
		},
		message: {
			about: "About",
			are_you_sure: "Are you sure?",
			reCharge: "Are you sure you want to charge orders?",
			orderAmount: "order amount",
			changeStatus: "re you sure you want to change orders status?",
			bulk_delete_content:
				"Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?",
			bulk_delete_title: "Delete %{name} |||| Delete %{smart_count} %{name}",
			delete_content: "Are you sure you want to delete this item?",
			delete_title: "Delete %{name} #%{id}",
			details: "Details",
			error: "A client error occurred and your request couldn't be completed.",
			invalid_form: "The form is not valid. Please check for errors",
			loading: "The page is loading, just a moment please",
			no: "No",
			not_found: "Either you typed a wrong URL, or you followed a bad link.",
			yes: "Yes",
			unsaved_changes:
				"Some of your changes weren't saved. Are you sure you want to ignore them?"
		},
		navigation: {
			skip_nav: "",
			no_results: "No results found",
			no_more_results:
				"The page number %{page} is out of boundaries. Try the previous page.",
			page_out_of_boundaries: "Page number %{page} out of boundaries",
			page_out_from_end: "Cannot go after last page",
			page_out_from_begin: "Cannot go before page 1",
			page_range_info: "%{offsetBegin}-%{offsetEnd} of %{total}",
			page_rows_per_page: "Rows per page:",
			next: "Next",
			prev: "Prev"
		},
		auth: {
			auth_check_error: "Please login to continue",
			user_menu: "Profile",
			username: "Email",
			password: "Password",
			sign_in: "Sign in",
			sign_in_error: "Authentication failed, please retry",
			logout: "Logout"
		},
		notification: {
			empty_shelves:'Empty shelves left:',
			updated: "Element updated |||| %{smart_count} elements updated",
			created: "Element created",
			deleted: "Element deleted |||| %{smart_count} elements deleted",
			bad_item: "Incorrect element",
			item_doesnt_exist: "Element does not exist",
			http_error: "Server communication error",
			data_provider_error: "dataProvider error. Check the console for details.",
			i18n_error: "Cannot load the translations for the specified language",
			canceled: "Action cancelled",
			logged_out: "Your session has ended, please reconnect."
		},
		validation: {
			required: "Required",
			minLength: "Must be %{min} characters at least",
			maxLength: "Must be %{max} characters or less",
			minValue: "Must be at least %{min}",
			maxValue: "Must be %{max} or less",
			number: "Must be a number",
			email: "Must be a valid email",
			oneOf: "Must be one of: %{options}",
			regex: "Must match a specific format (regexp): %{pattern}",
			float: "Number's max length must be 14 digits  - (max digits after and before comma is 7)"
		}
	},
	resources: {
		"fridge-event": {
			name: "Fridge Alerts"
		},
		"upload-inventory": {
			name: "Upload Inventory"
		},
		user: {
			name: "User |||| Users",
			fields: {
				name: "Name",
				email: "Email",
				password: "Password",
				role: "Role"
			}
		},
		member: {
			name: "Member |||| Members",
			fields: {
				fullName: "Full Name",
				phoneNumber: "Phone Number",
				phoneNumber2: "Extra Phone Number",
				type: "Type",
				city: "City",
				street: "Street",
				streetNumber: "Street Number",
				otherId: "Paz Id",
				technician: 'Technician',
				active: "Active"
			}
		},
		card: {
			name: "Card |||| Cards",
			fields: {
				digitalKey: "Digital Key",
				pysicalKey: "Pysical Key",
				active: "Active"
			}
		},
		product: {
			name: "Product |||| Products",
			fields: {
				sku: "SKU",
				barcode: "Barcode",
				name: "Name",
				imageUrl: "Image",
				cost: "Cost",
				StandertDeviatio: "Standert Deviatio",
				expireAlertDays: "Expire Alert Days",
				weight: "Weight",
				price: "Price",
				vatIncluded: "Vat Included",
				active: "Active",
				humanReachability: "Human Reachability limit"
			}
		},
		vendor: {
			name: "Vendor |||| Vendors",
			fields: {
				name: "Name",
				contact: "Contact",
				phoneNumber: "Phone Number"
			}
		},
		warehouse: {
			name: "Warehouse |||| Warehouses",
			fields: {
				name: "Name",
				city: "City",
				street: "Street",
				streetNumber: "Street Number",
				isFridge: "Is Fridge?"
			}
		},
		inventory: {
			name: "Inventory |||| Inventories",
			fields: {
				locationId: "Location Id",
				productId: "Product Id",
				warehouseId: "Warehouse Id",
				quantity: "Quantity",
				expiration_time: "Expiration Time"
			}
		},
		transaction: {
			name: "Transaction |||| Transactions",
			fields: {
				locationId: "Location Id",
				productId: "Product Id",
				warehouseFromId: "Warehouse From Id",
				warehouseToId: "Warehouse To Id",
				quantity: "Quantity",
				expiration_time: "Expiration Time"
			}
		},
		"transaction-type": {
			name: "Transaction Type |||| Transaction Types",
			fields: {
				name: "Name",
				hasFromWarehouse: "Has from warehouse",
				fromAllowFridge: "Allow From Fridge",
				hasToWarehouse: "Has to warehouse",
				toAllowFridge: "Allow To fridge"
			}
		},
		fridge: {
			name: "Fridge |||| Fridges",
			fields: {
        fridgeSettings:{cameraRotation:'camera rotation'},
				algoretailLinked: "Linked to algoretail",
				noNetwork: "No Network for more than an hour",
				network: "Network status",
				lastAlert: "Last Alert",
				serial: "Serial",
				errorCode: "Error Code",
				active: "Active",
				lastPing: "Last Ping",
				firmwareVersion: "Firmware Version",
				hardwareCardVersion: "Hardware Card Version",
				apkVersion: "APK Version",
				whatsappLink: "Whatsapp Link",
				otherId: "Paz Id",
				offlineDurationInHours: 'offline duration in hours',
				'fridgeStructureId': "Fridge structure",
				"fridgeStructureId-helper-text": 'Any change of fridge structure requires planogram update!'
			},
			errorCode: {
				'error1': ' No Network for more than an hour',
				'error2': 'FRIDGE NOT ACTIVE IN BO',
				'error3': 'No USB Connected',
				'error4': 'Door is not closed',
				'error5': 'No Fridge Id',
				'error6': 'No cache space in offline',
				'error7': 'No config file',
				'error8': 'Secret is empty ',
				'error9': 'No products in memory',
				'error10': 'Error page',
				'error11': 'No planogram in memory'
			}
		},
		"fridge-logs": {
			name: "Fridge Logs",
			fields: {
        temperature:"Temperature",
        humidity:"Humidity",
        weightsNotActive:"Weights Not Active"
      },
		},
		"weight-event": {
			name: "Weight Events",
			oneOrTwo: "lease choose only one or two event",
			warnDifferentSerials: "Please choose events with the same serial",
			differentShelves: "Cannot preform export shelves amount is different between both events",
			difference: "Export inventory difference between two events",
			inventory: "Export event inventory ",
			events: {
				startPurchase: "Start Purchase",
				endPurchase: "End Purchase",
				startFilling: "Start Filling",
				endFilling: "End Filling",
				technician: "Technician visit",
				bootUp: "App Boot Up"
			},
			fields: {
				row: "Shelf",
				column: "Column",
				timeStamp: "Date",
				amountOfProducts: "Amount of Products",
				eventType: "Event Type"

			}
		},
		location: {
			name: "Location |||| Locations",
			fields: {
				hardwareId: "Hardware Id",
				row: "Row",
				column: "Column",
				standard_quantity: "Standard Quantity",
				maximum: "Maximum"
			}
		},
		tenant: {
			name: "Franchise |||| Franchisees",
			fields: {
				name: "Name",
				contact: "Contact",
				phoneNumber: "Phone Number",
				area: "Area"
			}
		},
		order: {
			name: "Order |||| Orders",
			fields: {
				total: "Total",
				payTransactionId: "mPay Id",
				id: "Order id",
				uuid: "Tablet order Id",
				status: "Status",
				memberId: "Member Id",
				cardId: "Card Id",
				lines: "Order Lines",
				isOfflineOrder: "Offline order",
				errorCode: "Paz error code",
				errorMessage: "Paz error message",
				tranzilaConfirmationCode: "Tranzila Code",
				merchant: "Merchant",
				identificationMethod: "Identification Method",
				createdAt_lte: "To date",
				createdAt_gte: "From Date",
				manualAction: " Manual Action"
			},
			actions: {
				updateStatusToSuccess: "Update Status To Success",
				reCharge: "Recharge order"
			}
		},
		"order-old": {
			name: "OldOrder |||| OldOrders",
			fields:{
				withOldOrders: "With Old Orders"
			}
		},
		"order-line": {
			name: "Order Lines |||| Orders Lines",
			fields: {
				subtotal: "Sub Total",
				pricePerUnit: "Price Per Unit",
				quantity: "Quantity",
				productId: "Product Id",
				orderId: "Order Id",
				location: "Location"
			}
		},
		"release-channels": {
			name: "Release Channels",
			fields: {
				displayName: "Display name",
				name: "Name",
        default: "Default",
        lastHardwareVersion: "Last Hardware Version",
      },
		},
		complexe: {
			name: "Complexe |||| Complexes",
			fields: {
				name: "Name",
				city: "City",
				street: "Street",
				streetNumber: "Street Number",
				active: "Active",
				otherId: "Paz Id",
				moreInfo: "More info",
				shortName: "Short name",
				zipCode: "Zip code",
				postalCode: "Postal code",
				classification: "Classification"
			}
		},
		promotion: {
			name: "Promotion |||| Promotions",
			fields: {
				validFrom: "Valid From",
				validTo: "Valid To",
				type: "Type",
				active: "Active",
				ended: "Ended"
			}, type: {
				name: "Type",
				general: "General",
				member: "Member",
				complexe: "Complexe"
			}
		},
		settings: {
			name: "Setting |||| Settings",
			fields: {
				key: "key",
				value: "value"
			}
		},

		'fridge-structure': {
			name: 'Fridge Structure',
			fridgeStructureName: "Fridge Structure Name"
		},

		planogram: {
			name: 'Planogram',
			msg: 'This planogram is associated with a profile any update on planogram will effect other fridges associated with profile ',
			planogramName: 'Planogram Name',
			ofFridge: 'Hide Fridge Planograms'
		}, profile: {
			name: 'Profile',
			noFridges: 'No fridges found',
			fridges: 'Associated Fridges',
			profileName: 'Profile Name',
			purchaseLimit: 'Purchase Limit',
			desiredPlanogram: 'Desired Planogram',
			desiredApk: 'Desired Apk',
			desiredFirmware: 'Desired Firmware',
			releaseChannels: 'Release Channels',
			includedStructure: 'Fridge Structures and Planograms',
			select: 'Select Fridges and Planograms'

		}

	},
	reference: {
    planogram: "Planogram",
    fridgeStructure: "Fridge Structure",
    profile: "Profile",
    generation: "Electronic Version",
    insertInventoryFile: "Insert Inventory Excel File According to file",
    fillDate: "Inventory Fill Date",
    card: "Card",
    cards: "Cards",
    user: "User",
    users: "Users",
    member: "Member",
    members: "Members",
    product: "Product",
    products: "Products",
    vendor: "Vendor",
    vendors: "Vendors",
    warehouse: "Warehouse",
    warehouses: "Warehouses",
    location: "Location",
    locations: "Locations",
    "transaction-type": "Transaction Type",
    "transaction-types": "Transaction Types",
    tenant: "Franchise",
    tenants: "Franchisees",
    inventory: "Inventory",
    fridge: "Fridge",
    fridge_settings:'Fridge Settings',
    fridges: "Fridges",
    "fridge-logs": "Fridge Logs",
    "order-line": "Order lines",
    order: "Order",
    orders: "Orders",
    "release-channels": "Release Channels",
    complexe: "Complexe",
    complexes: "complexes",
    promotion: "Promotion",
    promotions: "Promotions",
    settings: "Settings",
	},
	status: {
		success: "Success",
		pending: "Pending",
		declined: "Declined",
		empty_cart: "Empty Cart",
		exceptional: "Exceptional",
    technician: "Technician",
    technicianPassword: "Technician Password",
    temporary:"Temporary",
		reCharged: "Recharged",
		sentToRecharge: "Sent to recharged"
	},
	manualAction: {
		"Modified status": "Modified status",
		success: "Success",
		pending: "Pending",
		declined: "Declined",
		exceptional: "Exceptional",
    recharged: "Recharged",
	},
	general: {
		'moreThan10': 'More than 10 fridges open to watch',
		'version-changes':'Version changes',
		'startDate':'Start Date',
		'endDate':'End Date',
		'version':'Version',
		height: 'height',
		width: 'width',
		depth: 'depth',
		removeProfile: 'Remove from profile',
    fridge_settings:"Fridge Settings",
    getNewHardwareVersions:'Get New Hardware Versions',
    weightOffset:'Weight Offset',
		tareGram: 'Initial weight',
		delta: 'Delta',
		calibrate: 'Calibrate',
		serverTime: "Server saving time",
		purchaseDate: "Purchase Date",
		openToSee: "Open to see All Messages",
		comment: "Comment",
		alert: "Alert",
		weightErrorId: "Weight Error Id",
		alertNum: "Alert No.",
		weightId: "Weight Id",
		isError: "Error",
		memberId: "Member Id",
		fullName: "Full Name",
		currentPlanogram: 'Current Planogram',
		desiredPlanogram: 'Desired Planogram',
		shelfSize: 'Shelf Size',
		changePlanogram: 'Change To Desired Planogram',
		network: 'Network',
		viewed: "Viewed",
		completed: "Completed",
		weight: "Weight",
		error: "Error",
		message: "Message",
		summary: "Summary",
		status: "Status",
		rowandcolumn: "Row And Column",
		warehouseFrom: "Warehouse From",
		warehouseTo: "Warehouse To",
		items: "Items",
		quantity: "Quantity",
		shelfType: 'Shelf Type',
		expiration_time: "Expiration time",
		shelves: "Shelves",
		row: "Shelve",
		column: "Column",
		createNewWarehouse: "Create New Warehouse",
		city: "City",
		street: "Street",
		streetNumber: "Street Number",
		standard_quantity: "Standard Quantity",
		hardwareId: "Hardware Id",
		closestExpDate: "Closest expiration date",
		createTransactionButton: "Create Transactions",
		fromAllowFridge: "Allow From Fridge",
		toAllowFridge: "Allow To Fridge",
		save: "Save",
		cancel: "Cancel",
		total: "Total products:",
		expiryInventory: "Inventory by expiry date",
		totalInventory: "Total Inventories",
		logs: "Logs",
		key: "key",
		value: "value",
		createdAt: "Created At",
		phoneNumber: "Phone Number",
		image: "Image",
		addImage: "Add Image",
		showFridge: "Show Fridges ?",
		locationNumber: "Weight number",
		isPlanogramFull: "Planogram is empty",
		importCards: "Import Cards",
		fridgeStatus: "Fridges Inventory Status",
		maximum: "Maximum",
		createdAfter: "Created After",
		gap: "Gap",
		statusByFridge: "Status By Fridge",
		charge: "Make a charge",
		backToFridge: "Back to fridges",
		changeProduct: "Change Product",
		addQuantity: "Add Inventory",
		removeQuantity: "Remove Inventory",
		editExpDates: "Edit Expiration Dates",
		switchLanguage: "Switch Language",
		reports: "Reports",
		recharge: "Recharging",
		fridgeAssociation: "Complexe Association",
		fridgeStructure: "Fridge Structure",
		detailsCorrect: "Member details correct",
		digitalKey: "Digital Key",
		pysicalKey: "Pysical Key",
		memberType: "Type",
		pricePerUnit: "Unit Price",
		subtotal: "Sub Total",
		price: "Price",
		validFrom: "Valid From",
		validTo: "Valid To",
		active: "Active",
		promotionDates: "Promotion Dates",
		serial: "Serial",
		generateFridgeToken: "Generate fridge token",
		clickHereToGenerateFridgeToken: "Generate new Token",
		lastUse: "Last use",
		role: "Role",
		complexeConvert: "Complexe Convert",
		fromComplexe: "From Complexe",
		toComplexe: "To Complexe",
		classification: "Classification",
		moreInfo: "More info",
		pazComplexe: "Paz complexe",
		pazFridge: "Paz associated fridges",
		associatedFridges: "Associated fridges",
		fridgeCount: "Fridges count",
		zero: "Without fridges",
		updatedAt: "Updated At",
		algoRetailToken: "Algo Retail Token",
		all: "All",
		clearFilter: "Clear Filters",
    amountOfProducts:"Amount of Products Taken/Added (fridge calc)",
    tareWeightCurrent:"Tare Weight Current",
    calcBo:"BO product calculation",
		absoluteWeight: "Absolute Weight",
		amountOfProductsByWeights: "Amount of products by weights",
		amountOfProductsByWeightsNoDrift: " Amount of products by weights no drift "
	},
	promotion: {
		type: {
			name: "Type",
			general: "General",
			member: "Member",
			complexe: "Complexe"
		}
	},
	member: {
		type: {
			paz: "Paz",
			hamezave: "Hamezave"
		}
	},
	merchant: {
		paz: "Paz",
		hamezave: "Hamezave"
	},
	user: {
		role: {
			admin: 'Admin',
			viewer: 'Viewer',
			customerSupport: 'Customer Support',
			driver: 'Driver'
		}
	},
	title: {
		fridgeStructureShow: "Fridge Structure Show %{name}",
		planogramShow: "Planogram Show %{name}",
		transactionCreate: "Transaction Create",
		locationCreate: "Location Create",
		locationShow: "Location Show",
		editProductInLocation: "Expiration Dates %{product}",
		fridgeStatus: "Fridges Inventory Status",
		statusByFridge: "Inventory Status By Fridge",
		changeProductInLocation: "Change Product",
		fridgeAssociation: "Complexe Association",
		complexeConvert: "Complexe Convert",
		calibrateUpdate: "Calibrate Update",
		offsetUpdate: "Offset Update"
	},
	summary: {
		excel: {
			title: "Inventory Excel Upload"
		},
		transaction: {
			title: "Transitions Summary",
			successMsg: "%{count} Transitions created successfully"
		},
		location: {
			create: {
				successMsg: "%{count} Locations created successfully"
			},
			edit: {
				successMsg: "%{count} Locations edited successfully"
			},
			title: "Locations Summary"
		},
		fridgeStructure: {
			create: {
				successMsg: ' Fridge Structure created successfully',
				failMsg: ' Fridge Structure creation failed'
			},
			edit: {
				successMsg: ' Fridge Structure edited successfully',
				failMsg: ' Fridge Structure edit failed'

			}
		},
		fridge: {
			title: 'Fridge created'
		},
		profile: {
			edit: {successMsg: 'Profile Edited successfully'},
			create: {successMsg: 'Profile created successfully'},
			remove: {
				successMsg: 'Fridge successfully removed from profile',
				failMsg: 'Unable to remove fridge from profile'
			}
		},
		planogram: {
			create: {
				successMsg: ' Planogram created successfully'
			},
			edit: {
				successMsg: ' Planogram  edited successfully'
			},
			replace: {
				successMsg: 'Successfully replaced planograms',
				failMsg: ' Failed to replaced planograms'

			}

		}

		,
		card: {
			title: "Card Import Summary",
			successMsg: "%{count} Cards created successfully"
		},
		order: {
			title: "Manual Charging",
			successMsg: "%{count} Manual charging passed successfully"
		},
		promotion: {
			title: "Promotion Summary",
			successMsg: "Promotions created successfully"
		},
		settings: {
			technicianPasswordSetSuccessfully: "Technician password set successfully",
      setSettings: "Settings updated successfully",
      setSettingsFailed: "Settings updated failed",
          updateAlgoRetailToken: "Token updated successfully"
    },
	},
	error: {
		transaction: {
			twoFridges: "Transfer between two fridges is not possible"
		},
		order: {
			orderChangeFailed:
				"Update failed - reason: orders with different statuses",
			orderNotFound: "Orders were not found"
		},
		inventory: {
			twoWarehouses:
				"Create transactions for more than one warehouse at the same time is not possible"
		},
		planogram: {
			minusZero: "Cannot reduce from 0",
			moreThanQuantity:
				"The quantity is different from the quantity available on the shelf",
			sameExpirationTime: "Records with identical dates exist",
			maximumMustBeBiggerThanStandardQuantity:
				"Maximum must be bigger or equal to standard quantity"
		},
		product: {
			noImage: "No Image"
		}
	},
	classification: {
		private: "Private",
		public: "Public",
		none: "None"
	}
};
