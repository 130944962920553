import PlanogramEdit from './PlanogramEdit'
import PlanogramCreate from './PlanogramCreate'
import PlanogramList from './PlanogramList'
import PlanogramShow from './PlanogramShow'
import DashboardIcon from '@material-ui/icons/Dashboard';

export default {
	list: PlanogramList,
	create: PlanogramCreate,
	edit: PlanogramEdit,
	show: PlanogramShow,
	icon: DashboardIcon
}
