module.exports = {
  currency: "ILS",
  currencySymbol: "₪",
  locales: "he-IL",
  redColor: "#d92027",
  orangeColor: "#ff9234",
  yellowColor: "#ffcd3c",
  greenColor: "#1da71d",
  purpleColor: "#581b75",
  greyColor: "#c2c2c2",
  blackColor: "rgba(0,0,0,0.87)",
  blueColor: "#2c4498",
  shelfTypes: [
    { id: 0, name: "Small" },
    { id: 1, name: "Medium" },
    { id: 2, name: "Large" },
  ],
  fridgeEvents: [
    { id: 1, alert: "DAILY TARE STATUS - סטטוס איפוס משקלים יומי" },
    { id: 2, alert: "DAILY TARE VALUES - ערכי איפוס משקלים יומי" },
    {
      id: 3,
      alert:
        "ORDER WEIGHT STAMP ERROR - הודעות שגיאה ממשקלים בזמן קניה (הודעות אדומות)",
    },
    { id: 4, alert: "ORDER ABOVE 8 PRODUCTS -  קניה מעל 8 מוצרים" },
    {
      id: 5,
      alert:
        "PURCHASE FINISH EMPTY CART -  הסתיימה קניה ללא מוצרים (פתחו וסגרו את המקרר בלי לקחת מוצרים)",
    },
    {
      id: 6,
      alert: "DOOR STATUS CHANGED WITHOUT USER, - הדלת נפתחה בלי שיוזר מחובר",
    },
    {
      id: 7,
      alert:
        "TECHNICAL SCREEN - התחברו למסך טנכאי / השאירו דלת פתוחה אחרי מסך טכנאי",
    },
    {
      id: 8,
      alert: "FILL SCREEN - התחברו למסך ממלא / השאירו דלת פתוחה אחרי מסך ממלא",
    },
    {
      id: 9,
      alert: "DOOR STILL OPEN PURCHASE FINISH - השאירו את הדלת פתוח לאחר קניה",
    },
    {
      id: 10,
      alert:
        "GENERAL FRIDGE ERROR COMMUNICATION NOT STABLE- התראה כללית  משקלים לא יציבים",
    },
    { id: 11, alert: "WEIGHTS_STATUS-בדיקת משקלים" },
    { id: 12, alert: "WEIGHTS_STATUS_RED-משקלים חרגו מהסף" },
    { id: 13, alert: "FAILED_TO_LOGIN - בעיית התחברות משתמש" },
    { id: 14, alert: "CUSTOMER_REGRETTED_EMPTY_CART" },
    {
      id: 15,
      alert: "MANUAL_LOGIN, manual login totp scanner - success logged in",
    },
    {
      id: 16,
      alert: "QRCODE_LOGIN, QRcode Login totp scanner - success logged in",
    },
    { id: 17, alert: "USER_FINISH_PURCHASE, user finishes to purchase" },
    { id: 18, alert: "AMOUNT_CHANGE  user total amount of products" },
    { id: 19, alert: "OPEN_LOGIN_ACTIVITY" },
    { id: 20, alert: "CAMERA_DETECTED" },
    { id: 21, alert: "INVALID_FORMAT" },
    { id: 22, alert: "LOGIN_TIMEOUT" },
    { id: 23, alert: "DOOR_NEVER_OPEN" },
    { id: 24, alert: "MANUAL_LOGIN_OPEN" },
    { id: 25, alert: "DOOR_OPEN_GUARANTEED" },
    { id: 26, alert: "BOOT_UP" },
    { id: 27, alert: "MAX_AMOUNT" },
    { id: 28, alert: "TEST_CALIBRATE_FAILED" },
    { id: 29, alert: "TEST_CALIBRATE_SUCCESS" },
    { id: 30, alert: "FRAUD" },
    { id: 31, alert: "TOUCH_DETECTED" },
    { id: 32, alert: "CAMERA_BLACK_SCREEN" },
    { id: 33, alert: "TOKEN_BASED_BOOT" },
    { id: 34, alert: "VALIDATE_CART" },
    { id: 35, alert: "RAM_MEMORY_SIZE" },
    { id: 36, alert: "FRIDGE_ID_FROM_STORAGE" },
    { id: 37, alert: "WEIGHTS_NOT_ACTIVE" },
    { id: 38, alert: "CAMERA_FLIP" },
    { id: 39, alert: "PURCHASE_FROM_CRASH" },
    { id: 40, alert: "MANUAL_LOGIN_ATTEMPTS" },
    { id: 41, alert: "USB_RESTARTING" },
    { id: 42, alert: "QRCODE_LOGIN_TECHNICIAN" },
    { id: 43, alert: "MANUAL_LOGIN_TECHNICIAN" },
    { id: 1000, alert: "High cpu" },
    { id: 1001, alert: "High battery temp" },
    { id: 1002, alert: "Wrong serial assigned to this fridge in scale fusion" },
    { id: 1003, alert: "Wrong serial assigned in scale fusion" },

  ],
  cameraRotation: [
    { id:0, cameraRotation: 0 },
    { id: 1, cameraRotation: 90},
    { id: 2, cameraRotation: 180 },
    { id: 3, cameraRotation:270}],
  errorCodes: [
    { id: 1, errorCode: "NO INTERNET - אין רשת" },
    { id: 2, errorCode: "FRIDGE NOT ACTIVE IN BO - מקרר לא פעיל בBO" },
    { id: 3, errorCode: "NO USB CONNECTED  - אין חיבור USB" },
    { id: 4, errorCode: "DOOR IS NOT CLOSED  -  דלת פתוחה" },
    { id: 5, errorCode: "NO FRIDGE ID -  מספר מקרר לא הוגדר" },
    { id: 6, errorCode: "NO CACHE SPACE IN OFFLINE, -  אין זכרון ב offline" },
    { id: 7, errorCode: "NO CONFIG FILE  - קובץ קונפיגורציה חסר" },
    { id: 8, errorCode: "SECRET IS EMPTY - מקרר ללא טוקן" },
    { id: 9, errorCode: "NO PRODUCTS IN MEMORY - אין מוצרים בזכרון" },
    { id: 10, errorCode: "Error page- דף שגיאה" },
    { id: 11, errorCode: "Planogram is not in memory- פלנורמה לא קיימת בזכרון" },
  ],
  debounce: 1000,
  perPageList:25,
  perPageInputField:1000

};
