import React, {useEffect, useState} from 'react'
import {url} from '../config/connection'
import {Box, Grid} from '@material-ui/core'
import {Error, Loading, useRedirect, useTranslate} from 'react-admin'
import CustomPage from '../components/CustomPage'
import {makeStyles} from '@material-ui/core/styles'
import {cloneDeep, groupBy} from 'lodash'
import httpClient from '../DataProvider/httpClient'

const {redColor, orangeColor, yellowColor} = require ('../constants')

const FridgeStructureShow = props => {
	const {
		match: {
			params: {id}
		}
	} = props
	const useStyles = makeStyles (() => ({
		root: {
			alignItems: 'center',
			flexWrap: 'nowrap !important',
			direction: 'ltr',
			flip: false
		},
		quantityText: {
			fontSize: '1.4rem'
		},
		bold: {
			fontWeight: 'bold'
		},
		headerdiv: {
			position: 'relative',
			width: '100%'
		},
		headerText: {
			width: '85%',
			margin: 'auto',
			fontSize: '1.4rem'
		},
		inlineGrid: {
			display: 'grid'
		},
		box: {
			flexGrow: '1',
			display: 'inline-box',
			padding: '0.2rem',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			minWidth: '15rem',
			minHeight: '20rem',
			margin: ' 1rem',
			border: '2px solid #80808047'
		},
		redColor: {
			color: redColor
		},
		productImg: {
			maxWidth: '15rem',
			maxHeight: '10rem',
			minHeight: '10rem'
		},
		yellowColor: {
			color: yellowColor
		},
		orangeColor: {
			color: orangeColor
		},
		redBackColor: {
			backgroundColor: redColor,
			color: 'white',
			borderRadius: '1rem',
			padding: '1px 5px'
		},
		yellowBackColor: {
			backgroundColor: yellowColor,
			borderRadius: '1rem',
			padding: '1px 5px'
		},
		orangeBackColor: {
			backgroundColor: orangeColor,
			borderRadius: '1rem',
			padding: '1px 5px'
		},
		blackColor: {
			color: 'black'
		},
		editDiv: {
			float: 'right'
		},
		oneProduct: {display: "flex", flexDirection: 'column', justifyContent: 'center'},
		buttons: {
			color: 'white',
			backgroundColor: '#ff3d00',
			'&:hover': {
				background: '#ff3d0080'
			}
		},
		buttonPlus: {
			marginRight: '1.5rem',
			marginLeft: '1rem'
		},
		buttonMinus: {
			marginLeft: '1.5rem',
			marginRight: '1rem'
		}
	}))

	const translate = useTranslate ()
	const classes = useStyles ()
	const redirect = useRedirect ()
	const [locations, setLocations] = useState ([])
	const [fridge] = useState ({id: ' '})
	const [planogram, setPlanogram] = useState ('')
	const [loading, setLoading] = useState (true)
	const [error, setError] = useState ()


	const manipulateLocation = (results) => {
		const groupByRow = groupBy (results, 'row')
		Object.keys (groupByRow).forEach (row => {
			const rowLocations = groupByRow[row]
			const rowIndexInArray = row - 1
			rowLocations.forEach (rowLocation => {
				if (!locations[rowIndexInArray]) {
					locations[rowIndexInArray] = []
				}
				locations[rowIndexInArray][rowLocation.column - 1] = rowLocation
			})
		})
		return cloneDeep (locations)
	}

	useEffect (() => {
		get ()
	},[])
	const get = () => {
		httpClient (`${url}/fridge-structure/${id}`, {method: 'GET'})
			.then (response => {
				const {name} = response.json
				const returnLocation = response.json.shelves
				if (returnLocation.length === 0) {
					redirect (`/location/create/${id}`)
				}
				else {
					// setFridge(fridge)
					setPlanogram (name)
					const newLocations = manipulateLocation (returnLocation)
					setLocations (newLocations)
					// setFridge({id:fridgeId})
				}
				setLoading (false)
			})
			.catch (error => {
				setError (error)
				setLoading (false)
			})
	}
	if (loading) return <Loading/>
	if (error) return <Error error={error}/>
	if (!locations) return null
	return (
		<CustomPage title={translate ('title.fridgeStructureShow', {name: planogram})}>
			<div className={classes.editDiv}>

			</div>
			<Grid container>
				<Grid item xs={12} className={classes.oneProduct}>
					<Grid container justify="center" spacing={2}>
						{planogram ? <h2>
							{fridge?.serial}
							{planogram
							}

						</h2> : ''}
					</Grid>
				</Grid>
			</Grid>
			{locations.map ((rows, idx) => {
				return (
					<Grid key={idx} container className={classes.root} spacing={2}>
						<span style={{fontSize: '1rem'}}>{idx + 1}</span>
						{rows.map ((column, index) => {
							return (
								<Box key={`${idx}-${index}`} className={classes.box}
								     style={{width: column.shelfType.dimensions.width}}>
									<Grid container className={classes.inlineGrid}>
										<Grid item xs={12} className={classes.oneProduct}>
											<h1>{column.shelfType.shelfSize}</h1>

											<span> <b>{translate ('general.height')}</b>: {column.shelfType.dimensions.height} | <b>{translate ('general.width')}</b> : {column.shelfType.dimensions.width} | <b>{translate ('general.depth')}</b> : {column.shelfType.dimensions.depth} </span>
										</Grid>

									</Grid>
								</Box>
							)
						})}
					</Grid>
				)
			})}
		</CustomPage>
	)
}

export default FridgeStructureShow
