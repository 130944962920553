import React from "react";
import { Datagrid, ReferenceField, TextField, usePermissions, useTranslate } from "react-admin";

const TenantDataGrid = ({ children, ...rest }) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  return (
    <Datagrid optimized {...rest}>
      {children}
      {permissions === "admin" ? (
        <ReferenceField
          label={translate("reference.member")}
          source="memberId"
          reference="member"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
      ) : null}
    </Datagrid>
  );
};

export default TenantDataGrid;
