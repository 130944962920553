import React, { useEffect, useState } from "react";
import { url } from "../config/connection";
import {
  Datagrid,
  Error,
  Loading,
  NumberField,
  ReferenceField,
  TextField,
  TopToolbar,
  useRedirect,
  useTranslate,
} from "react-admin";
import CustomPage from "../components/CustomPage";
import ExpirationDateField from "../components/ExpirationDateField";
import { keyBy } from "lodash";
import { Button } from "@material-ui/core";
import httpClient from "../DataProvider/httpClient";

const FridgeStatusTopToolbar = () => {
  return (
    <TopToolbar>
      <StatusByFridgeButton />
    </TopToolbar>
  );
};

const StatusByFridgeButton = () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  return (
    <Button
      component="span"
      color="primary"
      size="small"
      onClick={() => {
        redirect("/fridge/statusByFridge");
      }}
    >
      {translate("general.statusByFridge")}
    </Button>
  );
};

const FridgeStatus = () => {
  const translate = useTranslate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(null);
  const requestUrl = `${url}/fridge/quantityStatus`;
  const options = { method: "GET" };
  useEffect(() => {
    httpClient(requestUrl, options)
      .then((response) => {
        setData(response.json);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);
  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!data) return null;
  return (
    <CustomPage title={translate("title.fridgeStatus")}>
      <FridgeStatusTopToolbar />
      <Datagrid
        optimized
        data={keyBy(data, "productId")}
        ids={data.map(({ productId }) => productId)}
        currentSort={{ field: "productId", order: "ASC" }}
        rowClick=""
        basePath={`/fridge/status`}
        selectedIds={[]}
        resource={`fridge`}
      >
        <ReferenceField
          allowEmpty={true}
          label={translate("reference.product")}
          source="productId"
          reference="product"
          link=""
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <NumberField sortable={false} label={translate("general.quantity")} source="quantity" />
        <NumberField
          sortable={false}
          label={translate("general.standard_quantity")}
          source="standard_quantity"
        />
        <NumberField sortable={false} label={translate("general.gap")} source="gap" />
        <ExpirationDateField
          sortable={false}
          label={translate("general.closestExpDate")}
          source="close_exp_date"
        />
      </Datagrid>
    </CustomPage>
  );
};

export default FridgeStatus;
