import React from "react";
import {Admin, cacheDataProviderProxy, ListGuesser, resolveBrowserLocale, Resource} from "react-admin";
import {Route} from "react-router-dom";
import {createTheme, jssPreset, StylesProvider} from "@material-ui/core/styles";
import authProvider from "./AuthProvider/authProvider";
import {url} from "./config/connection";
import {customDataProvider} from "./DataProvider/CustomDataProvider";
import englishMessages from "./i18n/en";
import hebrewMessages from "./i18n/he";
import polyglotI18nProvider from "ra-i18n-polyglot";
import users from "./Users";
import vendors from "./Vendors";
import promotions from "./Promotions";
import products from "./Products";
import members from "./Members";
import orders from "./Orders";
import oldOrders from "./OldOrders";
import warehouses from "./Warehouses";
import inventories from "./Inventories";
import transactions from "./Transactions";
import releaseChannels from "./ReleaseChannels";
import fridges from "./Fridges";
import Layout from "./Layout";
import FridgesStructure from './FridgeStracture/FridgdStructureIndex'
import Planogram from './Planogram/PlanogramIndex'
import Profile from './Profile/ProfileIndex'
import fridgeEvents from "./FridgeEvent/FridgeEvent";
import complexes from "./Complexes";
import settings from "./Settings";
import LoginPage from "./Login";
import {create} from "jss";
import rtl from "jss-rtl";
import {WeightEventList} from './WeightEvent/WeightEventList';
import {WatchLater} from '@material-ui/icons';
import manifest from './manifest.json'

const language = localStorage.getItem ("admin-language") || resolveBrowserLocale ();

const theme = createTheme ({
	                           palette: {
		                           secondary: {
			                           light: "#fdbc24",
			                           main: "#fdbc24",
			                           dark: "#fdbc24",
			                           contrastText: "#fff"
		                           },
		                           primary: {
			                           light: "#E35C36",
			                           main: "#E35C36",
			                           dark: "#E35C36",
			                           contrastText: "#fff"
		                           }
	                           },
	                           direction: language === "he" ? "rtl" : "ltr",
	                           isRtl: language === "he"
                           });
console.log ("Theme created");

const i18nProvider = polyglotI18nProvider (
	(locale) => (locale === "he" ? hebrewMessages : englishMessages),
	language
);
if (language === "he") {
	document.getElementsByTagName ("body")[0].setAttribute ("dir", "rtl");
}

const dataProvider = cacheDataProviderProxy (customDataProvider (url));

const jss = create ({
	                    plugins: [...jssPreset ().plugins, language === "he" ? rtl () : null]
                    });

const Resources = (permissions) => {
	let resources
	if (permissions === 'driver') {
		resources = [
			<Resource name="fridge" key="fridge" {...fridges} />,
			<Resource name="profile" key="profile"/>,
			<Resource name="planogram" key="planogram" show={Planogram.show}/>,
			<Resource name="warehouse" key="warehouse"/>,
			<Resource name="release-channels" key="release-channels"/>,
			<Resource name="complexe" key="complexe"/>,
			<Resource name="fridge-structure" key="fridge-structure"/>


		]
	}
	else {
		resources = [
			<Resource name="/" key="/"/>,
			<Resource name="fridge/fridge-token" key="fridge/fridge-token"/>,
			<Resource name="fridge-logs" key="fridge-logs"/>,
			<Resource name="network-history" key="network-history"/>,
			<Resource name="version-changes" key="version-changes"/>,
			<Resource name="location" key="location"/>,
			<Resource name="fridge-structure" key="fridge-structure" edit={false} {...FridgesStructure}/>,
			<Resource name="inventory" key="inventory"/>,
			<Resource name="planogram" key="planogram" {...Planogram}/>,
			<Resource name="profile" key="profile" {...Profile}/>,
			<Resource name="mini-profile" key="mini-profile"/>,
			permissions === "admin" ? (
				<Resource name="user" key="user" {...users} />
			) : (
				<Resource name="user" key="user"/>
			),
    <Resource name="member" key="member" edit={permissions==='admin'?members.edit:''} show={members.show} list={members.list} icon={members.icon} />,
			<Resource name="order" key="order" {...orders} />,
			<Resource name="order-old" key="order-old" show={oldOrders.show}/>,
			<Resource name="journey" key="journey" />,
			<Resource name="order-line" key="order-line"/>,
			<Resource name="old-order-line" key="old-order-line"/>,
			<Resource name="product" key="product" {...products} />,
			<Resource name="vendor" key="vendor" {...vendors} />,
			<Resource name="promotion" key="promotion" {...promotions} />,
			<Resource name="promotion-mapping" key="promotion-mapping"/>,
			<Resource name="promotion-item" key="promotion-item"/>,
			permissions !== "customerSupport" ? (
				<Resource name="warehouse" key="warehouse" {...warehouses} />
			) : (
				<Resource name="warehouse" key="warehouse"/>
			),
				<Resource name="fridge-event" key="fridge-event" {...fridgeEvents} />,
			// permissions !== "customerSupport" ? (
			// 	<Resource name="transaction" key="transaction" {...transactions} />
			// ) : (
			// 	<Resource name="transaction" key="transaction"/>
			// ),
			// permissions === "admin" ? (
			// 	<Resource name="transaction-type" key="transaction-type" {...transactionTypes} />
			// ) : (
			// 	<Resource name="transaction-type" key="transaction-type"/>
			// ),
			<Resource name="complexe" key="complexe" {...complexes} />,
			<Resource name="fridge" key="fridge" {...fridges} />,
			<Resource name="fridge/statusByFridge"/>,
			permissions === "admin" ? (
				<Resource name="release-channels" key="release-channels" {...releaseChannels} />
			) : (
				<Resource name="release-channels" key="release-channels"/>
			),
			permissions === "admin" ? (<Resource name="settings" key="settings" list={settings.list}/>) : '',
    <Resource name="weight-event" key="weight-event" icon={WatchLater} list={WeightEventList}/>,
    <Resource name="weight-event-data" key="weight-event-data"/>
		];
	}
	return resources;
};

const App = () => {
	return (
		<StylesProvider jss={jss}>
			<Admin
				layout={Layout}
				theme={theme}
				i18nProvider={i18nProvider}
				dataProvider={dataProvider}
				authProvider={authProvider}
				loginPage={LoginPage}
				customRoutes={[
					<Route
						name="transactionCreate"
						path="/transaction/create/:data"
						component={transactions.create}
					/>,
					<Route
						path="/warehouse/:warehouseId/inventory"
						component={inventories.EmbeddedInventoryList}
					/>,
					<Route path="/fridge/status" component={fridges.FridgeStatus}/>,
					<Route path="/fridge/statusByFridge" component={fridges.StatusByFridge}/>,
					<Route path="/order/recharge/:data" component={orders.recharge}/>
				]}
			>
				{Resources}
			</Admin>
			<div style={{
				position: 'fixed', left: 0, bottom: 0, zIndex: 100,
				padding: 1,
				backgroundColor: '#fafafa',
				textAlign: 'left',
				fontSize:10
			}}><p style={{margin:0,padding:2}}>version {manifest.version} </p></div>
		</StylesProvider>
	);
};
export default App;
