import React from "react";
import {
	ArrayField,
	Datagrid,
	FunctionField,
	NumberField,
	ReferenceField,
	RichTextField,
	Show,
	SingleFieldList,
	Tab,
	TabbedShowLayout,
	TextField,
	useNotify,
	useRedirect,
	useTranslate,
} from "react-admin";
import { Chip} from '@material-ui/core'
import { Delete} from "@material-ui/icons";
import httpClient from "../DataProvider/httpClient";
import {url} from "../config/connection";

export const CustomField = ({record, profileId}) => {
	const notify = useNotify ()
	const redirect = useRedirect ()
	const translate = useTranslate ()
	const removeFromProfile = async (id) => {
		try {
			await httpClient (`${url}/profile/removeFridge/${id}`, {method: 'POST'})
			notify (translate ('summary.profile.remove.successMsg'), 'success')
			redirect ('')
			redirect ('/profile/' + profileId + '/show')
		} catch (e) {
			notify (translate ('summary.profile.remove.failMsg'), 'error')
		}

	}

	if (record.fridges) {
		return <div className={'grid'} >
			{record.fridges.map (f => {
				return <div
				className={'flex-row'}
				>
					<Delete  fontSize={'small'} style={{margin:10,cursor:'pointer'}} onClick={() => removeFromProfile (f?.id, f?.serial)}/>
					<Chip onClick={()=>redirect(`/fridge/${f.id}/show`)} label={f?.serial}/></div>

			})}</div>

	}
}

export const Title = ({ record }) => {
	const translate=useTranslate()
	return <span> {translate ('resources.profile.name')} {record.name}</span>;
	}
const ProfileShow = props => {
	const translate = useTranslate ()
	const redirect = useRedirect ()
	return (<Show {...props} title={<Title/>} >
			<TabbedShowLayout>
				<Tab label={'general.summary'}>
					<RichTextField source="name" label={translate ('resources.profile.profileName')}/>
					<TextField source={"desiredFirmware"} label={translate ('resources.profile.desiredFirmware')}/>
					<TextField source={"desiredApk"} label={translate ('resources.profile.desiredApk')}/>
					<NumberField source={'maxPurchasingAmount'} label={translate ('resources.profile.purchaseLimit')}/>
					<ArrayField fullWidth={true} source={"miniProfiles"} label={''}>
						<Datagrid >
							<ReferenceField
							allowEmpty={true}
							label={translate ('reference.fridgeStructure')}
							source='planogram.fridgeStructureId'
							reference='fridge-structure'
							link='show'
						>
							<TextField source='name' label={translate ('reference.fridgeStructure')}/>
						</ReferenceField>
							<ReferenceField
								allowEmpty={true}
								label={translate ('resources.profile.desiredPlanogram')}
								source='planogram.id'
								reference='planogram'
								link='show'
							>
								<TextField source='name'/>
							</ReferenceField>

							<ArrayField label={translate ('resources.profile.fridges')} source={"fridges"}>
								<SingleFieldList linkType={false}>
									<FunctionField render={record =><Chip style={{margin:5}} onClick={()=>redirect(`/fridge/${record.id}/show`)} label={record.serial}/>}/>
								</SingleFieldList>
							</ArrayField>

						</Datagrid>

					</ArrayField>

				</Tab>
				<Tab path='fridges' label={translate ('resources.profile.fridges')}
				>

					<ArrayField fullWidth={true} source={"miniProfiles"} label={''}>
						<Datagrid >
							<ReferenceField
								allowEmpty={true}
								label={translate ('reference.fridgeStructure')}
								source='planogram.fridgeStructureId'
								reference='fridge-structure'
								link='show'
							>
								<TextField source='name'/>
							</ReferenceField>
							<ReferenceField
								allowEmpty={true}
								label={translate ('resources.profile.desiredPlanogram')}
								source='planogram.id'
								reference='planogram'
								link='show'
							>
								<TextField source='name'/>
							</ReferenceField>
							<ArrayField label={''} fullWidth={true}   source={"fridges"}>
								<FunctionField render={record =>

									<CustomField record={record} profileId={props.id}/>}/>
							</ArrayField>

						</Datagrid>

					</ArrayField>
				</Tab>

			</TabbedShowLayout>
		</Show>
	)
}

export default ProfileShow
