import React from "react";
import { Card, CardContent } from "@material-ui/core";
import { Title } from "react-admin";

const CustomPage = (props) => {
     const style={ height: "100%" }
        if (typeof props?.style === 'string' ){
            style.backgroundColor='#fdbc24'
        }
  return (
    <Card style={{...style}}>
      <Title title={props.title}/>
      <CardContent>{props.children}</CardContent>
    </Card>
  );
};

export default CustomPage;
