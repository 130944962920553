import React, {cloneElement, Fragment, useEffect, useState} from "react";
import {Check, CheckBox, CheckBoxOutlineBlank, Close, CreditCard, Done, ShoppingCart} from '@material-ui/icons';
import {
	BooleanField,
	Confirm,
	Datagrid,
	DateTimeInput,
	ExportButton,
	Filter,
	FunctionField,
	Labeled,
	List,
	Loading,
	NumberField,
	Pagination,
	ReferenceField,
	ReferenceManyField,
	required,
	sanitizeListRestProps,
	SaveButton,
	SelectInput,
	Show,
	SimpleForm,
	Tab,
	TabbedShowLayout,
	TextField,
	TextInput,
	Toolbar,
	TopToolbar,
	useNotify,
	usePermissions,
	useRedirect,
	useRefresh,
	useShowController,
	useTranslate,
	useUnselectAll
} from "react-admin";
//todo: fix module import
import {Button, IconButton, Tooltip} from "@material-ui/core";
import OrderLinesDatagrid from "../OrderLines";
import {makeStyles} from "@material-ui/core/styles";
import RedirectButton from "../components/RedirectButton";
import {dateFormat, differenceBOcalaculationOnWeightEvent, downloadExcel} from "../utils.service";
import httpClient from "../DataProvider/httpClient";
import {url} from "../config/connection";
import {debounce, perPageList, purpleColor} from "../constants";
import CustomPage from "../components/CustomPage";
import fridgeEvent from '../FridgeEvent/FridgeEvent';
import {WeightEventList} from '../WeightEvent/WeightEventList';

const {redColor, orangeColor, currency, greenColor, locales, greyColor, blueColor} = require ("../constants");

const useStyles = makeStyles ({
	                              priceCell: {fontWeight: "bold"},
	                              status: {
		                              color: "white",
		                              borderRadius: "1rem",
		                              padding: "1px 5px"
	                              },
	                              errorPurple: {
		                              boxShadow: "-1px 0px 0 5px #e83ec0"
	                              },
	                              errorYellow: {
		                              boxShadow: "-1px 0px 0 5px #fdbc24"
	                              },
	                              redColor: {
		                              backgroundColor: redColor
	                              },
	                              orangeColor: {
		                              backgroundColor: orangeColor
	                              },
	                              blueColor: {
		                              backgroundColor: blueColor
	                              },
	                              greenColor: {
		                              backgroundColor: greenColor
	                              },
	                              purpleColor: {
		                              backgroundColor: purpleColor
	                              },
	                              blackColor: {
		                              backgroundColor: "#000"
	                              }, grayColor: {
		backgroundColor: greyColor
	}
                              });

const StatusField = ({record = {}}) => {
	const classes = useStyles ();
	const translate = useTranslate ();
	let className = classes.status + " ";
	let boxShadow = "";
	const {status, errorCode, errorMessage} = record;
	const code = errorCode ? translate ("resources.order.fields.errorCode") : " ";
	const str =
		code +
		" " +
		(errorCode || " ") +
		"\n" +
		translate ("resources.order.fields.errorMessage") +
		" " +
		errorMessage;
	switch (status) {
		case "success":
			className += classes.greenColor;
			break;
		case "pending":
			className += classes.orangeColor;
			break;
		case "declined":
			className += classes.redColor;
			if (errorMessage) {
				className = className + " " + classes.errorYellow;
			}
			break;
		case "exceptional":
			className += classes.blackColor;
			if (errorMessage) {
				className += className + " " + classes.errorPurple;
			}
			break
		case "temporary":
			className += classes.grayColor;
			if (errorMessage) {
				className += className + " " + classes.errorPurple;
			}
			break;
		case "technician":
			className += classes.blueColor;
			break;
		case "empty_cart":
			className += classes.purpleColor;
			break;
		default:
	}

	if (errorMessage) {
		return (
			<Tooltip title={str}>
        <span className={className} style={{boxShadow, fontSize: "14px"}}>
          {translate (`status.${status}`)}
        </span>
			</Tooltip>
		);
	}
	else {
		return (
			<span className={className} style={{fontSize: "14px"}}>
        {translate (`status.${status}`)}{" "}
      </span>
		);
	}
};

const OrderTitle = ({record}) => {
	const translate = useTranslate ();
	return (
		<span>
      {translate ("reference.order")} {record.id}
    </span>
	);
};
const OrderEditToolbar = (props) => {
	const notify = useNotify ();
	const redirect = useRedirect ();
	const translate = useTranslate ();

	function updateOrder (record) {
		const options = {method: "PATCH", body: JSON.stringify (record)};
		const requestUrl = `${url}/order/${record.id}`;
		httpClient (requestUrl, options)
			.then (() => {
				notify ("ra.notification.updated", "success");
				redirect ("/order");
			})
			.catch ((e) => {
				notify (translate (e.message), "warning");
			});
	}

	return (
		<Toolbar {...props}>
			<SaveButton undoable={true} onSave={() => updateOrder (props.data)}/>
		</Toolbar>
	);
};

const OldOrdersShow = (props) => {
	const {
		record // record fetched via dataProvider.getOne() based on the id from the location
	} = useShowController (props);
	const translate = useTranslate ();
	return (
		<Show {...props} title={<OrderTitle/>} hasEdit={false}>
			<TabbedShowLayout>
				<Tab label={translate ("general.summary")}>
					<Labeled label={translate ("general.status")}>
						<StatusField source="status"/>
					</Labeled>
					<FunctionField
						label={translate ("general.purchaseDate")}
						render={(record) => {
							const date = dateFormat (record.createdAt);
							const purchaseDate = dateFormat (record.purchaseDate);
							return (
								<Tooltip title={translate ("general.serverTime") + " " + date}>
									<span>{purchaseDate}</span>
								</Tooltip>
							);
						}}
					/>
					<NumberField
						source="total"
						locales={locales}
						options={{style: "currency", currency: currency}}
					/>
					<ReferenceField
						label={translate ("reference.member")}
						source="memberId"
						reference="member"
						link="show"
					>
						<TextField source="fullName"/>
					</ReferenceField>
					<TextField source="id"/>
					<TextField source="payTransactionId"/>
					<TextField source="uuid"/>
					<BooleanField source="isOfflineOrder"/>
					<TextField source="errorCode"/>
					<TextField source="errorMessage"/>
					<TextField source="identificationMethod"/>
				</Tab>
				<Tab label={translate ("reference.order-line")} path="lines">
					<OrderLinesShow/>
				</Tab>
				<Tab label={translate ("resources.fridge-event.name")} path="fridge-event">
					<ReferenceManyField reference="fridge-event" target="fridgeId" filter={{"uuid||$eq": record?.uuid}}
					                    addLabel={false}>
						<fridgeEvent.list
							disableSyncWithLocation
							basePath={`fridge-event`}
							resource={`fridge-event`}

						/>
					</ReferenceManyField>
				</Tab>
				<Tab label={'resources.weight-event.name'} path={"weight-event"}>
					<FunctionField
						addLabel={false}
						render={() => {
							return (
								<ReferenceManyField reference="weight-event" target="fridgeId" label={false}>
									<WeightEventList
										{...props}
										disableSyncWithLocation
										basePath={`weight-event`}
										resource={`weight-event`}
										orderId={record.id}
										fridgeId={record.fridgeId}


									/>
								</ReferenceManyField>)
						}}
					/>


				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

const OrderFilter = (props) => {
	const translate = useTranslate ();
	const fridgeId = props?.fridgeId || null
	return (
		<Filter {...props}>
			{fridgeId ? <TextInput
				source="fridge.serial"
				label={translate ("reference.fridge")}
				debounce={debounce}
			/> : <TextInput
				source="fridge.serial"
				alwaysOn
				label={translate ("reference.fridge")}
				debounce={debounce}
			/>}
			<TextInput
				alwaysOn
				optionText="otherId"
				source="member.otherId"
				label={translate ("reference.member")}
				debounce={1000}
			/>
			<SelectInput
				source="status"
				choices={[
					{
						id: "success",
						name: translate (`status.success`)
					},
					{id: "exceptional", name: translate (`status.exceptional`)},
					{id: "pending", name: translate (`status.pending`)},
					{id: "declined", name: translate (`status.declined`)},
					{id: "technician", name: translate (`status.technician`)},
					{id: "temporary", name: translate (`status.temporary`)},
					{id: "empty_cart", name: translate (`status.empty_cart`)}
				]}
				alwaysOn
				debounce={1000}
			/>
			<DateTimeInput
				alwaysOn
				source="updatedAt||$gte"
				label={`resources.order.fields.createdAt_gte`}
				locales={locales}
			/>
			<DateTimeInput
				alwaysOn
				source="updatedAt||$lte"
				label={`resources.order.fields.createdAt_lte`}
				locales={locales}
			/>
			<TextInput
				label={translate ("resources.order.fields.payTransactionId")}
				source="payTransactionId||$eq"
			/>
			<TextInput label={translate ("resources.order.fields.id")} source="id||$eq"/>
			<TextInput label={translate ("resources.order.fields.uuid")} source="uuid||$eq"/>
		</Filter>
	);
};

const OrderLinesShow = (props) => {
	const [events, setEvents] = useState ([])
	useEffect (() => {
		if (!props.record.events) {
			httpClient (url + `/weight-event?filter[0]=orderId||$eq||${props.record.id}`)
				.then ((r) => {
					setEvents (r.json)
				})
		}
		else {
			setEvents (props.record.events)
		}
	}, [])
	return (
		<ReferenceManyField {...props} reference="order-line" target="orderId" filter={{"OldOrders": 1234}} addLabel={false}>
			<OrderLinesDatagrid events={events}/>
		</ReferenceManyField>
	);
};

const OrderTopToolbar = ({
	                         currentSort,
	                         className,
	                         resource,
	                         filters,
	                         displayedFilters,
	                         exporter, // you can hide ExportButton if exporter = (null || false)
	                         filterValues,
	                         permanentFilter,
	                         hasCreate, // you can hide CreateButton if hasCreate = false
	                         basePath,
	                         selectedIds,
	                         onUnselectItems,
	                         showFilter,
	                         maxResults,
	                         total,
	                         ...rest
                         }) => {
	const translate = useTranslate ();

	return (
		<TopToolbar className={className} {...sanitizeListRestProps (rest)}>
			{filters &&
				cloneElement (filters, {
					resource,
					showFilter,
					displayedFilters,
					filterValues,
					context: "button"
				})}

			{filters && (
				<Tooltip title={translate ("general.clearFilter")}>
					<IconButton color="primary" size="small" onClick={() => rest.setFilters ({})}>
						<Close/>
					</IconButton>
				</Tooltip>
			)}
			{/*{permissions === 'admin' ? <CreateButton basePath={basePath} /> : null}*/}
			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filter={{...filterValues, ...permanentFilter}}
				exporter={() => downloadExcel (Object.values (rest.data), rest.defaultTitle)}
				maxResults={maxResults}
			/>
		</TopToolbar>
	);
};

OrderTopToolbar.defaultProps = {
	selectedIds: []
};

const OrderListPagination = (props) => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;

const OrderListBulkActions = (props) => {
	const unselectAll = useUnselectAll ();
	const translate = useTranslate ();
	const notify = useNotify ();
	const refresh = useRefresh ();
	const [isOpen, setOpen] = useState (false);
	const [functionName, setFunction] = useState ("");
	const [ids, setIds] = useState ([]);
	const [loading] = useState (false);
	useEffect (() => {
		           return () => {
			           unselectAll ("order");
		           };
	           }, []
	);

	const updateStatusToSuccess = async (ids) => {
		const body = JSON.stringify ({ids});
		await httpClient (`${url}/order/updateToSuccess`, {
			method: "PATCH",
			body
		});
	};
	const reCharge = async (ids) => {
		const body = JSON.stringify ({ids});
		return await httpClient (`${url}/order/reCharge`, {
			method: "PATCH",
			body
		});
	};

	function handleConfirm () {
		setOpen (!isOpen)
		if (functionName === "reCharge") {
			reCharge (ids)
				.then ((r) => {
					const msg = JSON.parse (r.body)?.msg;
					if (msg) {
						notify (msg, "warning");
					}
					else {
						notify (translate ("ra.notification.updated"), "success");
					}
					unselectAll ("order");
					refresh ();
				})
				.catch ((e) => {
					notify (translate (e.message), "warning");
					setOpen (!isOpen);
				});
		}
		else if (functionName === "updateStatusToSuccess") {
			updateStatusToSuccess (ids)
				.then (() => {
					unselectAll ("order");
					refresh ();
				})
				.catch ((e) => {
					notify (translate (e.message), "warning");
					setOpen (!isOpen);
				});
		}
	}

	function handleDialogClose () {
		setOpen (!isOpen);
	}

	return (
		<Fragment>
			<Button
				variant={"contained"}
				color={"primary"}
				component="span"
				size="small"
				style={{margin: "2px"}}
				onClick={() => {
					setOpen (true);
					setFunction ("updateStatusToSuccess");
					setIds (props.selectedIds);
				}}
				endIcon={<Done/>}
			>
				{translate ("resources.order.actions.updateStatusToSuccess")}
			</Button>
			<Button
				variant={"contained"}
				color={"primary"}
				component="span"
				size="small"
				style={{margin: "2px"}}
				onClick={() => {
					setOpen (true);
					setFunction ("reCharge");
					setIds (props.selectedIds);
				}}
				endIcon={<CreditCard/>}
			>
				{translate ("resources.order.actions.reCharge")}
			</Button>
			{isOpen ? (
				<Confirm
					isOpen={isOpen}
					loading={loading}
					title={translate ("resources.order.actions." + functionName)}
					content={
						translate ("ra.message.are_you_sure") +
						" " +
						translate ("ra.message.orderAmount") +
						" " +
						ids.length
					}
					onClose={handleDialogClose}
					onConfirm={handleConfirm}
				/>
			) : (
				""
			)}
		</Fragment>
	);
};

const OldOrdersList = ({permissions, ...props}) => {
	const translate = useTranslate ();
	const RowStyle = (record) => {
		const events = record?.events || []
		const rows = record.lines || []
		const start = events.find ((e) => e.eventType === 'START_PURCHASE')?.weightEventData
		const end = events.find ((e) => e.eventType === 'END_PURCHASE')?.weightEventData
		if (rows.length > 0 && events.length > 1) {
			for (const row of rows) {
				row.weightDataStart = start?.find (s => s.locationId === row.locationId) || []
				row.weightDataEnd = end?.find (s => s.locationId === row.locationId) || []
				if (row?.weightDataStart && row?.weightDataEnd) {
					let {differenceFromPreviousEventBO, mark} = differenceBOcalaculationOnWeightEvent (row)
					if (typeof differenceFromPreviousEventBO !== 'number') {
						return null
					}
					if (mark) {
						return {'backgroundColor': 'rgb(242 175 175 / 37%)'}

					}
					if (row.quantity === differenceFromPreviousEventBO) {
						continue
					}
					if (row.quantity !== differenceFromPreviousEventBO) {
						return {'backgroundColor': 'rgb(242 175 175 / 37%)'}
					}
				}
			}

		}
		else {
			return null;
		}
	}
	const fridgeId = props?.fridgeId || null
	let filter = {join: ["member||otherId,phoneNumber", "fridge||serial", 'events', 'events.weightEventData', 'lines', 'lines.product']}
	if (props?.fridgeId) {
		filter['fridgeId||$eq'] = props.fridgeId
	}
	return (
		<List
			{...props}
			bulkActionButtons={permissions === "admin" ? <OrderListBulkActions/> : false}
			actions={<OrderTopToolbar/>}
			filters={<OrderFilter fridgeId={fridgeId}/>}
			perPage={perPageList}
			pagination={<OrderListPagination/>}
			filter={filter}
			sort={{field: "purchaseDate", order: "DESC"}}
		>
			<Datagrid
				optimized
				rowStyle={RowStyle}
				expand={<OrderLinesShow/>}
				isRowSelectable={(record) => ["declined", "exceptional"].includes (record.status) && record.manualAction !== 'planogram'}
				rowClick={"show"}
				key={(record) => record.id}
			>
				<FunctionField
					source={'status'}
					label={translate ("general.status")}
					render={(record) => {
						if (!["success", "technician"].includes (record?.status) && record.manualAction !== 'planogram') {
							return (
								<RedirectButton
									icon={<StatusField record={record}/>}
									url={`/order/${record.id}/edit`}
								/>
							);
						}
						else {
							return <StatusField record={record}/>;
						}
					}}
				/>
				<TextField source="payTransactionId"/>
				<FunctionField
					source={'fridge.serial'}
					label={translate ("reference.fridge")}
					render={(record) => {
						if (record?.fridge?.serial) {
							return (
								<RedirectButton
									url={`/fridge/${record?.fridgeId}/show`}
									label={record?.fridge?.serial}
								/>
							);
						}
						else {
							return " ";
						}
					}}
				/>
				<FunctionField
					sortable={true}
					source={'member.otherId'}
					label={translate ("reference.member")}
					render={(record) => {
						if (record?.fridge?.serial) {
							return (
								<RedirectButton
									url={`/member/${record?.memberId}/show`}
									label={record?.member?.otherId}
								/>
							);
						}
						else {
							return " ";
						}
					}}
				/>
				<NumberField
					source="total"
					locales={locales}
					options={{style: "currency", currency: currency}}
				/>

				<FunctionField
					label={translate ("general.purchaseDate")}
					source={'updatedAt'}
					render={(record) => {
						const date = new Date (record.createdAt).toLocaleString ('en-IL', {
							timeZone: 'Asia/Jerusalem',
							dateStyle: 'short',
							timeStyle: 'short'
						})
						if (record?.purchaseDate) {
							const purchaseDate = new Date (record.purchaseDate).toLocaleString ('en-IL', {
								timeZone: 'UTC',
								dateStyle: 'short',
								timeStyle: 'short'
							})
							if (purchaseDate) {
								return (
									<Tooltip title={translate ("general.serverTime") + " " + date}>
										<span>{purchaseDate}</span>
									</Tooltip>
								);
							}
							else {
								return " ";
							}
						}
					}}
				/>
				<FunctionField
					source={"manualAction"}
					render={(record) => {
						if (record.manualAction) {
							return translate (`manualAction.${record.manualAction}`);
						}
						else {
							return "";
						}
					}}
				/>
				<FunctionField source="isOfflineOrder" render={(record) => {
					if (record?.isOfflineOrder) {
						return <Check/>
					}
					else {
						return "";
					}
				}}/>
			</Datagrid>
		</List>
	);
};

export default {
	list: OldOrdersList,
	show: OldOrdersShow,
	icon: ShoppingCart
};
