import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  email,
  Filter,
  Labeled,
  List,
  PasswordInput,
  required,
  SaveButton,
  SelectInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  useNotify,
  usePermissions,
  useRedirect,
  useTranslate
} from "react-admin";
import {Delete, Person} from "@material-ui/icons";
import GenericTopToolbar from "../components/GenericTopToolbar";
import {get} from "lodash";
import {passwordValidation} from "../utils.service";
import {Button} from '@material-ui/core';
import httpClient from '../DataProvider/httpClient';
import {url} from "../config/connection";


const UserTypeField = ({ source, record = {} }) => {
  const translate = useTranslate();
  const role = get(record, source);
  if (!role) return <span/>;
  return <span>{translate(`user.role.${role}`)}</span>;
};
const GetRolesList = () => {
  const translate = useTranslate();
  return [
    {
      id: "viewer",
      name: translate (`user.role.viewer`),
    },
    {
      id: "customerSupport",
      name: translate (`user.role.customerSupport`),
    },
    {
      id: "admin",
      name: translate (`user.role.admin`),
    }, {
      id: "driver",
      name: translate (`user.role.driver`),
    },
  ];
};
const UserCreate = (props) => {
  const { permissions } = usePermissions();
  const roles = GetRolesList();
  return (
    <Create {...props}>
      {permissions === "admin" ? (
        <SimpleForm redirect="show" onSubmit={''}>
          <TextInput source="name" validate={required()} />
          <TextInput source="email" type="email" validate={[required(), email()]} />
          <PasswordInput
            source="password"
            helperText={
              "Password should be at least 8 notes long and contain at least ine of each note: [A-Z],[a-z],[0-9] "
            }
            validate={passwordValidation}
          />
          <SelectInput validate={required()} source="role" choices={roles} />
        </SimpleForm>
      ) : (
        <div/>
      )}
    </Create>
  );
};

const UserTitle = ({ record }) => <span>{record.name}</span>;
const CustomToolbar = props => {
  const {id}=props.record
  const redirect=useRedirect()
  const notify =useNotify()
  const translate= useTranslate()
  const deleteUser=()=>{
    httpClient(url+'/user/'+id,{method:'delete' })
        .then(()=> {
          notify("ra.notification.updated", "success");
          redirect ('/user')
              }
        ).catch((e)=>notify(translate(e.message), "warning"))
  }
  return <Toolbar
      {...props}
      style={{ display: 'flex', justifyContent: 'space-between'}}
  >
    <SaveButton />
    <Button startIcon={<Delete/>} onClick={()=>deleteUser()}
                color="primary" variant={"outlined"}  size="medium" >
      {translate('ra.action.delete')}</Button>
  </Toolbar>
};
const UserEdit = (props) => {
  const { permissions } = usePermissions();
  const roles = GetRolesList();
  return (
    <Edit {...props} undoable={false}  title={<UserTitle  />}>
      {permissions === "admin" && (
        <SimpleForm redirect="list" isfullwidth={true} toolbar={<CustomToolbar/>} onSubmit={''}>
          <TextInput source="name" validate={required()} />
          <TextInput source="email" type="email" validate={[required(), email()]} />
          <PasswordInput source="password" validate={(v)=>passwordValidation(v,true)} />
          <SelectInput validate={required()} source="role" choices={roles} />
        </SimpleForm>
      )}
    </Edit>
  );
};
const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <TextInput source="email" type="email" alwaysOn />
  </Filter>
);

const UserShow = (props) => {
  const translate = useTranslate();
  const { permissions } = usePermissions();
  return (
    <Show {...props} title={<UserTitle />} hasEdit={permissions === "admin"}>
      <TabbedShowLayout>
        <Tab label={translate("general.summary")}>
          <TextField source="name" />
          <TextField source="email" />
          <Labeled label={translate("general.role")}>
            <UserTypeField source="role" />
          </Labeled>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

// const UserBulkActions = props => {
// 	return (
// 		<Fragment>
// 			<BulkDeleteButton {...props} />
// 		</Fragment>
// 	)
// }

const UserList = (props) => {
  const { permissions } = usePermissions();
  return (
    <List
      {...props}
      actions={<GenericTopToolbar />}
      filters={<UserFilter />}
      hasCreate={permissions === "admin"}
      bulkActionButtons={false}
    >
      <Datagrid optimized rowClick="show">
        <TextField source="name" />
        <TextField source="email" />
        <UserTypeField source="role" />
      </Datagrid>
    </List>
  );
};

export default {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  show: UserShow,
  icon: Person,
};
