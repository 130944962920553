import {fetchUtils, HttpError} from "react-admin";
import decodeJwt from "jwt-decode";
import {staging, url} from "../config/connection";

let isRefreshing = false
;
const httpClient = async (url, options = {}) => {
	let access_token = localStorage.getItem ("access_token");
	if (isRefreshing === false) {
		await waitForRefreshToken ();
	}
	if (!access_token) {
		return Promise.reject (new HttpError ("No access token", 401, {data: {}}));
	}
	if (!options.headers) {
		options.headers = new Headers ({
			                               Accept: "multipart/form-data, application/json"
		                               });
	}
	if (staging){
		options.headers.append('AB-testing',staging)
	}
	const currDate = new Date ().getTime ();
	if (access_token) {
		try {
			const decodeJwtToken = decodeJwt (access_token);
			const currTimeMS = parseInt (currDate.toString())
			const remainTime = decodeJwtToken?.exp * 1000 - currTimeMS
			if (remainTime <= 60000) {

				let returned_refresh_token = await callRefreshToken (access_token);
				if (returned_refresh_token) {
					access_token = returned_refresh_token;
				}

			}
		} catch (e) {
			const json = {...e};
			return Promise.reject (new HttpError (json && json?.message, json?.status, json));
		}
		options.headers.set ("Authorization", `Bearer ${access_token}`);
		return fetchUtils.fetchJson (url, options);
	}
};

function waitForRefreshToken () {
	return new Promise ((resolve, reject) => {
		const inter = setInterval (() => {
			if (isRefreshing === false) {
				clearInterval (inter);
				return resolve ();
			}
		}, 100);
		setTimeout (() => {
			if (isRefreshing) {
				clearInterval (inter);
				reject (new HttpError ("Server Failed", 500, {data: {}}));
			}
		}, 10000);
	});
}

async function callRefreshToken (accessToken) {
	try {
		isRefreshing = true;
		const refreshToken = localStorage.getItem ("refresh_token");
		if (!refreshToken) return new Error ("no Refresh token , please logout and login again");
		const body = JSON.stringify ({accessToken, refreshToken});
		const options = {method: "POST", body};
		options.headers = new Headers ({Accept: "application/json"});
		options.headers.set ("Authorization", `Bearer ${refreshToken}`);
		options.headers.set ("AB-testing", staging);
		const res = await fetchUtils.fetchJson (url + "/user/refresh-token", options);
		const newAccessToken = res.json.accessToken;
		localStorage.setItem ("access_token", newAccessToken);
		return newAccessToken;
	} catch (e) {
		localStorage.removeItem ("access_token");
		localStorage.removeItem ("refresh_token");
		throw new HttpError ("auth error", 401, {data: {}});
	} finally {
		isRefreshing = false;
	}
}

export default httpClient;
