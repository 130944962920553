import React from "react";
import { isImage } from "./utils";
import { IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";

const FileItem = ({
  file,
  apiRoot,
  showBiggerImg,
  classes,
  disabled,
  deleteFile,
  fileCoverImg,
}) => {
  const serverUrl = `${apiRoot}/s3/uploads/${file}`;
  const isImg = isImage(serverUrl);
  const backgroundImg = isImg ? serverUrl : fileCoverImg;

  const handleClickImg = () => {
    if (isImg) {
      showBiggerImg(serverUrl);
    }
  };
  return (
    <>
      <div className={classes.fileLink}>
        <div
    className={classes.fileImg}
    onClick={() => handleClickImg ()}
    style={{backgroundImage: `url(${backgroundImg})`}}
    />
      </div>

      <div className={classes.rightBlock}>
        {!disabled && (
          <IconButton
            className={classes.delete}
            variant="contained"
            color="primary"
            onClick={() => deleteFile(file.name)}
          >
            <Delete />
          </IconButton>
        )}
      </div>
    </>
  );
};

export default FileItem;
