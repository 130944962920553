import React, { useEffect, useState } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  usePermissions,
  useRedirect,
  useTranslate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import CreateToolBarWithCustomSave from "../components/CreateToolBarWithCustomSave";
import httpClient from "../DataProvider/httpClient";
import { url } from "../config/connection";
import { groupBy } from "lodash";


const PlanogramCreate = () => {
  const [record, setRecord] = useState({ rows: [] });
  const [products, setProducts] = useState([]);

  const { permissions } = usePermissions();
  useEffect(() => {
    const options = { method: "GET" };
    const requestUrl = `${url}/product?filter[0]=active||$ne||0`;
    httpClient(requestUrl, options)
      .then((response) => {
        setProducts(response.json);
      })
      .catch(() => {});
  }, []);
  const useStyles = makeStyles(() => ({
    root: {
      direction: "ltr",
      flip: false,
    },
    columnItem: {
      display: "inline-flex",
      "& > ul": {
        display: "inline-flex",
        "& > li": {
          border: "none",
          flexDirection: "column",
          margin: "1rem",
        },
      },
    },
  }));

  const getFridgeStructure = async (id) => {
    const requestUrl = `${url}/fridge-structure/${id}`;
    const options = { method: "GET" };
    const data = await httpClient(requestUrl, options);
    let shelvesGroupedByRows = groupBy(data?.json.shelves, "row");
    const rows = [];
    Object.keys(shelvesGroupedByRows).forEach((row) => {
      const columns = [];
      shelvesGroupedByRows[row].forEach((cell) => {
        columns[cell.column - 1] = {
          id: cell.id,
          shelfSize: cell.shelfType.shelfSize,
          shelfTypeId: cell.shelfType.id
        };
      });
      rows.push({ columns: columns });
    });
    setRecord({ ...record, id, rows });
  };
  const redirect = useRedirect();
  const translate = useTranslate();
  const classes = useStyles();
  return permissions === "admin" ? (
    <SimpleForm
      record={record}
      toolbar={
        <CreateToolBarWithCustomSave
          saveurl={`/planogram`}
          successmsg={translate("summary.planogram.create.successMsg")}
          title={translate("summary.planogram.create.successMsg")}
          goto={`/planogram`}
          callback={() => redirect("/planogram")}
        />
      }
      redirect="show"
     onSubmit={''}>
      <TextInput
        validate={[required()]}
        label={translate("resources.planogram.planogramName")}
        name={"planogramName"}
        onChange={(event) =>
          setRecord({ ...record, planogramName: event.target.value })
        }
       source={'planogramName'}/>
      <ReferenceInput
        label={translate("resources.fridge-structure.fridgeStructureName")}
        source="id"
        reference="fridge-structure"
        validate={[required()]}
        perPage={1000}
        inputProps={{
          onChange: (event) => {
            getFridgeStructure(event.target.value);
          },
        }}
      >
        <SelectInput source={"id"}
          isRequired={true}
            />
      </ReferenceInput>
      {record.rows.length > 0 ? (
        <ArrayInput
          validate={required()}
          source="rows"
          label={translate("general.shelves")}
          className={classes.root}
        >
          <SimpleFormIterator
            disableReordering
            disableRemove
            disableAdd
            className={classes.root}
          >
            <ArrayInput
              source="columns"
              label={translate("general.column")}
              className={classes.columnItem}
            >
              <SimpleFormIterator
                disableReordering
                disableRemove
                disableAdd
                className={classes.columnItem}
              >
                <TextInput
                  label={translate("general.shelfSize")}
                  source="shelfSize"
                  autoComplete="off"
                  disabled
                />
                <AutocompleteInput
                  choices={products}
                  label={translate("reference.product")}
                  source={"productId"}
                  isRequired={true}
                  validate={[required()]}


                />
                <NumberInput
                  min={0}
                  initialValue={7}
                  label={translate("general.standard_quantity")}
                  source="standard_quantity"
                  validate={[required(), minValue(1)]}
                  isRequired={true}

                />
                <NumberInput
                  min={0}
                  initialValue={25}
                  label={translate("general.maximum")}
                  source="maximum"
                  validate={[required(), minValue(1)]}
                  isRequired={true}

                />
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      ) : (
        ""
      )}
    </SimpleForm>
  ) : (
    <div />
  );
};

export default PlanogramCreate;
