import React, {useCallback, useState} from "react";
import { url } from "../config/connection";
import httpClient from "../DataProvider/httpClient";
import {
  Backdrop,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { SaveButton, useNotify, useRedirect, useTranslate } from "react-admin";
import { useForm } from "react-final-form";
import { Cancel } from "@material-ui/icons";
import { every, omit, remove } from "lodash";
import { makeStyles } from "@material-ui/core/styles";

const SaveWithModalButton = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const form = useForm();
  const { saveurl, successmsg, title, goto, callback } = props;
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modalTitle: {
      color: "#ff5722",
    },
    errorIcon: {
      color: "#f93e3e",
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [logs, setLogs] = useState([]);
  const [successNumber, setSuccessNumber] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  const handleOpen = (errLogs, number) => {
    setOpen(true);
    setLogs(errLogs);
    setSuccessNumber(number);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logManipulation = (log) => {
    if (log.type === "error") {
      return log.message;
    } else {
      return null;
    }
  };

  const handleSave = useCallback(() => {
    const state = form.getState();
    if (!state.hasValidationErrors) {
      const values = state.values;
      const requestUrl = `${url}${saveurl}`;
      const options = {
        method: "POST",
        body: JSON.stringify(omit(values, "warehouseInv")),
      };
      setIsSaving(true);
      return httpClient(requestUrl, options).then((response) => {
        if (every(response.json, (log) => log.type === "success")) {
          notify(successmsg, "success", { smart_count: response.json.length });
          if (goto) redirect(goto);
        } else {
          const successLogs = response.json;
          const errorLogs = remove(successLogs, (log) => log.type === "error");
          if (callback) {
            callback(form, successLogs);
          }
          handleOpen(errorLogs, successLogs.length);
        }
        setIsSaving(false);
      });
    }
  }, [form]);

  const generateListItems = function generateListItems(element, idx) {
    return React.cloneElement(element, { key: idx });
  };

  return (
    <div>
      <SaveButton
        {...omit(props, ["saveurl", "successmsg", "title", "goto", "callback"])}
        saving={isSaving}
        handleSubmitWithRedirect={handleSave}
        onSave={handleSave}
      />
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogTitle className={classes.modalTitle}>{title}</DialogTitle>
        <div>
          {successNumber > 0 ? (
            <h4
              style={{
                color: "#49cc90",
                paddingInlineStart: "1.5rem",
                margin: "0.2rem",
              }}
            >
              {translate(`${successmsg}`, { smart_count: successNumber })}
            </h4>
          ) : null}
          <List dense={true}>
            {logs.map((log, i) => {
              return generateListItems(
                <ListItem>
                  <ListItemIcon>
                    <Cancel className={classes.errorIcon} />
                  </ListItemIcon>
                  <ListItemText primary={logManipulation(log)} />
                </ListItem>,
                i
              );
            })}
          </List>
        </div>
      </Dialog>
    </div>
  );
};

export default SaveWithModalButton;
