import React from "react";
import {
	ArrayField,
	Datagrid,
	DateField,
	FunctionField,
	List,
	ReferenceArrayField,
	ReferenceField,
	SingleFieldList,
	TextField,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate
} from "react-admin";
import {Chip, makeStyles} from "@material-ui/core";
import httpClient from "../DataProvider/httpClient";
import {url} from "../config/connection";
import {Delete} from "@material-ui/icons";
import {greyColor,  locales} from "../constants";

const CustomField = ({record}) => {
	const notify = useNotify ()
	const redirect = useRedirect ()
	const refresh = useRefresh ()
	const translate = useTranslate ()
	const removeFromProfile = async (id) => {
		try {
			await httpClient (`${url}/profile/removeFridge/${id}`, {method: 'POST'})
			notify (translate ('summary.profile.remove.successMsg'), 'success')
			refresh ()
		} catch (e) {
			notify (translate ('summary.profile.remove.failMsg'), 'error')
		}

	}
	return <div className={'flex-row'} style={{margin: '5px'}}>
		<Delete fontSize={'small'} style={{cursor: "pointer"}}
		        onClick={() => removeFromProfile (record?.id, record?.serial)}/>
		<Chip onClick={() => redirect (`/fridge/${record.id}/show`)} label={record?.serial}/></div>
}


const AllFridges = () => {
	const translate = useTranslate ()
	return <ArrayField fullWidth={true} headerClassName={'header'}
	                   source={"miniProfiles"} label={"Included structures"}>
		<Datagrid>
			<ReferenceField
				allowEmpty={true}
				label={translate ('reference.fridgeStructure')}
				source='planogram.fridgeStructureId'
				reference='fridge-structure'
				link='show'
			>
				<TextField source='name' label={translate ('reference.fridgeStructure')}/>
			</ReferenceField>
			<ReferenceField

				allowEmpty={true}
				label={translate ('reference.planogram')}
				source='planogram.id'
				reference='planogram'
				link='show'
			>
				<TextField source='name'/>
			</ReferenceField>
			<ArrayField source={"fridges"} label={translate ('resources.profile.fridges')}>
				<SingleFieldList linkType={false}>

					<ReferenceArrayField
						allowEmpty={true}
						label={translate ('resources.profile.fridges')}
						reference='fridge'
						target={'id'}
						link={'/'}
					>
						<FunctionField render={record =>
							<CustomField record={record}/>}
						/>
					</ReferenceArrayField>

				</SingleFieldList>
			</ArrayField>
		</Datagrid>
	</ArrayField>
}
const useStyles = makeStyles ({
	                              cell: {width: '20vw'},
	                              header: {backgroundColor: greyColor}
                              });
const ProfileList = ({permissions, ...props}) => {
	const translate = useTranslate ()
	const classes = useStyles ()


	return (
		<List
			{...props}
			perPage={25}
			sort={{field: 'createdAt', order: 'DESC'}}
			hasCreate={permissions === 'admin'}

		>
			<Datagrid rowClick='show' expand={<AllFridges classes={classes} {...props}/>}>
				<TextField source={'name'} label={translate ("resources.profile.profileName")}/>

				<ReferenceField
					allowEmpty={true}
					label={translate ('reference.release-channels')}
					source='releaseChannels'
					reference='release-channels'
					link='show'
				>
					<TextField source='name'/>
				</ReferenceField>
				<DateField source='createdAt' options={{
					year: '2-digit',
					month: '2-digit',
					day: '2-digit',
					hour: '2-digit',
					minute: '2-digit',
				}}
				           label={'general.createdAt'}
				           locales={locales}/>

			</Datagrid>
		</List>
	)
}
export default ProfileList


