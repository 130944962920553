import React, {Fragment} from "react";
import ReactS3Uploader from "react-s3-uploader";
import {useTranslate} from "react-admin";
import Button from "@material-ui/core/Button";

const AddFileBtn = ({ classes, label, uploadOptions }) => {
	const translate = useTranslate ();

	// https://github.com/odysseyscience/react-s3-uploader/issues/90
	const _onUploadStart = (file, next) => {
		compressFile(file, next, { format: 'image/jpeg' });
	}

	const dataURItoBlob = (dataURI, format) => {
		// The atob() function decodes a string of data which has been encoded using Base64 encoding
		const binary = atob (dataURI.split (',')[1]);
		const array = [];
		for (let i = 0; i < binary.length; i++) { // eslint-disable-line
			array.push (binary.charCodeAt (i));
		}
		return new Blob ([new Uint8Array (array)], {type: format});
	}

	const compressFile = (file, next, options) => {
		const {format} = options;
		const img = new Image ();
		const canvas = document.createElement ('canvas');
		const ctx = canvas.getContext ('2d');
		//on load image size re-drawn cut to half
		img.onload = () => {
			const {width, height} = img;
			canvas.width = width / 2;
			canvas.height = height / 2;
			ctx.drawImage (img, 0, 0, width / 2, height / 2);
			next (new File ([dataURItoBlob (canvas.toDataURL (format, 0.5))], file.name, {type: format}));
		};
		img.src = URL.createObjectURL (file);
	}
	return (
		<Fragment>
			<Button className={classes.labelFileInput} color="primary">
				<label htmlFor={uploadOptions.id} style={{padding: "3px 10px", display: "inline-block"}}>
					{translate ("general.addImage")}
				</label>
			</Button>
			<ReactS3Uploader {...uploadOptions} preprocess={_onUploadStart}/>
		</Fragment>
	);
};

export default AddFileBtn;
