import {Close} from '@material-ui/icons'
import {Button} from '@material-ui/core'
import {
	CreateButton,
	Datagrid,
	DateField,
	ExportButton,
	Filter,ReferenceInput,
	List,
	sanitizeListRestProps,
	TextField,
	TextInput,SelectInput,
	TopToolbar,
	usePermissions,
	useTranslate
} from "react-admin";
import React, {cloneElement} from "react";


const {locales} = require ('../constants')

const PlanogramTopToolbar = ({
	                             currentSort,
	                             className,
	                             resource,
	                             filters,
	                             displayedFilters,
	                             exporter, // you can hide ExportButton if exporter = (null || false)
	                             filterValues,
	                             permanentFilter,
	                             hasCreate, // you can hide CreateButton if hasCreate = false
	                             basePath,
	                             selectedIds,
	                             onUnselectItems,
	                             showFilter,
	                             maxResults,
	                             total,
	                             ...rest
                             }) => {
	const {permissions} = usePermissions ()
	const transalte = useTranslate ()
	return (
		<TopToolbar className={className} {...sanitizeListRestProps (rest)}>
			{filters &&
			cloneElement (filters, {
				resource,
				showFilter,
				displayedFilters,
				filterValues,
				context: 'button'
			})}
			{filters && (
				<Button
					color='primary'
					size='small'
					startIcon={<Close/>}
					onClick={() => rest.setFilters ({})}
				>
					{transalte ('general.clearFilter')}
				</Button>
			)}
			{permissions === 'admin' && <CreateButton basePath={basePath}/>}
			{permissions === 'admin' && (
				<ExportButton
					disabled={total === 0}
					resource={resource}
					sort={currentSort}
					filter={{...filterValues, ...permanentFilter}}
					exporter={exporter}
					maxResults={maxResults}
				/>
			)}
		</TopToolbar>
	)
}



const PlanogramFilter = props => {

	const translate = useTranslate ()
	return <Filter {...props}>
		<TextInput source='name' label={translate ('resources.planogram.planogramName')} alwaysOn> </TextInput>
		<ReferenceInput reference={'fridge-structure'} source={'fridgeStructureId'} label={translate ('resources.fridge-structure.name')} alwaysOn>
			<SelectInput optionText='name' />
		</ReferenceInput>

	</Filter>

}
const PlanogramList = props => {
	const translate = useTranslate ()
	const {permissions} = usePermissions ()
	return (
		<List
			{...props}

			actions={<PlanogramTopToolbar/>}
			filters={<PlanogramFilter/>}
			hasCreate={permissions === 'admin'}
			// bulkActionButtons={<PlanogramActions/>}
			bulkActionButtons={false}
			filter={{
				'ofFridge||$eq':0,
				join: [
					'fridge||id,name',
					'profile||id,name,profileId,profile',
					'fridgeStructure||id,name',

				]
			}}

		>
			<Datagrid rowClick='show'>
				<TextField label={translate ('resources.planogram.planogramName')} source='name'/>
				{/*<TextField source='fridge.serial' label={translate ('general.serial')}/>*/}
				<TextField source='fridgeStructure.name'
				           label={translate ('resources.fridge-structure.fridgeStructureName')}/>
				<DateField
					source='createdAt'
					options={{
						year: '2-digit',
						month: '2-digit',
						day: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
					}}
					showTime={true}
					locales={locales}
					label={translate ('general.createdAt')}
				/>
			</Datagrid>
		</List>
	)
}

export default PlanogramList
