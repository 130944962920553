import React, {useState} from "react";
import {
	AutocompleteInput,
	BooleanInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	TextInput,
	useTranslate
} from "react-admin";
import {GetFridgeGenerationList} from "./index";
import {debounce, perPageInputField} from "../constants";
import {url} from "../config/connection";
import httpClient from "../DataProvider/httpClient";
import {groupBy} from 'lodash/collection';
import CreateToolBarWithCustomSave from '../components/CreateToolBarWithCustomSave';

export const FridgeCreate = ({permissions}) => {
	const translate = useTranslate ()
	const [record, setRecord] = useState ({
		                                      rows: [],
		                                      desiredApk: '',
		                                      desiredFirmware: '',
		                                      releaseChannels: '',
		                                      planogram: '',
		                                      name: '',
		                                      fridgeStructureId: ''

	                                      })
	const [profileChoices, setProfileChoices] = useState ([])
	const [fridgeStructureId, setFridgeStructureId] = useState ('')
	const getProfileData = async (profileId) => {

		const profile = profileChoices.find (profile => profile.id === profileId)
		const {desiredApk, desiredFirmware} = profile


		const miniProfile = profile.miniProfiles.filter (miniProfile => miniProfile.fridgeStructureId === fridgeStructureId)[0]
		setRecord ({
			           ...record,
			           desiredApk,
			           desiredFirmware,
			           planogram: miniProfile?.planogram?.name,
			           miniProfileId: miniProfile?.id,
			           profileId: profileId

		           })

	}
	const getRelevantProfiles = async (fridgeStructureId) => {
		const requestUrl = `${url}/profile`
		const options = {method: 'GET'}
		const {json} = await httpClient (requestUrl, options)
		const profileChoices = []
		json.forEach (profile => {
			profile.miniProfiles.forEach (miniProfile => {
				                              if (miniProfile.planogram.fridgeStructureId === fridgeStructureId) {
					                              profileChoices.push (profile)
				                              }
			                              }
			)
		})


		return profileChoices

	}

	const getFridgeStructure = async (id) => {
		const requestUrl = `${url}/fridge-structure/${id}`
		const options = {method: 'GET'}
		const data = await httpClient (requestUrl, options)
		setFridgeStructureId (id)
		let shelvesGroupedByRows = groupBy (data?.json.shelves, 'row')
		const rows = []
		let count = 1
		Object.keys (shelvesGroupedByRows).forEach ((row, ) => {
			const columns = []
			shelvesGroupedByRows[row].forEach ((cell) => {
				columns[cell.column - 1] = {
					id: cell?.id,
					shelfSize: cell?.shelfType?.shelfSize,
					shelfTypeId: cell?.shelfType?.id,
					hardwareId: count
				}
				count++
			})
			rows.push ({columns: columns})
		})
		const profilesChoices = await getRelevantProfiles (id)

		setRecord ({...record, rows, fridgeStructureId: id})
		setProfileChoices (profilesChoices)

	}
	if (permissions === 'admin') {
		return <SimpleForm
			className={'fridge-create'}
			record={record}

			toolbar={
				<CreateToolBarWithCustomSave
					saveurl={`/fridge`}
					redirect={'/fridge'}
					goto={'/fridge'}
					successmsg={translate ('summary.fridge.create.successMsg')}
					title={translate ('summary.fridge.create.failMsg')}

				/>
			}
		 onSubmit={''}>
			<div>
				<TextInput
					source="serial"
					label={translate ('general.serial')}
					validate={[required ()]}
					inputProps={{
						onChange: (event) => {
							setRecord ({...record, name: "fridge " + event.target?.value, serial: event.target?.value})
						}
					}}
					autoComplete="off"

				/>
				<ReferenceInput
					label={translate ('resources.fridge-structure.fridgeStructureName')}
					source="fridgeStructureId"
					reference="fridge-structure"
					validate={[required ()]}
					perPage={1000}
					inputProps={{
						onChange: (event) => {
							getFridgeStructure (event.target.value);
						}
					}}
				>
					<SelectInput source={"id"}/>
				</ReferenceInput>

				< SelectInput source="profileId" onChange={e => getProfileData (e.target.value)}
				              choices={profileChoices}
				              key="id" fullWidth
				              label={'reference.profile'}
				              optionText="name" optionValue="id"/>

				<BooleanInput source="active" label={translate ('resources.fridge.fields.active')}
				/>
				<BooleanInput source="linkedToPrivatePlanogram" defaultValue={false}
				              label={translate ('resources.fridge.fields.algoretailLinked')}/>

				{record.desiredApk && <TextInput source="desiredApk" disabled/>}
				{record.desiredFirmware && <TextInput source="desiredFirmware" disabled/>}
				<ReferenceInput
					label={translate ('reference.release-channels')}
					source="releaseChannelId"
					reference="release-channels"
					perPage={1000}
					validate={[required ()]}
				>
					<SelectInput optionText="displayName"/>
				</ReferenceInput>
				<SelectInput choices={GetFridgeGenerationList (translate)} optionText="generation"
				             label={translate ('reference.generation')} source="generation"
				/>

				<ReferenceInput
					validate={[required ()]}
					label={translate ("reference.complexe")}
					source="complexeId"
					reference="complexe"
					setPaginatinon={(x) => console.log (x)}
					perPage={perPageInputField}
					filterToQuery={(searchText) => {
						if (searchText)
							return {
								q: {
									$or: [
										{name: {$cont: searchText}},
										{city: {$cont: searchText}},
										{street: {$cont: searchText}}
									]
								},
								isSearch: true
							};
					}}

					debounce={debounce}
				>

					<AutocompleteInput
						optionText={(record) =>
							record
								? `${record.name} - ${record.city} ${record.street} ${record.streetNumber}`
								: ``
						}
						translateChoice={false}
						validate={[required ()]}
						debounce={debounce}
					/>
				</ReferenceInput>
				<TextInput source="whatsappLink" autoComplete="off"/>
			</div>
			{record.planogram && <TextInput source="planogram" disabled/>}
			{/*{record.rows?.length>0 && <ArrayInput*/}

			{/*	source='rows'*/}
			{/*	label={translate ('general.shelves')}*/}
			{/*	className={classes.root}*/}
			{/*>*/}
			{/*	<SimpleFormIterator    disableRemove disableAdd className={classes.root}>*/}
			{/*		<ArrayInput*/}
			{/*			source='columns'*/}
			{/*			label={translate ('general.column')}*/}
			{/*			className={classes.columnItem}*/}
			{/*		>*/}
			{/*			<SimpleFormIterator disableRemove disableAdd className={classes.columnItem}>*/}
			{/*				<TextInput*/}
			{/*					label={translate ('general.shelfSize')}*/}
			{/*					source='shelfSize'*/}
			{/*					validate={false}*/}
			{/*					autoComplete="off"*/}
			{/*					disabled*/}
			{/*				/>*/}
			{/*				<NumberInput*/}
			{/*					label={translate ('general.hardwareId')}*/}
			{/*					source='hardwareId'*/}
			{/*					autoComplete="off"*/}
			{/*					validate={[required(),minValue(1),maxValue(32)]}*/}
			{/*				/>*/}
			{/*			</SimpleFormIterator>*/}
			{/*		</ArrayInput>*/}
			{/*	</SimpleFormIterator>*/}
			{/*</ArrayInput>}*/}


		</SimpleForm>
	}


	else return <div/>

}



