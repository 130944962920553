import React, { Fragment, useEffect, useState } from "react";
import { url } from "../config/connection";
import httpClient from "../DataProvider/httpClient";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import {
  AutocompleteInput,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SaveButton,
  SimpleForm,
  Toolbar,
  useTranslate,
} from "react-admin";
import { useForm } from "react-final-form";
import { Refresh } from "@material-ui/icons";
import { omit } from "lodash";
import { debounce, perPageInputField } from "../constants";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  changeProductButton: {
    position: "absolute",
    right: "0rem",
    top: "0rem",
  },
}));

const ChangeProductFormToolBar = (props) => {
  const [saving, setSaving] = useState(false);
  const form = useForm();
  const translate = useTranslate();
  const closeFunction = props.close;
  const saveFunction = props.save;
  const { location } = props;

  const handleSave =  ()=> {
    setSaving(true);
  };

  useEffect(() => {
    if (saving) {
      const state = form.getState();
      if (!state.hasValidationErrors) {
        const values = state.values;
        const requestUrl = `${url}/location/changeProduct`;
        const options = {
          method: "POST",
          body: JSON.stringify({ ...values, locationId: location.id }),
        };
        httpClient(requestUrl, options)
          .then((response) => {
            setSaving(false);
            if (saveFunction) saveFunction(response.json);
          })
          .catch(() => {
            setSaving(false);
          });
      } else {
        setSaving(false);
      }
    }
  }, [saving]);

  return (
    <Toolbar {...props}>
      {!saving ? (
        <SaveButton
          label={translate("general.save")}
          // handleSubmitWithRedirect={handleSave}
          onSave={handleSave}
          redirect={false}
          submitOnEnter={true}
        />
      ) : (
        <LinearProgress style={{ width: "5rem" }} />
      )}
      <Button onClick={closeFunction}>{translate("general.cancel")}</Button>
    </Toolbar>
  );
};

export default function ChangeProductButton(props) {
  const [open, setOpen] = useState(false);
  const [location] = useState(props.location);
  const [record, setRecord] = useState(props.location);
  const classes = useStyles();
  const theme = useTheme();
  const translate = useTranslate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const openDialog = ()=> {
    setOpen(true);
  };

  useEffect(() => {
    setRecord(omit(props.location, "productId"));
  }, [JSON.stringify(props.location)]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (values) => {
      props.onClose(values);
    handleClose();

  };

  const maximumValidation = (value, allValues) => {
    if (allValues.standard_quantity && value < allValues.standard_quantity) {
			return translate ("error.planogram.maximumMustBeBiggerThanStandardQuantity");
    }
    return undefined
  };

  return (
    <Fragment>
      {!props.disabled ? (
        <Tooltip
          title={translate("general.changeProduct")}
          aria-label={translate("general.changeProduct")}
        >
          <IconButton
            disabled={props.disabled}
            className={`${classes.changeProductButton}`}
            color="primary"
            size="small"
            onClick={() => {
              openDialog();
            }}
          >
            <Refresh />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton
          disabled={props.disabled}
          className={`${classes.changeProductButton}`}
          color="primary"
          size="small"
          onClick={() => {
            openDialog();
          }}
        >
          {" "}
          <Refresh />
        </IconButton>
      )}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        fullWidth
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {translate("title.changeProductInLocation", {
            product: location?.product?.name,
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <SimpleForm
              toolbar={
                <ChangeProductFormToolBar
                  location={location}
                  close={handleClose}
                  save={handleSave}
                />
              }
              record={record}
             onSubmit={''}>
              <ReferenceInput
                fullWidth
                validate={[required()]}
                label={translate("reference.product")}
                source="productId"
                reference="product"
                perPage={perPageInputField}
                filter={{ "active||$eq": true }}
                filterToQuery={(searchText) => {
                  if (searchText) return { name: searchText };
                }}
              >
                <AutocompleteInput optionText="name" validate={[required()]} debounce={debounce} />
              </ReferenceInput>
              <NumberInput
                min={0}
                fullWidth
                label={translate("general.standard_quantity")}
                source="standard_quantity"
                validate={[required(), minValue(1)]}
              />
              <NumberInput
                min={0}
                fullWidth
                label={translate("general.maximum")}
                source="maximum"
                validate={[required(), minValue(1), maximumValidation]}
              />
            </SimpleForm>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
