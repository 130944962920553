import React,{ useRef } from "react";
import { QRCodeCanvas } from "qrcode.react";
import {Button} from '@material-ui/core';

const QRcode=(props)=> {
	const {translate,data}=props
	const canvasRef = useRef();
	const handleButtonClicked = () => {
		const canvas = canvasRef.current.children[0]?.children[0];
		const pngFile = canvas.toDataURL("image/png");

		const downloadLink = document.createElement("a");
		downloadLink.download = "QrCode";
		downloadLink.href = `${pngFile}`;
		downloadLink.click();
	};
	const str= `technician;${data?.otherId};${data?.technicianPassword}`
	return (
		<div className="App">
			<div ref={canvasRef}>
				<div>
					<QRCodeCanvas value={str} includeMargin={true} />
				</div>
			</div>
			<Button   color="primary"
			          size="small" onClick={handleButtonClicked}>{translate('ra.action.download')}</Button>
		</div>
	);
}

export default QRcode
