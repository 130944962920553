import React, { cloneElement, Fragment } from "react";
import {
  AutocompleteInput,
  Datagrid,
  downloadCSV,
  ExportButton,
  Filter,
  List,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceInput,
  sanitizeListRestProps,
  TextField,
  TextInput,
  TopToolbar,
  useTranslate,
} from "react-admin";
import jsonExport from "jsonexport/dist";
import ExpirationDateField from "../components/ExpirationDateField";
import CustomPage from "../components/CustomPage";
import CreateTransactionButton from "../components/CreateTransactionButton";
import { debounce, perPageInputField, perPageList } from "../constants";
import {downloadExcel} from '../utils.service';

const StatusByFridgeFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <ReferenceInput
        label={translate("reference.fridge")}
        source="fridgeId||$eq"
        reference="fridge"
        filterToQuery={(searchText) => {
          if (searchText) return { serial: searchText };
        }}
        debounce={debounce}
        perPage={perPageInputField}
        alwaysOn
      >
        <AutocompleteInput  optionText="serial" debounce={debounce} />
      </ReferenceInput>
      <TextInput label={translate("general.city")} source="city" alwaysOn />
      <TextInput label={translate("general.street")} source="street" alwaysOn />
    </Filter>
  );
};

const StatusByFridgePagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
);

const StatusByFridgeBulkActions = (props) => {
  return (
    <Fragment>
      <CreateTransactionButton {...props} />
    </Fragment>
  );
};

const exporter = (inventories) => {
  const statusForExport = inventories.map((inventory) => {
    const { expiration_time, fridgeId, id, productId, warehouseId, ...statusForExport } = inventory; // omit backlinks and author
    return statusForExport;
  });
  jsonExport(
    statusForExport,
    {
      headers: [
        "fridge_serial",
        "product",
        "standard_quantity",
        "quantity",
        "gap",
        "city",
        "street",
        "streetNumber",
      ], // order fields in the export
      rename: [
        "Fridge Serial",
        "Product Name",
        "Standard Quantity",
        "Quantity",
        "Gap",
        "City",
        "Street",
        "Street Number",
      ],
    },
    (err, csv) => {
      const eachLine = csv.split("\n");
      csv = eachLine.map((line) => "\ufeff" + line);
      downloadCSV(csv.join("\n"), "statusByFridge");
    }
  );
};

const StatusByFridgeToolbar = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={()=>downloadExcel(Object.values(rest.data),rest.defaultTitle)}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

const StatusByFridge = () => {
  const translate = useTranslate();
  return (
    <CustomPage title={translate("title.statusByFridge")}>
      <List
        title=" "
        hasCreate={false}
        hasEdit={false}
        hasList={false}
        hasShow={false}
        actions={<StatusByFridgeToolbar />}
        exporter={exporter}
        resource={`fridge/statusByFridge`}
        basePath={`fridge/statusByFridge`}
        filters={<StatusByFridgeFilter />}
        sort={{ field: "fridgeId", order: "ASC" }}
        perPage={perPageList}
        pagination={<StatusByFridgePagination />}
        bulkActionButtons={<StatusByFridgeBulkActions />}
      >
        <Datagrid rowClick="" optimized>
          <ReferenceField
            allowEmpty={true}
            label={translate("reference.fridge")}
            source="fridgeId"
            reference="fridge"
            link=""
          >
            <TextField source="serial" />
          </ReferenceField>
          <ReferenceField
            allowEmpty={true}
            label={translate("reference.product")}
            source="productId"
            reference="product"
            link=""
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField label={translate("general.city")} source="city" />
          <TextField label={translate("general.street")} source="street" />
          <TextField label={translate("general.streetNumber")} source="streetNumber" />
          <NumberField label={translate("general.quantity")} source="quantity" />
          <NumberField label={translate("general.standard_quantity")} source="standard_quantity" />
          <NumberField label={translate("general.gap")} source="gap" />
          <ExpirationDateField
            label={translate("general.closestExpDate")}
            source="expiration_time"
          />
        </Datagrid>
      </List>
    </CustomPage>
  );
};

export default StatusByFridge;
