import React from "react";
import { url } from "../config/connection";
import httpClient from "../DataProvider/httpClient";
import { Button } from "@material-ui/core";
import { useNotify, useTranslate } from "react-admin";
import { Cached } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  mainDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

const GenerateFridgeTokenButton = (props) => {
  const { id, serial } = props.record;
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const [secret, setSecret] = React.useState(null);
  const generateNewToken = async () => {
    const requestUrl = `${url}/fridge/fridge-token`;
    const options = { method: "POST", body: JSON.stringify({ fridgeId: id }) };
    httpClient(requestUrl, options)
      .then((response) => {
        const { newSecret } = response.json;
        setSecret(newSecret);
        const blob = new Blob([`[config]\ndeviceId=${serial}\nsecret=${newSecret}`], {
          type: "ini",
        });
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, "Hamezave_config.ini");
        } else {
          const elem = window.document.createElement("a");
          elem.href = window.URL.createObjectURL(blob);
          elem.download = "Hamezave_config_"+serial+".ini";
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }
      })
      .catch((err) => {
        notify(JSON.stringify(err), "error");
      });
  };

  return (
    <div className={classes.mainDiv}>
      <Button
        component="span"
        color="primary"
        size="small"
        label={translate("general.clickHereToGenerateFridgeToken")}
        startIcon={<Cached />}
        onClick={generateNewToken}
      >
        {translate("general.clickHereToGenerateFridgeToken")}
      </Button>
      {secret && <h2>{secret}</h2>}
    </div>
  );
};

export default GenerateFridgeTokenButton;
