import React, { Fragment } from "react";
import {
	BooleanInput,
	Datagrid,
	DateField,
	DateTimeInput,
	Filter,
	List,
	TextField,
	useTranslate
} from "react-admin";
import {TableCell, TableHead, TableRow} from '@material-ui/core';

const { locales } = require("../../constants");

const VersionChangesilter = (props) => {
	const translate = useTranslate();
	return (
		<Filter {...props}>

		</Filter>
	);
};

const DatagridHeader = ({ children }) => (
	<TableHead>
		<TableRow>
			{children.map( (child) => (
				<TableCell key={child.props.source}>
					{child.props.source}
				</TableCell>
			))}
		</TableRow>
	</TableHead>
);
const VersionChangesList= (props) => {
	const {
		match: {
			params: { fridgeId },
		},
	} = props;
	const translate = useTranslate();
	return (
		<Fragment>
			<List
				title=" "
				bulkActionButtons={false}
				hasCreate={false}
				hasEdit={false}
				hasList={true}
				hasShow={false}
				sort={{ field: "createdAt", order: "DESC" }}
				resource={`version-changes`}
				basePath={`version-changes`}
				filter={{ "fridgeId||$eq": parseInt(fridgeId, 10) }}
			>
				<Datagrid optimized rowClick="" resource={`version-changes`} basePath={`version-changes`}  >
					<TextField  source="version"
					            label={translate("general.version")}
					/>
					<TextField  source="value"
					            label={translate("general.value")}

					/>
					<DateField
						label={translate("general.startDate")}
						showTime={true}
						options={{
							year: "numeric",
							month: "numeric",
							day: "numeric",
							hour: "2-digit",
							minute: "2-digit",
							second: "2-digit",
						}}
						source="startDate"
						locales={locales}

					/>					<DateField
						label={translate("general.endDate")}
						showTime={true}
						options={{
							year: "numeric",
							month: "numeric",
							day: "numeric",
							hour: "2-digit",
							minute: "2-digit",
							second: "2-digit",
						}}
						source="endDate"
						locales={locales}

					/>
				</Datagrid>
			</List>
		</Fragment>
	);
};

export default {
	VersionChangesList,
};
