import React, { cloneElement, Fragment } from "react";
import {
  BooleanField,
  BooleanInput,
  BulkDeleteButton,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  ExportButton,
  Filter,
  List,
  required,
  sanitizeListRestProps,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  usePermissions,
  useTranslate,
} from "react-admin";
import { Button } from "@material-ui/core";
import { Close, StoreMallDirectory } from "@material-ui/icons";
import inventory from "../Inventories";
import {downloadExcel} from '../utils.service';

const WarehouseCreate = ({ permissions, ...props }) => {
  return (
    <Create {...props}>
      {permissions === "admin" ? (
        <SimpleForm redirect="show" onSubmit={''}>
          <TextInput source="name" autoComplete="off" validate={[required()]} />
          <TextInput source="city" autoComplete="off" validate={[required()]} />
          <TextInput source="street" autoComplete="off" validate={[required()]} />
          <TextInput source="streetNumber" autoComplete="off" validate={[required()]} />
        </SimpleForm>
      ) : (
        <div></div>
      )}
    </Create>
  );
};

const WarehouseTitle = ({ record }) => <span>{record.name}</span>;

const WarehouseEdit = ({ permissions, ...props }) => {
  return (
    <Edit {...props} title={<WarehouseTitle />}>
      {permissions === "admin" && (
        <SimpleForm redirect="list" onSubmit={''}>
          <TextInput source="name" autoComplete="off" validate={[required()]} />
          <TextInput source="city" autoComplete="off" validate={[required()]} />
          <TextInput source="street" autoComplete="off" validate={[required()]} />
          <TextInput source="streetNumber" autoComplete="off" validate={[required()]} />
          <BooleanField source="isFridge" />
        </SimpleForm>
      )}
    </Edit>
  );
};

const WarehouseShow = ({ permissions, ...props }) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const translate = useTranslate();
  return (
    <Show {...props} title={<WarehouseTitle />} hasEdit={permissions === "admin"}>
      <TabbedShowLayout>
        <Tab label={translate("general.expiryInventory")}>
          <inventory.EmbeddedInventoryList
            match={{
              isExact: true,
              params: { warehouseId: id },
              url: `/warehouse/${id}/show`,
            }}
            location={{
              hash: "",
              pathname: `/warehouse/${id}/show`,
              search: "",
              state: null,
            }}
          />
        </Tab>
        <Tab label={translate("general.totalInventory")} path="products">
          <inventory.ProductsInventoryDatagrid
            match={{
              isExact: true,
              params: { warehouseId: id },
              url: `/warehouse/${id}/show`,
            }}
          />
        </Tab>
        <Tab label={translate("general.summary")} path="summary">
          <TextField source="name" />
          <TextField source="city" />
          <TextField source="street" />
          <TextField source="streetNumber" />
          <BooleanField source="isFridge" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const WarehouseFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn />
      <TextInput source="city" alwaysOn />
      <BooleanInput
        label={translate("general.showFridge")}
        source="isFridge||$in"
        alwaysOn
        parse={(val) => {
          if (val) return [0, 1];
          return 0;
        }}
      />
    </Filter>
  );
};

const WarehouseBulkActions = (props) => {
  return (
    <Fragment>
      <BulkDeleteButton {...props} />
    </Fragment>
  );
};

const WarehouseTopToolbar = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const { permissions } = usePermissions();
  const transalte = useTranslate();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {filters && (
        <Button
          color="primary"
          size="small"
          startIcon={<Close />}
          onClick={() => rest.setFilters({ "isFridge||$in": 0 })}
        >
          {transalte("general.clearFilter")}
        </Button>
      )}
      {permissions === "admin" && <CreateButton basePath={basePath} />}
      {permissions === "admin" && (
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={()=>downloadExcel(Object.values(rest.data),rest.defaultTitle)}
          maxResults={maxResults}
        />
      )}
    </TopToolbar>
  );
};

const WarehouseList = ({ permissions, ...props }) => (
  <List
    {...props}
    filters={<WarehouseFilter />}
    filterDefaultValues={{ "isFridge||$in": 0 }}
    bulkActionButtons={permissions === "admin" ? <WarehouseBulkActions /> : false}
    hasCreate={permissions === "admin"}
    actions={<WarehouseTopToolbar />}
  >
    <Datagrid optimized rowClick="show">
      <TextField source="name" />
      <TextField source="city" />
      <TextField source="street" />
      <TextField source="streetNumber" />
      <BooleanField source="isFridge" />
    </Datagrid>
  </List>
);

// const WarehouseDatagrid = props => {
//   return (
//     <Datagrid rowClick='show' {...props}>
//       <TextField source='name' />
//       <TextField source='city' />
//       <TextField source='street' />
//       <TextField source='streetNumber' />
//     </Datagrid>
//   )
// }

export default {
  // WarehouseDatagrid: WarehouseDatagrid,
  list: WarehouseList,
  create: WarehouseCreate,
  edit: WarehouseEdit,
  show: WarehouseShow,
  icon: StoreMallDirectory,
};
