import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  Filter,
  List,
  ReferenceManyField,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  usePermissions,
  useTranslate,
} from "react-admin";
import GenericTopToolbar from "../components/GenericTopToolbar";
import { LocalShipping } from "@material-ui/icons";
import Product from "../Products/index";

const { ProductDatagrid } = Product;
const VendorCreate = (props) => {
  const { permissions } = usePermissions();
  return (
    <Create {...props}>
      {permissions === "admin" ? (
        <SimpleForm redirect="show" onSubmit={''}>
          <TextInput source="name" autoComplete="off" />
          <TextInput source="contact" autoComplete="off" />
          <TextInput source="phoneNumber" autoComplete="off" />
        </SimpleForm>
      ) : (
          <div/>
      )}
    </Create>
  );
};

const VendorTitle = ({ record }) => <span>{record.name}</span>;

const VendorEdit = (props) => {
  const { permissions } = usePermissions();
  return (
    <Edit {...props} title={<VendorTitle />}>
      {permissions === "admin" && (
        <SimpleForm redirect="list" onSubmit={''}>
          <TextInput source="name" autoComplete="off" />
          <TextInput source="contact" autoComplete="off" />
          <TextInput source="phoneNumber" autoComplete="off" />
        </SimpleForm>
      )}
    </Edit>
  );
};

const VendorShow = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <Show {...props} hasEdit={permissions === "admin"} title={<VendorTitle />}>
      <TabbedShowLayout>
        <Tab label={translate("general.summary")}>
          <TextField source="name" />
          <TextField source="contact" />
          <TextField source="phoneNumber" />
        </Tab>
        <Tab label={translate("reference.products")} path="products">
          <ReferenceManyField reference="product" target="vendorId" addLabel={false}>
            <ProductDatagrid {...props} />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

// const VendorBulkActions = props => {
//   return (
//     <Fragment>
//       <BulkDeleteButton {...props} />
//     </Fragment>
//   )
// }

const VendorDatagrid = (props) => {
  return (
    <Datagrid optimized rowClick="show" {...props}>
      <TextField source="name" />
      <TextField source="contact" />
      <TextField source="phoneNumber" />
    </Datagrid>
  );
};

const VendorFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
);

const VendorList = ({ permissions, ...props }) => {
  return (
    <List
      filters={<VendorFilter />}
      {...props}
      // bulkActionButtons={
      //   permissions === 'admin' ? <VendorBulkActions /> : false
      // }
      bulkActionButtons={false}
      hasCreate={permissions === "admin"}
      actions={<GenericTopToolbar />}
    >
      <VendorDatagrid />
    </List>
  );
};

export default {
  list: VendorList,
  create: VendorCreate,
  edit: VendorEdit,
  show: VendorShow,
  icon: LocalShipping,
};
