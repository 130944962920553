import React, { cloneElement } from "react";
import {
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  TopToolbar,
  useTranslate,
} from "react-admin";
import { Close } from "@material-ui/icons";
import { Button } from "@material-ui/core";

const TransactionListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter,
  filterValues,
  permanentFilter,
  hasCreate,
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const translate = useTranslate();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {filters && (
        <Button
          color="primary"
          size="small"
          startIcon={<Close />}
          onClick={() => rest.setFilters({})}
        >
          {translate("general.clearFilter")}
        </Button>
      )}
      {hasCreate && <CreateButton basePath={basePath} />}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={{ ...filterValues, ...permanentFilter }}
        exporter={exporter}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

TransactionListActions.defaultProps = {
  selectedIds: [],
};

export default TransactionListActions;
