import React, { Fragment } from "react";
import { Button, useNotify, useRedirect, useTranslate, useUnselectAll } from "react-admin";
import { pick, uniq } from "lodash";

const CreateTransactionButton = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const unselectAll = useUnselectAll();

  const handleClick = () => {
    const { selectedIds } = props;
    let warehouseId = uniq(selectedIds.map((id) => id.split("_")[1] || 0));
    if (warehouseId.length !== 1) {
      notify("error.inventory.twoWarehouses", "warning");
    } else {
      const data = selectedIds.map((ids) => {
        const [, warehouseId, productId, quantity] = ids.split("_");
        return {
          warehouseId: parseInt(warehouseId),
          productId: parseInt(productId),
          quantity: parseInt(quantity),
        };
      });
      unselectAll("fridge/statusByFridge");
      redirect(
        `/transaction/create/${JSON.stringify({
          type: "fridgeStatus",
          items: data.map((d) => pick(d, ["productId", "warehouseId", "quantity"])),
        })}`
      );
    }
  };

  return (
    <Fragment>
      <Button label={translate("general.createTransactionButton")} onClick={handleClick} />
    </Fragment>
  );
};

export default CreateTransactionButton;
