import React, {useEffect, useState} from "react";
import {TabbedForm,
	AutocompleteInput,
	BooleanInput,
	Edit,
	ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
	TextField,
  TextInput,
  useTranslate,FormTab
} from "react-admin";
import { FridgeTitle, GetFridgeGenerationList } from "./index";
import {cameraRotation, debounce, perPageInputField} from "../constants";
import {url} from "../config/connection";
import httpClient from "../DataProvider/httpClient";
import RedirectButton from "../components/RedirectButton";



const CustomProfileSelectInput = (props) => {
	const [profile, setProfile] = useState (0)
	useEffect (() => {
		if (!props.record?.profile?.profileId) {
			return
		}
		httpClient (url + '/profile/' + props.record?.profile?.profileId).then (res => {
			const parsed = JSON.parse (res.body).catch (() => {
			});

			setProfile (parsed)
		})

	}, [props.record.profileId, props.record?.profile?.profileId]);
	const translate = props.translate
	if (profile !== 0) {
		return <RedirectButton label={translate ('reference.profile') + ':' + profile.name}
		                       url={`/profile/${profile?.id}/show`}/>
	}
	else {
		return translate ('reference.noProfile')
	}
}




export const FridgeEdit = ({ permissions, ...props }) => {
	const translate = useTranslate ()
	return <Edit {...props} title={<FridgeTitle/>}  >
		{permissions === 'admin' && (
	      <TabbedForm redirect="show"  >
	      <FormTab label={translate ("reference.fridge")}>
				<TextInput
					source='serial'
					autoComplete='off'
					validate={[required ()]}
				/>
				<SelectInput choices={GetFridgeGenerationList (translate)} optionText='generation'
				             label={translate ('reference.generation')} source='generation'/>
				<CustomProfileSelectInput translate={translate}/>
				<ReferenceInput
            label={translate("reference.complexe")}
            source="complexeId"
            reference="complexe"
            perPage={perPageInputField}
            filterToQuery={(searchText) => {
						if (searchText)
							return {
								q: {
									$or: [
										{name: {$cont: searchText}},
										{city: {$cont: searchText}},
                      { street: { $cont: searchText } },
                    ],
								},
                  isSearch: true,
                };
					}}
				>
					<AutocompleteInput
              optionText={(record) =>
							record
								? `${record.name} - ${record.city} ${record.street} ${record.streetNumber}`
								: ``
						}
						translateChoice={false}
						validate={[required ()]}
              debounce={debounce}
					/>
				</ReferenceInput>


				<ReferenceInput
					source='fridgeStructureId'
					reference='fridge-structure'
					perPage={1000}

				>
					<SelectInput
						source={'name'}
						translateChoice={false}
						validate={[required ()]}
						helperText={translate('resources.fridge.fields.fridgeStructureId-helper-text')}

					/>
				</ReferenceInput>

				<TextInput source='whatsappLink' autoComplete='off'/>
				<ReferenceInput
					label={translate ('reference.release-channels')}
					source='releaseChannelId'
					reference='release-channels'
					perPage={1000}
					validate={[required ()]}
				>
					<SelectInput optionText='displayName'/>
				</ReferenceInput>
				<ReferenceField
					label={translate ('reference.warehouse')}
					source='warehouseId'
					reference='warehouse'
					link='show'

				>
					<TextField source='name'/>
				</ReferenceField>
				<BooleanInput source='active'/>
				<BooleanInput source='linkedToPrivatePlanogram'  label={translate ('resources.fridge.fields.algoretailLinked')}/>

			</FormTab>
              <FormTab label={translate ("reference.fridge_settings")} >
                <SelectInput source={'fridgeSettings.cameraRotation'} choices={cameraRotation}             optionText="cameraRotation" optionValue="id"
                />
              </FormTab>
        </TabbedForm>
		)}
	</Edit>
}
