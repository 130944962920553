import {
	BulkExportButton,
	Datagrid,
	Filter,
	List,
	TopToolbar,
	SelectArrayInput,
	TextField,
	useTranslate,
	DateTimeInput,
	TextInput,
	FunctionField, ExportButton,
	SelectField, useUnselectAll, useNotify, sanitizeListRestProps
} from "react-admin";
import React, {cloneElement, Fragment, useEffect} from "react";
import {debounce, locales} from "../constants";
import {dateFormat, differenceBOcalaculationOnWeightEvent, getEstimatedAmount} from '../utils.service';
import * as XLSX from "xlsx";
import {Button} from '@material-ui/core';
import {Close, ShoppingCart} from '@material-ui/icons';
import {keyBy} from 'lodash';
import RedirectButton from '../components/RedirectButton';

const WeightEventTopToolbar = ({
	                               currentSort,
	                               className,
	                               resource,
	                               filters,
	                               displayedFilters,
	                               exporter, // you can hide ExportButton if exporter = (null || false)
	                               filterValues,
	                               permanentFilter,
	                               hasCreate, // you can hide CreateButton if hasCreate = false
	                               basePath,
	                               selectedIds,
	                               onUnselectItems,
	                               showFilter,
	                               maxResults,
	                               total,
	                               ...rest
                               }) => {
	const translate = useTranslate ();
	return (

		<TopToolbar className={className} {...sanitizeListRestProps (rest)}>
			{filters &&
				cloneElement (filters, {
					resource,
					showFilter,
					displayedFilters,
					filterValues,
					context: "button"
				})}
			{filters && (
				<Button
					color="primary"
					size="small"
					startIcon={<Close/>}
					onClick={() => rest.setFilters ({})}
				>
					{translate ("general.clearFilter")}
				</Button>
			)}
			<ExportButton
				disabled={total === 0}
				resource={resource}
				sort={currentSort}
				filter={{...filterValues, ...permanentFilter}}
				exporter={() => downloadExcel (Object.values (rest.data), rest.defaultTitle)}
				maxResults={maxResults}
			/>

		</TopToolbar>
	);
};

const WeightEventFilter = (props) => {
	const translate = useTranslate ()
	return (
		<Filter {...props}>
			{props?.fridgeId ? <TextInput
				source="fridge.serial"
				label={translate ("reference.fridge")}
				debounce={debounce}
			/> : <TextInput
				source="fridge.serial"
				alwaysOn
				label={translate ("reference.fridge")}
				debounce={debounce}
			/>}
			<DateTimeInput
				source="timeStamp||$gte"
				label={`resources.order.fields.createdAt_gte`}
				locales={locales}
				alwaysOn

			/>
			<DateTimeInput
				source="timeStamp||$lte"
				label={`resources.order.fields.createdAt_lte`}
				locales={locales}
				alwaysOn
				parse={(x) => {
					return x
				}}

			/>
			<SelectArrayInput choices={props.choices} source="eventType" alwaysOn/>

		</Filter>
	);
};


const EventsGrid = (props) => {
	return (
		<EventsDataGrid record={props.record.weightEventData}/>
	);
}
const EventsDataGrid = (props) => {
	const data = props.record

	const translate = useTranslate ()
	if (!props.record) {
		return ''
	}
	return <Datagrid

		optimized
		data={keyBy (data, "locationId")}
		ids={data.map (({locationId}) => locationId)}
		currentSort={{field: "locationId", order: "ASC"}}
		rowClick=""
		basePath={`/weight-event-data`}
		selectedIds={[]}
		resource={`weight-event`}
	>

		<TextField label={translate ('reference.product')} source="product.name"/>
		<TextField source="row"/>
		<TextField source="column"/>
		<FunctionField label={translate ("general.amountOfProducts")} render={r => {
			return r.amountOfProducts * -1
		}}/>
		<TextField source="tareWeightCurrent" label={translate ("general.tareWeightCurrent")}
		/>
		<FunctionField label={translate ("general.calcBo")} render={r => {
			return (r.tareWeightCurrent<0 ? -1:1)* getEstimatedAmount (r.product, r.tareWeightCurrent, r.offset)
		}}/>
	</Datagrid>
}
const downloadExcel = (x, fileName) => {
	const worksheet = XLSX.utils.json_to_sheet (x);
	const workbook = XLSX.utils.book_new ();
	XLSX.utils.book_append_sheet (workbook, worksheet, "Sheet1");
	XLSX.writeFile (workbook, fileName + ".xlsx");
};
const EventsBulkActionButtons = (props) => {
	const unselectAll = useUnselectAll ();
	const notify = useNotify ();

	useEffect (() => {

		return () => {
			unselectAll ("weight-event");
		};
	}, []);
	let exporter = (events) => {
		let data = []
		const {fridge, eventType, timeStamp, weightEventData} = events[0]
		let fileName = `weightEvent ${fridge?.serial} ${eventType} ${timeStamp}`
		if (events?.length === 1) {
			if (weightEventData) {
				data = weightEventData.map ((x) => {
					const {row, column, productId, product, tareWeightCurrent, offset} = x
					return {
						row,
						column,
						productName: product.name,
						productWeight: product.weight,
						productId,
						offset,
						amountOfProductsBO: getEstimatedAmount (product, tareWeightCurrent, offset),
						weightOnShelf: tareWeightCurrent
					}
				})
			}
			downloadExcel (data, fileName)
		}
		else {
			if (events[0].fridge.serial === events[1].fridge.serial) {
				events.sort (function (a, b) {
					const textA = a.timeStamp
					const textB = b.timeStamp
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
				})


			}
			else {
				notify ('resources.weight-event.warnDifferentSerials', 'warning')
				unselectAll ("weight-event");
				return

			}
			const firstEvents = events[0].weightEventData
			const secondEvents = events[1].weightEventData
			if (firstEvents.length !== secondEvents.length) {
				notify ('resources.weight-event.differentShelves', 'warning')
				unselectAll ("weight-event");
				return
			}
			data = secondEvents.map ((secondEvent) => {
				let changeProduct = 'product changed could not perform calculation old product data '
				const {row, column, productId, amountOfProducts, product, tareWeightCurrent,weightDriftNoOffset ,offset} = secondEvent
				const startEvent = firstEvents?.find ((firstEvent) => (firstEvent.row === row && firstEvent.column === column && productId ===firstEvent.productId))
				if (!startEvent) {
					const oldEvent = firstEvents.find ((firstEvent) => firstEvent.row === secondEvent.row &&firstEvent.column === secondEvent.column)
					const {product, row, column, amountOfProducts, productId} = oldEvent
					changeProduct += JSON.stringify ({
						                                 row,
						                                 column,
						                                 amountOfProducts,
						                                 productId,
						                                 productName: product?.name || '-'
					                                 })

				}
				const {differenceFromPreviousEventBO}= differenceBOcalaculationOnWeightEvent({weightDataStart:startEvent,weightDataEnd:secondEvent,product:secondEvent.product})
				return {
					row,
					column,
					productId,
					productName: product.name,
					startWeight: startEvent?.tareWeightCurrent,
					endWeight: tareWeightCurrent,
					startWeightNoDrift:startEvent.weightDriftNoOffset,
					endWeightNoDrift:secondEvent.weightDriftNoOffset,
					productWeight: product.weight,
					offset,
					differenceFromPreviousEvent: -1 * amountOfProducts,
					differenceFromPreviousEventBO:-1*differenceFromPreviousEventBO,
					differenceCalculationBasedOn:'tareWeightCurrent',
					amountOfProductsBO: getEstimatedAmount (product, (weightDriftNoOffset===0?tareWeightCurrent:weightDriftNoOffset), offset),
					note: !startEvent ? changeProduct : ''
				}
			})
			fileName = `weightEvent ${fridge?.serial} difference ${events[1].eventType} ${events[1].timeStamp} to ${events[0].eventType} ${events[0].timeStamp}`
			downloadExcel (data, fileName)


		}
	};

	const label = 'resources.weight-event.' + (props.selectedIds.length > 1 ? 'difference' : 'inventory')
	if (props.selectedIds.length > 2) {
		notify ('resources.weight-event.oneOrTwo', 'warning')
	}
	return <Fragment>
		<BulkExportButton label={props.translate (label)} exporter={exporter}/>
	</Fragment>
};

export const WeightEventList = ({permissions, ...props}) => {
	let filter = {
		"fridgeId||$eq": parseInt (props?.fridgeId, 10),
		join: ["fridge||id,serial", 'weightEventData||row,column,productId,amountOfProducts,product,tareWeightCurrent,offset,locationId']
	}
	if (props?.orderId) {
		filter["orderId||$eq"] = props.orderId
	}
	if (!props.fridgeId) {
		filter = {join: ["fridge||id,serial", 'weightEventData||row,column,productId,amountOfProducts,product,tareWeightCurrent,offset,locationId']}
	}
	const translate = useTranslate ();
	const choices = [
		{id: 'START_PURCHASE', name: translate (`resources.weight-event.events.startPurchase`)},
		{id: 'END_PURCHASE', name: translate (`resources.weight-event.events.endPurchase`)},
		{id: 'FILLING_END', name: translate (`resources.weight-event.events.endFilling`)},
		{id: 'FILLING_START', name: translate (`resources.weight-event.events.startFilling`)},
		{id: 'TECHNICIAN', name: translate (`resources.weight-event.events.technician`)},
		{id: 'BOOT_UP', name: translate (`resources.weight-event.events.bootUp`)}]

	return (
		<List
			{...props}
			sort={{field: "timeStamp", order: "DESC"}}
			filters={<WeightEventFilter choices={choices} fridgeId={props?.fridgeId} translate={translate}/>}
			actions={<WeightEventTopToolbar/>}
			filter={filter}
			bulkActionButtons={<EventsBulkActionButtons translate={translate}/>}


		>
			<Datagrid expandSingle expand={<EventsGrid/>} optimized>

				<TextField source="fridge.serial" label={translate ('general.serial')}/>
				<SelectField source="eventType" choices={choices}/>
				<FunctionField
					label={`resources.weight-event.fields.timeStamp`}
					render={(record) => {
						if (record?.timeStamp) {
							if (record?.timeStamp) {
								return dateFormat (record?.timeStamp);
							}


						}
						else {
							return " ";
						}
					}}
				/>
				<FunctionField
					label={'reference.order'}
					render={(record) => {
						if (record?.order?.id) {
							return <RedirectButton  url={`/order/${record?.order?.id}/show`}

							                       icon={<ShoppingCart/>}

							/>

						}else {
							return null
					}

					}}

				></FunctionField>


			</Datagrid>

		</List>
	);
}

