import {
	Datagrid,
	DateField,
	FunctionField,
	List,
	ReferenceField,
	TextField,
	useTranslate
} from "react-admin";
import React from "react";
const {locales} = require ('../constants')



const FridgeStructureList = ({permissions, ...props}) => {
	const translate = useTranslate ()
	return (
		<List
			{...props}
			// filters={<FridgeFilter/>}
			bulkActionButtons={false}
			filter={{
			}}
		>
			<Datagrid rowClick='show'>
				<ReferenceField
					label={translate ('reference.fridgeStructure')}
					source='id'
					reference='fridge-structure'
					link='show'
				>
					<TextField source='name'/>
				</ReferenceField>


				<FunctionField
					label={translate ('general.shelves')}
					render={record => {
						return record ? (
							<span>
                {
	                record?.shelves?.length
                }
              </span>
						) : null
					}}
				/>
				<DateField showTime={true}
				           label={translate ('general.createdAt')}

				           options={{
					           year: '2-digit',
					           month: '2-digit',
					           day: '2-digit',
					           hour: '2-digit',
					           minute: '2-digit',
				           }}
				           source='createdAt'
				           locales={locales}
				>


				</DateField>
			</Datagrid>
		</List>
	)
}

export default FridgeStructureList
