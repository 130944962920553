import FridgeStructureCreate from './FridgeStructureCreate'
import FridgeStructureList from './FridgeStructureList'
import FridgeStructureShow from './FridgeStructureShow'
import TableChartIcon from '@material-ui/icons/TableChart';


export default {
	list: FridgeStructureList,
	create: FridgeStructureCreate,
	show: FridgeStructureShow,
	icon: TableChartIcon
}
