import * as XLSX from 'xlsx';

export function dateFormat (timeStampStart) {
	if (!timeStampStart) {
		return ''
	}
	let newTimeStamp = timeStampStart?.replace ("Z", "");
	let dateFormat = new Date (newTimeStamp);
	let d = dateFormat.getDate ();
	let m = dateFormat.getMonth () + 1;
	const y = dateFormat.getFullYear ();
	let hh = dateFormat.getHours ();
	let mm = dateFormat.getMinutes ();
	d = d < 10 ? "0" + d : d;
	m = m < 10 ? "0" + m : m;
	mm = mm < 10 ? "0" + mm : mm;
	hh = hh < 10 ? "0" + hh : hh;

	return +hh + ":" + mm + " " + d + "/" + m + "/" + y;
}

export function passwordValidation (value, edit = false) {
	if (edit && !value) {
		return undefined;
	}
	const upperCaseLetters = /[A-Z]/g;
	const lowerCaseLetters = /[a-z]/g;
	const numbers = /[0-9]/g;
	let count = "Password should contain At least  \n";
	let msg = "Password should contain At least  \n";
	if (!value?.match (numbers)) {
		msg += ` number  \n`;
	}
	if (!value?.match (upperCaseLetters)) {
		msg += " 1 [A-Z] letter  \n";
	}

	if (!value?.match (lowerCaseLetters)) {
		msg += " 1 [a-z] letter  \n";
	}
	if (value?.length < 8) {
		msg += " 6 characters  \n";
	}
	if (msg === count) {
		return undefined;
	}
	return msg;
}

export const downloadExcel = (x, fileName) => {
	const worksheet = XLSX.utils.json_to_sheet (x);
	const workbook = XLSX.utils.book_new ();
	XLSX.utils.book_append_sheet (workbook, worksheet, "Sheet1");
	XLSX.writeFile (workbook, fileName + ".xlsx");
};

export const getEstimatedAmount = (product, weight, weightOffset) => {
	if (!product || !weightOffset || !weight) {
		return null
	}
	const std = product?.StandertDeviatio || 1
	const minimumProductWight = (1 - std / 100) * product.weight
	const normalizedWeight = weightOffset * Math.abs (weight)
	let expectedProductAmount = Math.round (normalizedWeight / product.weight)
	let predictedAvgProductWeight = expectedProductAmount !== 0 ? normalizedWeight / expectedProductAmount : normalizedWeight
	if (((Math.abs (predictedAvgProductWeight) % product.weight)) >= minimumProductWight) {
		return expectedProductAmount
	}
	else {
		return Math.floor (normalizedWeight / product.weight)

	}
}

export const differenceBOcalaculationOnWeightEvent = (row) => {
	if (!row) {
		return 'null'
	}
	let amount = 0
	let mark = 0
	const startEvent = row.weightDataStart
	const endEvent = row.weightDataEnd
	let deltaWeight = 0
	if (isNaN (startEvent.tareWeightCurrent) || isNaN (endEvent.tareWeightCurrent)) {
		return 'null'
	}
	deltaWeight = endEvent.tareWeightCurrent - startEvent.tareWeightCurrent
	amount = getEstimatedAmount (row.product, deltaWeight, row.weightDataEnd.offset)
	return {differenceFromPreviousEventBO: (deltaWeight > 0 ? -1 : 1) * amount, mark}

}
