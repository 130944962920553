import React, { cloneElement } from "react";
import {
  CreateButton,
  ExportButton,
  sanitizeListRestProps,
  TopToolbar,
  usePermissions,
  useTranslate,
} from "react-admin";
import { Close } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import {downloadExcel} from '../utils.service';

const GenericTopToolbar = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  const { permissions } = usePermissions();
  const translate = useTranslate();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {filters && (
        <Button
          color="primary"
          size="small"
          startIcon={<Close />}
          onClick={() => rest.setFilters({})}
        >
          {translate("general.clearFilter")}
        </Button>
      )}
      {(permissions === "admin" && hasCreate)&& <CreateButton basePath={basePath} />}
      {permissions === "admin" && (
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          exporter={()=>downloadExcel(Object.values(rest.data),rest.defaultTitle)}
          maxResults={maxResults}
        />
      )}
    </TopToolbar>
  );
};

export default GenericTopToolbar;
