import React, {Fragment, useEffect, useState} from 'react'
import {url} from '../config/connection'
import {
	ArrayInput,
	AutocompleteInput,
	DateInput,
	Error,
	FormDataConsumer,
	LinearProgress,
	minValue,
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	useDataProvider,
	useTranslate
} from 'react-admin'
import CreateToolBarWithCustomSave from '../components/CreateToolBarWithCustomSave'
import CustomPage from '../components/CustomPage'
import {useForm} from 'react-final-form'
import {cloneDeep, find, map, omit, remove, uniqBy} from 'lodash'
import httpClient from '../DataProvider/httpClient'
import {debounce, perPageInputField} from '../constants';

const {locales} = require ('../constants')

/**
 * Form variables map
 *    transactionTypeId : number
 *    warehouseFromId   : number
 *    warehouseToId     : number
 *    expirationTimes   : array  - array of all available expiration dates in 'FromWarehouse'
 *    warehouseInv      : array  - array of all inventory with warehouseId = 'FromWarehouse'', null if fromWarehouse is
 * fridge
 */

const unlockProductInput = (form) => {
  const formValues = form.getState().values;
	if (!formValues.staticLocation) {
		const items = formValues.items
      ? formValues.items.map((item) => omit(item, ["locationId"]))
      : [];
    form.change("items", cloneDeep(items));
    form.change("staticLocation", false);
    form.change("warehouseFromId", null);
    form.change("warehouseToId", null);
	}
};

const fillExpirationTimes = (dataProvider, form, warehouseId) => {
	const requestUrl = `${url}/inventory/byWarehouse/${warehouseId}`
	const options = {method: 'GET'}
	dataProvider
    .getOne("warehouse", { id: warehouseId })
		.then (({data}) => {
			if (data) {
				httpClient (requestUrl, options)
          .then((response) => {
            form.change("warehouseInv", response.json);
          })
          .catch((err) => {
            console.log(err);
            form.change("warehouseInv", null);
          });
      } else {
        form.change("warehouseInv", null);
      }
    })
    .catch((err) => {
      console.log(err);
      form.change("warehouseInv", null);
    })
    .finally(() => {
      form.change("hasFromWarehouse", true);
      form.change("expirationTimes", null);
      console.log("clear expirationTimes  - fillExpirationTimes");
    });
};

/**
 * React component that controls visibility of two warehouse select inputs according to 'typeId'
 * @param {object} props
 */
const WarehouseInputs = (props) => {
  const translate = useTranslate();
  const form = useForm();
  const dataProvider = useDataProvider();
  const [transactionType, setTransactionType] = useState({fromWarehouse:false});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  useEffect(() => {
      get()
  }, [props.typeId]);

  const get=()=>{
    dataProvider
        .getOne("transaction-type", { id: props.typeId })
        .then(({ data }) => {
          const values = form.getState().values;
          form.change("hasFromWarehouse", data.hasFromWarehouse || null);
          form.change("hasToWarehouse", data.hasToWarehouse || null);
          if (!values.staticLocation) {
            form.change("expirationTimes", null);
            unlockProductInput(form);
          }
          setTransactionType(data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
  }
  if (loading) return <LinearProgress />;
  if (error) return <Error error={error} />;
  if (!transactionType) return null;

  return (
    <Fragment>
      {transactionType.hasFromWarehouse ? (
        !transactionType.fromAllowFridge ? (
          <ReferenceInput
            fullWidth
            label={translate("general.warehouseFrom")}
            source="warehouseFromId"
            validate={[required()]}
            perPage={perPageInputField}
            filter={{ isFridge: 0 }}
            sort={{ field: "name", order: "ASC" }}
            onChange={(e) => {
              fillExpirationTimes(dataProvider, form, e.target.value);
            }}
            reference="warehouse"
          >
            <SelectInput optionText="name" validate={[required()]} />
          </ReferenceInput>
        ) : (
          <ReferenceInput
            fullWidth
            label={translate("general.warehouseFrom")}
            validate={[required()]}
            onChange={(e) => {
              fillExpirationTimes(dataProvider, form, e.target.value);
            }}
            sort={{ field: "name", order: "ASC" }}
            perPage={perPageInputField}
            source="warehouseFromId"
            reference="warehouse"
          >
            <SelectInput optionText="name" validate={[required()]} />
          </ReferenceInput>
        )
      ) : null}
      {transactionType.hasToWarehouse ? (
        !transactionType.toAllowFridge ? (
          <ReferenceInput
            fullWidth
            label={translate("general.warehouseTo")}
            source="warehouseToId"
            filter={{ isFridge: 0 }}
            sort={{ field: "name", order: "ASC" }}
            validate={[required()]}
            reference="warehouse"
            perPage={perPageInputField}
          >
            <SelectInput optionText="name" validate={[required()]} />
          </ReferenceInput>
        ) : (
          <ReferenceInput
            fullWidth
            label={translate("general.warehouseTo")}
            validate={[required()]}
            sort={{ field: "name", order: "ASC" }}
            source="warehouseToId"
            perPage={perPageInputField}
            reference="warehouse"
          >
            <SelectInput optionText="name" validate={[required()]} />
          </ReferenceInput>
        )
      ) : null}
    </Fragment>
  );
};

/**
 *
 * @param {object} props
 */
const LocationInputs = (props) => {
  const { source, getSource } = props;
  let { warehouseFromId, warehouseToId } = props;
  const translate = useTranslate();
  const form = useForm();
  const state = form.getState();
  const values = state.values;
  const [warehouseId, setWarehouseId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState(null);
  const [error, setError] = useState();
  const [isChange, setIsChange] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  warehouseToId = warehouseToId || 0;
  warehouseFromId = warehouseFromId || 0;
  useEffect(() => {
    const requestUrl = `${url}/fridge/getLocationsForTransaction/${warehouseFromId},${warehouseToId}`;
    const options = { method: "GET" };
    httpClient(requestUrl, options)
      .then((response) => {
        const { fridges, locations } = response.json;
        if (fridges.length > 1) {

					setErrorMsg (translate ('error.transaction.twoFridges'))
					setWarehouseId (null)
					setLocations (null)
				}
				else if (fridges.length === 1) {
					const changed = !warehouseId || warehouseId !== fridges[0].warehouseId
					setIsChange (changed)
					setWarehouseId (fridges[0].warehouseId)
					setLocations (locations)
					setErrorMsg (null)
				}
				else {
					setIsChange (true)
					setWarehouseId (null)
					setLocations (null)
					setErrorMsg (null)
        }
        if (isChange && !props.staticLocation) {
          if (values && values.items) {
            const items = values.items.map((item) => omit(item, ["locationId"]));
            form.change("items", cloneDeep(items));
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setLoading(false);
      });
  }, [warehouseFromId, warehouseToId]);

  if (loading) return <LinearProgress />;
  if (error) return <Error error={error} />;

  return (
    <Fragment>
      {warehouseId && locations ? (
        <SelectInput
          fullWidth
          onChange={(e) => {
            const location = find(locations, { id: e.target.value });
            form.change(getSource("productId"), location.productId);
            if (location.expirationTimes && values.warehouseFromId === warehouseId) {
              form.change(getSource("expirationTimes"), location.expirationTimes);
              console.log("change expirationTimes  - LocationInputs");
              form.change("warehouseInv", null);
            }
            form.change(getSource("locationId"), e.target.value);
          }}
          choices={locations}
          label={translate("general.rowandcolumn")}
          source={source}
          validate={[required()]}
          optionText={(record) => {
            return `${translate("general.row")} ${record.row}, ${translate("general.column")} ${
              record.column
            } \t | \t ${record.product.name} \t | \t  ${record.standard_quantity} / ${
              record.quantity
            }`;
          }}
        />
      ) : errorMsg ? (
        <h2 style={{ color: "red" }}>{errorMsg}</h2>
      ) : null}
    </Fragment>
  );
};

const removeCreatedTransactions = function removeCreatedTransactions(form, successfullyCreated) {
  const state = form.getState();
  const values = state.values;
  const items = values.items;
  remove(items, (item) =>
    find(successfullyCreated, {
      data: {
        productId: item.productId,
        quantity: item.quantity,
        expiration_time: item.expiration_time,
      },
    })
  );
  form.change("items", cloneDeep(items));
};

const createRecord = function createRecord({ type, items }) {
  const transactionType =   localStorage.getItem("transferTransactionType");
  return new Promise(async (resolve) => {
    const warehouseId = items[0].warehouseId;
    switch (type) {
      case "fridgeStatus":
        const fridgeStatusRequestUrl = `${url}/fridge/getLocationsAndQuantity/${warehouseId}`;
        const fridgeStatusOptions = { method: "GET" };
        httpClient(fridgeStatusRequestUrl, fridgeStatusOptions)
          .then((response) => {
            const locationsAndQuantity = response.json;
            const productIds = map(items, "productId");
            const relevantItems = locationsAndQuantity.filter(
              (locationAndQuantity) =>
                productIds.includes(locationAndQuantity.productId) &&
                locationAndQuantity.quantity > 0
            );
            resolve({
              transactionTypeId: transactionType,
              warehouseToId: warehouseId,
              staticLocation: true,
              items: relevantItems,
            });
          })
          .catch((error) => {
            console.log(error);
          });
        break;
      case "bulk":
        resolve({
          transactionTypeId: transactionType,
          warehouseFromId: warehouseId,
          hasFromWarehouse: true,
          staticLocation: true,
          items: items.map((item) => ({
            locationId: item.locationId || null,
            productId: item.productId,
            expiration_time: item.expiration_time || null,
          })),
        });
        break;
      case "minus":
        const requestUrl = `${url}/fridge/getLocationsForTransaction/${warehouseId},0`;
        const options = { method: "GET" };
        const response = await httpClient(requestUrl, options);
        const { locations } = response.json;
        resolve({
          transactionTypeId: transactionType,
          warehouseFromId: warehouseId,
          hasFromWarehouse: true,
          staticLocation: true,
          items: items.map((item) => {
            const location = find(locations, { id: item.locationId });
            return {
              locationId: item.locationId || null,
              productId: item.productId,
              expiration_time: item.expiration_time || null,
              expirationTimes: location ? location.expirationTimes : null,
            };
          }),
        });
        break;
      case "plus":
        resolve({
          transactionTypeId: transactionType,
          warehouseToId: warehouseId,
          staticLocation: true,
          items: items.map((item) => ({
            locationId: item.locationId || null,
            productId: item.productId,
            expiration_time: item.expiration_time || null,
          })),
        });
        break;
      default:
    }
  });
};

const TransactionCreate = (props) => {
  const [record, setRecord] = useState({});
  const [recordSet, setRecordSet] = useState(false);
  const {
    match: {
      params: { data },
    },
  } = props;
  let warehouseId;
  let type;
  if (data && !recordSet) {
    const jsonData = JSON.parse(data);
    warehouseId = jsonData.items[0].warehouseId;
    type = jsonData.type;
    createRecord(jsonData).then((newRecord) => {
      setRecord(newRecord);
      setRecordSet(true);
    });
  }
  const translate = useTranslate();
  const orderTransactionType = window.orderTransactionType;
  return (
    <CustomPage title={translate("title.transactionCreate")}>
      <SimpleForm
        toolbar={
          <CreateToolBarWithCustomSave
            saveurl="/transaction/bulkCreate"
            successmsg="summary.transaction.successMsg"
            title={translate("summary.transaction.title")}
            goto={
              warehouseId
                ? type === "bulk"
                  ? `/warehouse/${JSON.parse(warehouseId)}/show`
                  : `/location/${JSON.parse(warehouseId)}/show`
                : "/transaction"
            }
            callback={removeCreatedTransactions}
          />
        }
        record={record}
       onSubmit={''}>
        <ReferenceInput
          fullWidth
          filter={{ "id||$ne": orderTransactionType }}
          label={translate("reference.transaction-type")}
					validate={[required ()]}
          source="transactionTypeId"
          perPage={perPageInputField}
          reference="transaction-type"
        >
          <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.transactionTypeId && (
              <WarehouseInputs typeId={formData.transactionTypeId} {...rest} />
            )
          }
        </FormDataConsumer>
        <ArrayInput label={translate("general.items")} source="items">
          <SimpleFormIterator fullWidth>
            <FormDataConsumer>
              {({ formData, scopedFormData, getSource, ...rest }) =>
                (formData.warehouseFromId || formData.warehouseToId) && (
                  <LocationInputs
                    warehouseFromId={formData.warehouseFromId}
                    warehouseToId={formData.warehouseToId}
                    staticLocation={formData.staticLocation || null}
                    source={getSource("locationId")}
                    getSource={getSource}
                    {...rest}
                  />
                )
              }
            </FormDataConsumer>
            <FormDataConsumer>
              {({ scopedFormData, getSource }) =>
                scopedFormData && scopedFormData.locationId ? (
                  <ReferenceInput
                    fullWidth
                    label={translate("reference.product")}
                    source={getSource("productId")}
                    reference="product"
                    disabled
                    perPage={perPageInputField}
                  >
                    <SelectInput disabled />
                  </ReferenceInput>
                ) : (
                  <ReferenceInput
                    fullWidth
                    validate={[required()]}
                    label={translate("reference.product")}
                    source={getSource("productId")}
                    reference="product"
                    filter={{ "active||$eq": true }}
                    filterToQuery={(searchText) => {
                      if (searchText) return { name: searchText };
                    }}
                  >
                    <AutocompleteInput debounce={debounce} />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
            <NumberInput
              fullWidth
              validate={[required(), minValue(1)]}
              label={translate("general.quantity")}
              source="quantity"
            />
            <FormDataConsumer>
              {({ formData, scopedFormData, getSource }) => {
                return scopedFormData &&
                  scopedFormData.expirationTimes &&
                  // scopedFormData.productId &&
                  // scopedFormData.locationId &&
                  formData.hasFromWarehouse ? (
                  <SelectInput
                    fullWidth
                    validate={[required()]}
                    label={translate("general.expiration_time")}
                    source={getSource("expiration_time")}
                    choices={scopedFormData.expirationTimes.map((expirationTime) => ({
                      id: expirationTime.expiration_time,
                      name: `${new Date(expirationTime.expiration_time).toLocaleDateString(
                        locales
                      )} - ${expirationTime.quantity}`,
                    }))}
                  />
                ) : scopedFormData &&
                  formData.hasFromWarehouse &&
                  formData.warehouseInv &&
                  scopedFormData.productId ? (
                  <SelectInput
                    fullWidth
                    validate={[required()]}
                    label={translate("general.expiration_time")}
                    source={getSource("expiration_time")}
                    choices={uniqBy(
                      formData.warehouseInv
                        .filter((inv) => inv.productId === scopedFormData.productId)
                        .map((obj) => ({
                          id: obj.expiration_time,
                          name: `${new Date(obj.expiration_time).toLocaleDateString(locales)} - ${
                            obj.quantity
                          }`,
                        })),
                      "id"
                    )}
                  />
                ) : (
                  <DateInput
                    fullWidth
                    validate={[required()]}
                    locales={locales}
                    label={translate("general.expiration_time")}
                    source={getSource("expiration_time")}
                  />
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </CustomPage>
  );
};

export default TransactionCreate;
