import React, {Fragment} from "react";
import {
	BooleanField,
	Datagrid,
	DateField,
	FunctionField,
	List,
	ReferenceField,
	ReferenceManyField,
	SelectField,
	Show,
	Tab,
	TabbedShowLayout,
	TextField,
	UrlField,
	useShowController,
	useTranslate
} from "react-admin";
import {cameraRotation, locales} from "../constants";
import fridgeLogs from "../FridgeLogs";
import network from "../NetworkHistory";
import versionChanges from "./VersionChanges/VersionChanges";
import inventory from "../Inventories";
import GenerateFridgeTokenButton from "../components/GenerateFridgeTokenButton";
import {FridgeTitle, GetFridgeGenerationList} from "./index";
import PlanogramShow from "../Planogram/PlanogramShow";
import {dateFormat} from '../utils.service';
import {WeightEventList} from '../WeightEvent/WeightEventList';
import Orders from '../Orders';


const FridgeTokenList = (props) => {
	const {
		match: {
			params: {fridgeId}
		}
	} = props;
	const translate = useTranslate ();
	return (
		<Fragment>
			<List
				title=" "
				hasCreate={false}
				hasEdit={false}
				hasList={true}
				hasShow={false}

				sort={{field: "createdAt", order: "DESC"}}
				basePath={`fridge/fridge-token`}
				resource={`fridge/fridge-token`}
				filter={{"fridgeId||$eq": fridgeId}}
			>
				<Datagrid
					optimized
					rowClick=""
					resource={`fridge/fridge-token`}
					basePath={`fridge/fridge-token`}
				>
					<DateField
						label={translate ("general.createdAt")}
						showTime={true}
						options={{
							year: "numeric",
							month: "numeric",
							day: "numeric",
							hour: "2-digit",
							minute: "2-digit",
							second: "2-digit"
						}}
						source="createdAt"
						locales={locales}
					/>
					<DateField
						label={translate ("general.lastUse")}
						showTime={true}
						options={{
							year: "numeric",
							month: "numeric",
							day: "numeric",
							hour: "2-digit",
							minute: "2-digit",
							second: "2-digit"
						}}
						source="lastUse"
						locales={locales}
					/>
				</Datagrid>
			</List>
		</Fragment>
	);
};


export const FridgeShow = ({permissions, ...props}) => {
	const {
		match: {
			params: {id}
		}
	} = props;
	const {
		record // record fetched via dataProvider.getOne() based on the id from the location
	} = useShowController (props);
	const translate = useTranslate ();
	return <Show {...props} title={<FridgeTitle/>} hasEdit={permissions === "admin"}
	>
		<TabbedShowLayout>
			<Tab label={translate ("general.summary")}>
				<TextField source="serial"/>
				{translate ("reference.generation")}
				<FunctionField
					label={translate ("reference.generation")}
					render={(record) => {
						const data = GetFridgeGenerationList (translate);
						const item = data.find ((i) => i.id === record.generation);
						return item?.generation;
					}}
				/>
				<ReferenceField
					label={translate ("reference.warehouse")}
					source="warehouseId"
					reference="warehouse"
					link="show"
				>
					<TextField source="name"/>
				</ReferenceField>
				<ReferenceField
					label={translate ('reference.release-channels')}
					source="releaseChannelId"
					reference="release-channels"
					link="show"
				>
					<TextField source="displayName"/>
				</ReferenceField>
				<UrlField source="whatsappLink"/>
				<DateField
					showTime={true}
					options={{
						year: "numeric",
						month: "numeric",
						day: "numeric",
						hour: "2-digit",
						minute: "2-digit",
						second: "2-digit"
					}}
					source="lastPing"
					locales={locales}
				/>
				<TextField source="firmwareVersion"/>
				<TextField source="hardwareCardVersion"/>
				<TextField source="apkVersion"/>
				<ReferenceField
					label={translate ("reference.complexe")}
					source="complexeId"
					reference="complexe"
					link="show"
				>

					<TextField source="name"/>
				</ReferenceField>

				<ReferenceField
					label={translate ('reference.fridgeStructure')}
					source="fridgeStructureId"
					reference="fridge-structure"
					link="show"
				>
					<TextField source="name"/>
				</ReferenceField>
				<ReferenceField
					label={translate ('reference.release-channels')}
					source="releaseChannelId"
					reference="release-channels"
					link="show"
				>
					<TextField source="displayName"/>
				</ReferenceField>
				{permissions !== 'customerSupport' && (
					<FunctionField
						label={translate ('reference.profile')}
						render={record => {
							if (record?.profile?.profileId) {
								return (
									<ReferenceField reference={'profile'} link={'show'} source={'profile.profileId'}>
										<TextField source={'name'}/>
									</ReferenceField>

								)
							}
						}}
					/>
				)}
				<TextField source="otherId"/>
				<BooleanField source="active"/>

				<BooleanField source="linkedToPrivatePlanogram"
				              label={translate ('resources.fridge.fields.algoretailLinked')}/>
				<FunctionField source="fillDate"
				               label={translate ("reference.fillDate")}
				               render={(record) => {
					               if (record?.fillDate) {
						               return dateFormat (record.fillDate)
					               }
					               else {
						               return " ";
					               }
				               }}
				/>
				<FunctionField
					label={translate ("resources.fridge.fields.lastAlert")}
					render={(record) => {
						const id = record.errorCode;
						if (id > 0) {
							return translate (`resources.fridge.errorCode.error${id}`);
						}
						else {
							return "";
						}
					}}
				/>
			</Tab>
			<Tab label={translate ("reference.fridge_settings")}>
				<SelectField source="fridgeSettings.cameraRotation" choices={cameraRotation}
				             optionText={'cameraRotation'} optionValue={'id'}/>
			</Tab>
			{permissions === 'admin' && (
				<Tab label={translate ('general.logs')} path="logs">
					<fridgeLogs.FridgeLogsList
						disableSyncWithLocation
						match={{
							isExact: true,
							params: {fridgeId: id},
							path: "/fridge/:fridgeId/logs",
							url: `/fridge/${id}/show/logs`
						}}
						location={{
							hash: "",
							pathname: `/fridge/${id}/show/logs`,
							search: "",
							state: null
						}}
					/>
				</Tab>
			)}
			<Tab label={translate ('general.network')} path="network-history">
				<network.NetworkHistoryList
					disableSyncWithLocation
					match={{
						isExact: true,
						params: {fridgeId: id},
						path: "/fridge/:fridgeId/network-history",
						url: `/fridge/${id}/show/network-history`
					}}
					location={{
						hash: "",
						pathname: `/fridge/${id}/show/network-history`,
						search: "",
						state: null
					}}
				/>
			</Tab>
			<Tab label={translate ('general.version-changes')} path="version-changes">
				<versionChanges.VersionChangesList
					disableSyncWithLocation
					match={{
						isExact: true,
						params: {fridgeId: id},
						path: "/fridge/:fridgeId/version-changes",
						url: `/fridge/${id}/show/version-changes`
					}}
					location={{
						hash: "",
						pathname: `/fridge/${id}/show/version-changes`,
						search: "",
						state: null
					}}
				/>
			</Tab>
			{permissions === "admin" && (
				<Tab
					label={translate ("general.generateFridgeToken")}
					path="fridge-token"
				>
					<GenerateFridgeTokenButton {...props} />
					<FridgeTokenList
						disableSyncWithLocation
						match={{
							isExact: true,
							params: {fridgeId: id},
							path: "/fridge/:fridgeId/fridge-token",
							url: `/fridge/${id}/show/fridge-token`
						}}
						location={{
							hash: "",
							pathname: `/fridge/${id}/show/fridge-token`,
							search: "",
							state: null
						}}
					/>
				</Tab>
			)} {permissions === "admin" && (
			<Tab label={'resources.weight-event.name'} path={"weight-event"}>
				<FunctionField
					addLabel={false}
					render={() => {
						return (
							<ReferenceManyField reference="weight-event" target="fridgeId" label={false}>
								<WeightEventList
									disableSyncWithLocation
									basePath={`weight-event`}
									resource={`weight-event`}
									filter={{"fridgeId||$eq": id}}
									fridgeId={id}


								/>
							</ReferenceManyField>)
					}}
				/>


			</Tab>
		)}
			<Tab label={translate ("reference.orders")} path="orders">
				<ReferenceManyField reference="order" target="fridgeId" addLabel={false}>
					<Orders.list {...props}

					             disableSyncWithLocation
					             basePath={`order`}
					             resource={`order`}
					             filter={{"fridgeId||$eq": id}}
					             fridgeId={id}
					/>
				</ReferenceManyField>
			</Tab>
			{(permissions === 'admin' && record?.privatePlanogramId && record.linkedToPrivatePlanogram) && (
				<Tab
					label={translate ('general.desiredPlanogram')}
					path="desiredPlanogram"

				>
					<PlanogramShow
						match={{
							isExact: true,
							params: {id: record?.privatePlanogramId},
							path: `/planogram/${record?.privatePlanogramId}show`,
							url: `/planogram/${record?.privatePlanogramId}show`
						}}/>
				</Tab>
			)}

			{(permissions === 'admin' && record?.profile?.planogramId && !record.linkedToPrivatePlanogram) && (
				<Tab
					label={translate ('general.desiredPlanogram')}
					path="desiredPlanogram"

				>
					<PlanogramShow
						match={{
							isExact: true,
							params: {id: record?.profile?.planogramId},
							path: `/planogram/${record?.profile?.planogramId}show`,
							url: `/planogram/${record?.profile?.planogramId}show`
						}}/>
				</Tab>
			)}
			{permissions === 'admin' && (
				<Tab
					label={translate ('general.currentPlanogram')}
					path="currentPlanogram"
				>
					<PlanogramShow match={{
						isExact: true,
						params: {id: record?.currentPlanogramId},
						path: `/planogram/${record?.currentPlanogramId}/show`,
						url: `/planogram/${record?.currentPlanogramId}/show`
					}}/>
				</Tab>
			)}
			<Tab label={translate ('general.totalInventory')} path="products">
				<inventory.ProductsInventoryDatagrid
					match={{
						isExact: true,
						params: {warehouseId: record?.warehouseId},
						path: '/inventory',
						url: `/warehouse/${record?.warehouseId}/show`
					}}
				/>
			</Tab>

		</TabbedShowLayout>
	</Show>

}
