import React, {cloneElement} from "react";
import {
	AutocompleteInput, BooleanInput,
	Datagrid, SimpleForm,
	ExportButton, FunctionField,
	Filter,
	Labeled,
	List, FormDataConsumer,
	ReferenceField,
	ReferenceInput, SaveButton, PasswordInput,
	ReferenceManyField,
	required,
	sanitizeListRestProps,
	SelectInput,
	Show,
	Tab,
	TabbedShowLayout,
	TextField,
	TextInput,
	TopToolbar,
	usePermissions,
	useTranslate, BooleanField, Toolbar, useGetOne, Loading, Edit, useShowController, ListGuesser, DateField
} from "react-admin";
import {Close, People} from "@material-ui/icons";
import {Button} from "@material-ui/core";
import WhatsAppField from "../components/WhatsappField";
import MemberTypeField from "../components/MemberTypeField";
import {debounce} from '../constants';
import {downloadExcel} from '../utils.service';
import Orders from '../Orders';
import QRcode from '../components/QRcode';
import fridgeEvent from '../FridgeEvent/FridgeEvent';


const MemberTitle = ({record}) => <span>{record.fullName}</span>;
const EditToolBar = props => (
	<Toolbar {...props}>
		<SaveButton type="button"/>
	</Toolbar>
);
const MemberEdit = props => {
	const translate = useTranslate ()
	const {permissions} = usePermissions ()
	const sortAlphabets = (text) => {
		return text.split ('').sort ().join ('');
	};
	const validateTechPassword = (value) => {
		const numbers = /[0-9]/g;
		const upperCaseLetters = /[A-Z]/g;
		const lowerCaseLetters = /[a-z]/g;
		let msg = "Password minimum length is 6 and should contain Only numbers   \n";
		if (value?.match (upperCaseLetters) || value?.match (lowerCaseLetters)) {
			return msg
		}
		if (!value?.match (numbers)) {
			return msg
		}
		if (value?.length < 6) {
			return msg
		}
		if (value?.length >= 6) {
			if (value === sortAlphabets (value)) {
				return "Numbers cannot be in ascending or descending order"
			}
		}
		else {
			return undefined
		}
	}
	let {data} = useGetOne (props.resource, props.id);
	if (data) {
		delete data.technicianPassword
		return permissions === 'admin' ? (
			<Edit  {...props} title={<MemberTitle/>}>
			<SimpleForm redirect="show" toolbar={<EditToolBar/>}>
				<TextField source="fullName"/>
				<Labeled label={translate ("general.memberType")}>
					<MemberTypeField source="type"/>
				</Labeled>
				<TextField source="phoneNumber"/>
				<TextField source="phoneNumber2"/>
				<TextField source="city" validate={required ()}/>
				<TextField source="street"/>
				<TextField source="streetNumber"/>

				<ReferenceField
					label={translate ("reference.complexe")}
					source="complexeId"
					reference="complexe"
					link="show"
				>
					<TextField source="name"/>
				</ReferenceField>
				<TextField source="otherId"/>
				<BooleanInput source="technician"/>
				<FormDataConsumer>
					{({formData}) => {
						return formData?.technician && formData.active ?
							<PasswordInput initiallyVisible={false} source="technicianPassword" required
							               validate={validateTechPassword}/> : ''
					}

					}
				</FormDataConsumer>

				<BooleanInput source="active"/>
			</SimpleForm>
			</Edit>
		) : (
			<div></div>
		)

	}
	else {
		return <Loading/>
	}
}

const MemberFilter = (props) => {
	const translate = useTranslate ();
	return (
		<Filter {...props}>
			<TextInput source="fullName" alwaysOn autoComplete="off"/>
			<TextInput source="city" alwaysOn autoComplete="off"/>
			<SelectInput
				source="type"
				alwaysOn
				choices={[
					{
						id: "paz",
						name: translate (`member.type.paz`)
					},
					{id: "hamezave", name: translate (`member.type.hamezave`)}
				]}
			/>

			<TextInput source="phoneNumber" autoComplete="off"/>
			<ReferenceInput
				label={translate ("reference.complexe")}
				source="complexeId||$eq"
				reference="complexe"
				alwaysOn
				filterToQuery={(searchText) => {
					if (searchText) return {name: searchText};
				}}
			>
				<AutocompleteInput optionText="name" debounce={debounce}/>
			</ReferenceInput>
			<BooleanInput source="active"
			              alwaysOn
			              parse={(val) => {
				              if (val === true) return 1;
				              if (val === false) return 0;
				              return val;
			              }}/>
			<BooleanInput source="technician"
			              alwaysOn
			              defaultValue={false}
			              parse={(val) => {
				              if (val === true) return 1;
				              if (val === false) return 0;
				              return val;
			              }}/>
		</Filter>
	);
};

const MemberShow = (props) => {
	const {
		record // record fetched via dataProvider.getOne() based on the id from the location
	} = useShowController (props);
	const translate = useTranslate ();
	return (
		<Show {...props} title={<MemberTitle/>}>
			<TabbedShowLayout>
				<Tab label={translate ("general.summary")}>
					<TextField source="fullName"/>
					<Labeled label={translate ("general.memberType")}>
						<MemberTypeField source="type"/>
					</Labeled>
					<TextField source="city" validate={required ()}/>
					<TextField source="street"/>
					<TextField source="streetNumber"/>

					<ReferenceField
						label={translate ("reference.complexe")}
						source="complexeId"
						reference="complexe"
						link="show"
					>
						<TextField source="name"/>
					</ReferenceField>
					<TextField source="otherId"/>
					<BooleanField source="technician"/>
					<BooleanField source="active"/>
					<FunctionField label={'QRcode'}
					               render={record => record.technician && record.active ?
						               <QRcode data={record} translate={translate}/> : ''}
					/>


				</Tab>
				<Tab label={translate ("reference.orders")} path="orders">
					<ReferenceManyField reference="order"  target="memberId" addLabel={false}>
						<Orders.OrderDataGrid {...props} />
					</ReferenceManyField>
				</Tab>
			</TabbedShowLayout>
		</Show>
	);
};

const MemberTopToolbar = ({
	                          currentSort,
	                          className,
	                          resource,
	                          filters,
	                          displayedFilters,
	                          exporter, // you can hide ExportButton if exporter = (null || false)
	                          filterValues,
	                          permanentFilter,
	                          hasCreate, // you can hide CreateButton if hasCreate = false
	                          basePath,
	                          selectedIds,
	                          onUnselectItems,
	                          showFilter,
	                          maxResults,
	                          total,
	                          ...rest
                          }) => {
	const {permissions} = usePermissions ();
	const translate = useTranslate ();
	return (
		<TopToolbar className={className} {...sanitizeListRestProps (rest)}>
			{filters &&
				cloneElement (filters, {
					resource,
					showFilter,
					displayedFilters,
					filterValues,
					context: "button"
				})}
			{filters && (
				<Button
					color="primary"
					size="small"
					startIcon={<Close/>}
					onClick={() => rest.setFilters ({})}
				>
					{translate ("general.clearFilter")}
				</Button>
			)}
			{/*{permissions === 'admin' && <CreateButton basePath={basePath} />}*/}
			{permissions === "admin" && (
				<ExportButton
					disabled={total === 0}
					resource={resource}
					sort={currentSort}
					filter={{...filterValues, ...permanentFilter}}
					exporter={() => downloadExcel (Object.values (rest.data), rest.defaultTitle)}
					maxResults={maxResults}
				/>
			)}
		</TopToolbar>
	);
};


const MemberList = (props) => {
	const translate = useTranslate ();
	const {permissions} = usePermissions ();
	return (
		<List
			{...props}
			actions={<MemberTopToolbar/>}
			filters={<MemberFilter/>}
			hasCreate={permissions === "admin"}
			bulkActionButtons={false}

			// bulkActionButtons={permissions === 'admin' ? <MemberActions /> : false}
		>
			<Datagrid rowClick="show" optimized>
				<TextField source="fullName"/>
				<MemberTypeField source="type"/>
				<TextField source="city"/>
				<TextField source="street"/>
				<TextField source="streetNumber"/>
				<WhatsAppField source="phoneNumber"/>

				<ReferenceField
					label={translate ("reference.complexe")}
					source="complexeId"
					reference="complexe"
					link="show"
				>
					<TextField source="name"/>
				</ReferenceField>
			</Datagrid>
		</List>
	);
};
export default {
	list: MemberList,
	edit: MemberEdit,
	show: MemberShow,
	icon: People
};
