export default {
	generation: {
		generation_1: "דור 1",
		generation_2: "דור 1 (יצור מקביל)",
		generation_3: "דור 1.1",
		generation_4: "דור 1.1 שחזור"
	},
	ra: {
		action: {
			add_filter: "הוספת סינון",
			add: "הוספה",
			back: "חזור",
			bulk_actions: "פריט אחד נבחר |||| %{smart_count} פריטים נבחרו",
			cancel: "ביטול",
			clear_input_value: "נקה ערך",
			clone: "שכפל",
			confirm: "אישור",
			create: "יצירה",
			edit: "עריכה",
			delete: "מחיקה",
			export: "יצוא",
			list: "רשימה",
			refresh: "רענן",
			remove_filter: "הסרת סינון",
			remove: "מחיקה",
			save: "שמירה",
			search: "חיפוש",
			download: "הורדה",
			show: "הצג",
			sort: "מיון",
			undo: "ביטול",
			expand: "הרחבה",
			close: "סגירה",
			open_menu: "הצגת תפריט",
			close_menu: "סגירת תפריט",
			unselect: "ביטול בחירה"
		},
		boolean: {
			true: "כן",
			false: "לא",
			null: ""
		},
		page: {
			create: "צור %{name}",
			dashboard: "לוח המחוונים",
			edit: "%{name} #%{id}",
			error: "שגיאה התרחשה",
			list: "רשימת %{name}",
			loading: "טוען",
			not_found: "לא קיים",
			show: "%{name} #%{id}",
			empty: "%{name} לא קיים.",
			invite: "האם ברצונך להוסיף?"
		},
		input: {
			file: {
				upload_several: "גרור מספר קבצים להעלאה או לחץ לבחור אחד.",
				upload_single: "גרור קובץ להעלאה או לחץ לבחור אחד."
			},
			image: {
				upload_several: "גרור מספר תמונות להעלאה או לחץ לבחור אחת.",
				upload_single: "גרור תמונה להעלאה או לחץ לבחור אחת."
			},
			references: {
				all_missing: "ישויות הקשר לא נמצאו.",
				many_missing: "לפחות אחת מישויות הקשר אינה זמינה יותר.",
				single_missing: "ישות הקשר אינה זמינה יותר."
			},
			password: {
				toggle_visible: "הסתרת סיסמא",
				toggle_hidden: "הצגת סיסמא"
			}
		},
		message: {
			about: "אודות",
			are_you_sure: "האם את/ה בטוח/ה ?",
			reCharge: "האם את/ה בטוח/ה שברצונך לחייב?",
			orderAmount: "כמות רכישות",
			changeStatus: "האם את/ה בטוח/ה שברצונך לשנות סטטוס?",
			bulk_delete_content:
				"האם אתה בטוח שברצונך למחוק %{name}? |||| האם אתה בטוח שברצונך למחוק %{smart_count} פריטים?",
			bulk_delete_title: "מחק %{name} |||| מחק %{smart_count} %{name} פריטים",
			delete_content: "האם אתה בטוח שברצונך למחוק את הפריט הזה?",
			delete_title: "מחק %{name} #%{id}",
			details: "פרטים",
			error: "קרתה שגיאת תקשורת והבקשה שלך לא הסתיימה.",
			invalid_form: "הטופס אינו תקין. אנא תקן את שגיאותיך",
			loading: "העמוד טוען, אנא המתן",
			no: "לא",
			yes: "כן",
			not_found: "הכנסת כתובת שגויה או לחצת על קישור לא תקין",
			unsaved_changes:
				"חלק מהשינויים שבוצעו לא נשמרו, האם להתעלם מהשינויים שביצעת?"
		},
		navigation: {
			skip_nav: "",
			no_results: "לא נמצאו תוצאות",
			no_more_results: "עמוד %{page} מחוץ לתחום. אנא נסה את העמוד הקודם.",
			page_out_of_boundaries: "עמוד %{page} מחוץ לתחום",
			page_out_from_end: "לא ניתן להמשיך מעבר לעמוד האחרון",
			page_out_from_begin: "לא ניתן לגשת לפני העמוד הראשון",
			page_range_info: "%{offsetBegin}-%{offsetEnd} מתוך %{total}",
			page_rows_per_page: "מספר שורות לעמוד:",
			next: "הבא",
			prev: "הקודם"
		},
		auth: {
			auth_check_error: "יש להתחבר על מנת להמשיך",
			user_menu: "פרופיל",
			username: "דואר אלקטרוני",
			password: "סיסמא",
			sign_in: "כניסה",
			sign_in_error: "הכניסה נכשלה, אנא נסו שנית",
			logout: "התנתקות"
		},
		notification: {
			empty_shelves: 'נותרו מדפים ריקים בפלנוגרמה:',
			updated: "פריט עודכן בהצלחה",
			created: "פריט נוצר בהצלחה",
			deleted: "פריט נמחק בהצלחה",
			bad_item: "פריט לא תקין",
			item_doesnt_exist: "פריט לא קיים",
			http_error: "שגיאה בהתקשרות מול השרת",
			data_provider_error: "שגיאת dataProvider. בדוק את הקונסול לפרטים נוספים.",
			canceled: "הפעולה בוטלה",
			logged_out: "הנך מנותק, אנא התחבר מחדש."
		},
		validation: {
			required: "חובה",
			minLength: "חייב להכיל לפחות %{min} תווים",
			maxLength: "יכול להכיל לכל היותר %{max} תווים",
			minValue: "חייב להיות לפחות %{min}",
			maxValue: "חייב להיות %{max} או פחות",
			number: "חייב להיות מספר",
			float: "המספר לא יכול להיות באורך של  יותר מ 14 ספרות ולא יותר מ 7 לפני ואחרי הנקודה העשרונית",
			email: 'כתובת דוא"ל לא תקנית',
			oneOf: "חייב להיות מאחת האפשרויות הבאות: %{options}",
			regex: "חייב להתאים לתבנית ספציפית (ביטוי רגולרי): %{pattern}"
		}
	},
	resources: {
		"fridge-event": {
			name: "התראות מקרר"
		},
		user: {
			name: "משתמש |||| משתמשים",
			fields: {
				name: "שם",
				email: "דואר אלקטרוני",
				password: "סיסמא",
				role: "הרשאה"
			}
		},
		member: {
			name: "לקוח |||| לקוחות",
			fields: {
				fullName: "שם מלא",
				phoneNumber: "מספר טלפון סלולרי",
				phoneNumber2: "מספר טלפון נוסף",
				type: "סוג לקוח",
				city: "עיר",
				street: "רחוב",
				streetNumber: "מספר",
				otherId: "מזהה פז",
				technician: 'טכנאי',
				technicianPassword: "סיסמאת טכנאי",
				active: "פעיל"
			}
		},
		card: {
			name: "כרטיס |||| כרטיסים",
			fields: {
				digitalKey: "מספר כרטיס דיגיטלי",
				pysicalKey: "מספר כרטיס מודפס",
				active: "פעיל"
			}
		},
		product: {
			name: "מוצר |||| מוצרים",
			fields: {
				sku: 'מק"ט',
				barcode: "ברקוד",
				imageUrl: "תמונה",
				name: "שם",
				cost: "עלות",
				StandertDeviatio: "סטיית תקן",
				expireAlertDays: "מספר ימים להתראה",
				weight: "משקל",
				price: "מחיר",
				vatIncluded: 'כולל מע"מ',
				active: "פעיל",
				humanReachability: "מגבלת לקיחה אנושית"
			}
		},
		vendor: {
			name: "ספק |||| ספקים",
			fields: {
				name: "שם",
				contact: "איש קשר",
				phoneNumber: "מספר טלפון"
			}
		},
		warehouse: {
			name: "מחסן |||| מחסנים",
			fields: {
				name: "שם",
				city: "עיר",
				street: "רחוב",
				streetNumber: "מספר",
				isFridge: "האם מקרר?"
			}
		},
		inventory: {
			name: "מלאי |||| מלאי",
			fields: {
				locationId: "מזהה מיקום",
				productId: "מזהה מוצר",
				warehouseId: "מזהה מחסן",
				quantity: "כמות",
				expiration_time: "תאריך תפוגה"
			}
		},
		transaction: {
			name: "טרנזקציה |||| טרנזקציות",
			fields: {
				locationId: "מזהה מיקום",
				productId: "מזהה מוצר",
				warehouseFromId: "מזהה מחסן מעביר",
				warehouseToId: "מזהה מחסן מקבל",
				quantity: "כמות",
				expiration_time: "תאריך תפוגה"
			}
		},
		"transaction-type": {
			name: "סוג טרנזקציה |||| סוגי טרנזקציות",
			fields: {
				name: "שם",
				hasFromWarehouse: "קיים מחסן מעביר",
				fromAllowFridge: "אפשר מקרר כמעביר",
				hasToWarehouse: "קיים מחסן מקבל",
				toAllowFridge: "אפשר מקרר כמקבל"
			}
		},
		fridge: {
			name: "מקרר |||| מקררים",
			fields: {
				fridgeSettings: {cameraRotation: 'היפוך מצלמה'},
				algoretailLinked: "מקושר לאלגוריטייל",
				noNetwork: "אין רשת במשך יותר משעה",
				network: "סטטוס רשת",
				lastAlert: "התראה אחרונה",
				serial: "מספר סיראלי",
				active: "פעיל",
				errorCode: "קוד שגיאה",
				lastPing: "תאריך בדיקת חיים",
				firmwareVersion: "גרסת קושחה",
				hardwareCardVersion: "גרסת כרטיס חומרה",
				apkVersion: "גרסת APK",
				whatsappLink: "קישור לקבוצת whatsapp",
				otherId: "מזהה פז",
				offlineDurationInHours: 'מס. שעות באופליין',
				'fridgeStructureId': "מבנה מקרר",
				"fridgeStructureId-helper-text": 'כל שינוי של מבנה מקרר מחייב עדכון פלנוגרמה'
			},
			errorCode: {
				'error1': 'אין רשת במשך יותר משעה',
				'error2': ' מקרר לא פעיל בBO',
				'error3': 'אין חיבור USB',
				'error4': 'דלת פתוחה',
				'error5': ' מספר מקרר לא הוגדר',
				'error6': '  אין זכרון ב offline',
				'error7': 'קובץ קונפיגורציה חסר',
				'error8': 'מקרר ללא טוקן',
				'error9': 'אין מוצרים בזכרון',
				'error10': 'דף שגיאה',
				'error11': 'אין פלנוגרמה בזכרון'

			}
		},
		"fridge-logs": {
			name: "לוגים של מקרר",
			fields: {
				temperature: "טמפרטורה",
				humidity: "לחות",
				weightsNotActive: "משקלים לא פעילים"
			}
		},
		"weight-event": {
			name: "אירועי משקל",
			oneOrTwo: "אנא בחר אירוע אחד או שנים בלבד",
			warnDifferentSerials: "אנא בחר את אירוע עם אותו מספר מקרר",
			differentShelves: "אין אפשרות לבצע הפרש כמות המדפים במקררים שונה",
			difference: "יצא חישוב הפרש מלאי במקרר בין האירועים",
			inventory: "יצא מלאי מקרר באירוע זה",
			events: {
				startPurchase: "התחלת רכישה",
				endPurchase: "סיום רכישה",
				startFilling: "התחלת מילוי",
				endFilling: "סיום מילוי",
				technician: "ביקור טכנאי",
				bootUp: "אפליקציה עלתה מחדש"
			},
			fields: {
				row: "מדף",
				column: "עמודה",
				timeStamp: "תאריך",
				amountOfProducts: "כמות מוצרים",
				eventType: "סוג אירוע"

			}
		},
		location: {
			name: "מיקום |||| מיקומים",
			fields: {
				hardwareId: "מזהה חומרה",
				row: "מדף",
				column: "עמודה",
				standard_quantity: "כמות תקן",
				maximum: "מקסימום"
			}
		},
		tenant: {
			name: "זכיין |||| זכיינים",
			fields: {
				name: "שם",
				contact: "איש קשר",
				phoneNumber: "מספר טלפון",
				area: "אזור"
			}
		},
		'fridge-structure': {
			name: 'מבנה מקרר',
			fridgeStructureName: "שם מבנה מקרר",
			id: "מזהה מבנה מקרר"
		}, planogram: {
			name: 'פלנוגרמה',
			msg: 'פלנוגרמה משויכת לפרופיל, כל עדכון שלה ישפיע על כל המקררים המשויכים לפרופיל',
			planogramName: 'שם פלנוגרמה',
			ofFridge: 'הסתר פלנוגרמות מקררים'

		}, profile: {
			name: 'פרופיל',
			noFridges: 'לא נמצאו מקררים',
			purchaseLimit: 'מגבלת רכישה',
			profileName: 'שם פרופיל',
			'profiles.planogram': 'פלנוגרמה רצויה',
			fridges: 'מקררים משויכים',
			desiredPlanogram: 'פלנוגרמה רצויה',
			desiredApk: 'גרסת Apk רצויה ',
			releaseChannels: 'ערוץ',
			desiredFirmware: 'גרסת קושחה רצויה',
			includedStructure: 'מבני מקרר ופלנוגרמות',
			select: 'בחר מקררים ופלנוגרמה'
		},
		order: {
			name: "רכישה |||| רכישות",
			fields: {
				total: 'סה"כ',
				payTransactionId: "מזהה עסקה mPay",
				id: "מזהה עסקה",
				uuid: "מזהה עסקה טאבלט",
				status: "סטטוס",
				memberId: "מזהה לקוח",
				cardId: "מזהה כרטיס",
				lines: "שורות רכישה",
				isOfflineOrder: "רכישה במצב Offline",
				errorCode: "קוד שגיאה פז",
				errorMessage: "תיאור שגיאה פז",
				tranzilaConfirmationCode: "קוד עסקה טרנזילה",
				merchant: "סוג עסקה",
				identificationMethod: "שיטת זיהוי",
				createdAt_lte: "עד תאריך",
				createdAt_gte: "מתאריך",
				manualAction: "שינוי ידני"
			},
			actions: {
				updateStatusToSuccess: "עדכן סטטוס להצלחה",
				reCharge: "חייב מחדש"
			}
		},
		"order-old": {
			name: "רכישה ישנה |||| רכישות ישנות",
			fields:{
				withOldOrders: "עם רכישות ישנות"
			}
		},
		"order-line": {
			name: "שורות רכישה |||| שורות רכישות",
			fields: {
				subtotal: 'סה"כ שורה',
				pricePerUnit: "מחיר ליחידה",
				quantity: "כמות",
				productId: "מזהה מוצר",
				orderId: "מזהה רכישה",
				location: "מיקום"
			}
		},
		"release-channels": {
			name: "ערוצים",
			fields: {
				displayName: "שם תצוגה",
				name: "שם",
				default: "ברירת מחדל",
				lastHardwareVersion: "גרסת Hardware"

			}
		},
		complexe: {
			name: "מתחם |||| מתחמים",
			fields: {
				name: "שם",
				city: "עיר",
				street: "רחוב",
				streetNumber: "מספר",
				active: "פעיל",
				otherId: "מזהה פז",
				moreInfo: "פרטים נוספים",
				shortName: "שם קצר",
				zipCode: "מיקוד",
				postalCode: "קוד דואר",
				classification: "סיווג"
			}
		},
		promotion: {
			name: "מבצע |||| מבצעים",
			fields: {
				validFrom: "מתאריך",
				validTo: "עד תאריך",
				type: "סוג מבצע",
				active: "פעיל",
				ended: "הסתיים"
			},
			type: {
				name: "סוג מבצע",
				general: "מבצע כללי",
				member: "מבצע למשתמש",
				complexe: "מבצע למתחם"
			}
		},
		settings: {
			name: "הגדרות",
			fields: {
				key: "מפתח",
				value: "ערך"
			}
		},
		"upload-inventory": {
			name: "עדכון מלאי"
		}

	},

	reference: {
		upToDate: 'הפלנוגרמה מעודכנת',
		planogram: 'פלנוגרמה',
		noProfile: 'אין פרופיל',
		insertInventoryFile: 'הכנס/י את קובץ האקסל בהתאם  להנחיות ולפורמט',
		fillDate: 'תאריך עדכון מלאי',
		profile: 'פרופיל',
		generation: 'דור לוח אלקטרוני',
		card: 'כרטיס',
		cards: 'כרטיסים',
		user: 'משתמש',
		users: 'משתמשים',
		member: 'לקוח',
		members: 'לקוחות',
		product: 'מוצר',
		products: 'מוצרים',
		vendor: 'ספק',
		vendors: 'ספקים',
		warehouse: 'מחסן',
		warehouses: 'מחסנים',
		location: 'מיקום',
		locations: 'מיקומים',
		'transaction-type': 'סוג טרנזקציה',
		'transaction-types': 'סוגי טרנזקציות',
		tenant: 'זכיין',
		tenants: 'זכיינים',
		inventory: 'מלאי',
		fridge: 'מקרר',
		fridge_settings: 'הגדרות מקרר',
		fridges: 'מקררים',
		'fridge-logs': 'לוגים של מקרר',
		'order-line': 'שורות רכישה',
		order: 'רכישה',
		orders: 'רכישות',
		'release-channels': 'ערוצים',
		complexe: 'מתחם',
		complexes: 'מתחמים',
		promotion: 'מבצע',
		promotions: 'מבצעים',
		settings: 'הגדרות',
		fridgeStructure: 'מבנה מקרר'
	},
	status: {
		success: "הצלחה",
		pending: "ממתין",
		declined: "נדחה",
		empty_cart: "עגלה ריקה",
		exceptional: "חריג",
		temporary: "זמני",
		technician: "טכנאי",
		sentToRecharge: "נשלח לחיוב מחדש"
	},
	manualAction: {
		"Modified status": "סטטוס שונה",
		success: "הצלחה",
		pending: "ממתין",
		declined: "נדחה",
		exceptional: "חריג",
		recharged: "חויב מחדש"
	},
	general: {
		'moreThan10': 'יותר מ 10 מקררים פתח לצפיה',
		'version-changes': 'שינוי גרסא',
		'version': 'גרסא',
		'startDate': 'תאריך עדכון',
		'endDate': 'תאריך סיום',
		fridge_settings: "הגדרות מקרר",
		getNewHardwareVersions: 'משוך גרסאות hardware עדכניות',
		weightOffset: 'סחיפת משקלים',
		height: 'גובה',
		width: 'רוחב',
		depth: 'עומק',
		removeProfile: 'הסר מפרופיל',
		serverTime: 'זמן שמירה בשרת',
		purchaseDate: 'תאריך רכישה',
		openToSee: 'לחצו לצפייה בכל ההודעות',
		comment: 'הערה',
		alert: 'התראה',
		weightErrorId: 'מזהה שגיאת משקל',
		alertNum: 'מספר התראות',
		isError: 'שגיאה',
		memberId: 'מזהה משתמש',
		fullName: 'שם מלא',
		viewed: 'נצפה',
		completed: 'טופל',
		error: 'שגיאה',
		weight: 'משקל',
		weightId: 'מזהה משקל',
		message: 'הודעה',
		currentPlanogram: 'פלונגרמה נוכחית',
		desiredPlanogram: 'פלונגרמה רצויה',
		shelfSize: 'סוג מדף',
		changePlanogram: 'עדכן לפלנוגרמה רצויה',
		network: 'רשת',
		tareGram: 'משקל התחלתי',
		delta: 'דלתא',
		calibrate: 'כיול',
		summary: "סיכום כללי",
		status: "סטטוס",
		statusApk: "סטטוס  פינג לפי גרסה",
		statusOrderApk: "סטטוס  רכישות יומי לפי גרסה",
		rowandcolumn: "מדף ועמודה",
		warehouseFrom: "מחסן מעביר",
		warehouseTo: "מחסן מקבל",
		items: "מוצרים",
		quantity: "כמות",
		expiration_time: "תאריך תפוגה",
		shelves: "מדפים",
		row: "מדף",
		column: "עמודה",
		createNewWarehouse: "יצירת מחסן חדש",
		city: "עיר",
		street: "רחוב",
		streetNumber: "מספר",
		standard_quantity: "כמות תקן",
		hardwareId: "מזהה חומרה",
		closestExpDate: "תוקף קרוב ביותר",
		createTransactionButton: "יצירת טרנזקציות",
		fromAllowFridge: "אפשר מקרר כמעביר",
		toAllowFridge: "אפשר מקרר כמקבל",
		save: "שמירה",
		cancel: "ביטול",
		total: 'סה"כ מוצרים בעמודה:',
		expiryInventory: "מלאי לפי תאריך תפוגה",
		totalInventory: "מלאי לפי מוצר",
		logs: "לוגים",
		key: "מפתח",
		value: "ערך",
		createdAt: "נוצר בתאריך",
		phoneNumber: "מספר טלפון",
		image: "תמונה",
		addImage: "הוספת תמונה",
		showFridge: "האם להציג מקררים ?",
		locationNumber: "מספר משקלים",
		isPlanogramFull: 'פלנוגרמה ריקה ממוצרים',
		importCards: "טעינת כרטיסים",
		fridgeStatus: "סטטוס מלאי במקררים",
		maximum: "מקסימום",
		createdAfter: "נוצר לאחר",
		gap: "פער",
		statusByFridge: "סטטוס ברמת מקרר",
		charge: "לבצע חיוב",
		backToFridge: "חזרה לכל המקררים",
		changeProduct: "החלפת מוצר",
		addQuantity: "הוספת מלאי",
		removeQuantity: "הורדת מלאי",
		editExpDates: "עריכת תקפים",
		switchLanguage: "שינוי שפה",
		reports: 'דו"חות',
		recharge: "גביה חוזרת",
		fridgeAssociation: "שיוך למתחם",
		fridgeStructure: "מבנה מקרר",
		detailsCorrect: "הפרטים תואמים",
		digitalKey: "מספר כרטיס דיגיטלי",
		pysicalKey: "מספר כרטיס מודפס",
		memberType: "סוג לקוח",
		pricePerUnit: "מחיר יחידה",
		subtotal: 'סה"כ שורה',
		price: "מחיר",
		validFrom: "מתאריך",
		validTo: "עד תאריך",
		active: "פעיל",
		promotionDates: "תאריכי מבצע",
		serial: "מספר סיראלי",
		generateFridgeToken: "החלפת טוקן למקרר",
		clickHereToGenerateFridgeToken: "יצירת טוקן חדש למקרר",
		lastUse: "שימוש אחרון",
		role: "הרשאה",
		complexeConvert: "העברת תוכן בין מתחמים",
		fromComplexe: "ממתחם",
		toComplexe: "למתחם",
		classification: "סיווג",
		moreInfo: "מידע נוסף",
		pazComplexe: "מתחם פז",
		pazFridge: "מקררים המשויכים לפז",
		associatedFridges: "מקררים משוייכים",
		fridgeCount: "כמות מקררים",
		zero: "ללא מקררים",
		all: "הכל",
		clearFilter: "נקה פילטרים",
		algoRetailToken: "טוקן אלגו ריטייל",
		updatedAt: "עודכן בתאריך",
		amountOfProducts: "כמות מוצרים שנלקחה/נוספה חישוב מקרר",
		tareWeightCurrent: "משקל במדף",
		amountOfProductsByWeights: " כמות מוצרים לפי משקל",
		calcBo: "מוצרים לפי  BO",
		amountOfProductsByWeightsNoDrift: " כמות מוצרים לפי משקל ללא סחיפה",
		absoluteWeight: "משקל אבסולוטי"
	},
	promotion: {
		type: {
			name: "סוג מבצע",
			general: "מבצע כללי",
			member: "מבצע למשתמש",
			complexe: "מבצע למתחם"
		}
	},
	member: {
		type: {
			paz: "פז",
			hamezave: "המזווה"
		}
	},
	merchant: {
		paz: "פז",
		hamezave: "המזווה"
	},
	user: {
		role: {
			admin: 'מנהל',
			viewer: 'צופה',
			customerSupport: 'שירות לקוחות',
			driver: 'נהג'
		}
	},
	title: {
		fridgeStructureShow: "צפייה במבנה מקרר %{name} ",
		planogramShow: "צפיה בפלנוגרמה %{name}",
		transactionCreate: "יצירת טרנזקציה",
		locationCreate: "יצירת מיקומים במקרר",
		locationShow: "צפייה במקרר",
		editProductInLocation: "תאריכי תוקף %{product}",
		fridgeStatus: "סטטוס מלאי במקררים",
		statusByFridge: "סטטוס מלאי ברמת מקרר",
		changeProductInLocation: "החלפת מוצר",
		fridgeAssociation: "שיוך כרטיסים למתחם",
		complexeConvert: "העברת תוכן בין מתחמים",
		calibrateUpdate: "עדכון כיול",
		offsetUpdate: "עדכון סחיפת משקלים"

	},
	summary: {
		excel: {
			title: "העלאת מלאי  מקררים באקסל"
		},
		fridge: {
			title: 'מקרר נוצר בהצלחה',
			create: {
				successMsg: 'מקרר  נוצר בהצלחה',
				failMsg: 'יצירת  מקרר נכשלה'

			},
			edit: {
				successMsg: 'מקרר נערך בהצלחה',
				failMsg: 'עריכת  מקרר נכשלה'
			}
		},
		transaction: {
			title: "סיכום טרנזקציות",
			successMsg:
				"טרנזקציה נוצרה בהצלחה |||| %{smart_count} טרנזקציות נוצרו בהצלחה"
		},
		location: {
			title: "מיקומים במקרר",
			create: {
				successMsg:
					"מיקום נוצר בהצלחה |||| %{smart_count} מיקומים נוצרו בהצלחה"
			},
			edit: {
				successMsg:
					"מיקום נוצר בהצלחה |||| %{smart_count} מיקומים נוצרו בהצלחה"
			}
		},
		card: {
			title: "סיכום טעינת כרטיסים",
			successMsg: "כרטיס נוצר בהצלחה |||| %{smart_count} כרטיסים נוצרו בהצלחה"
		},
		order: {
			title: "חיוב ידני",
			successMsg: "חיוב עבר בהצלחה |||| %{smart_count} חיובים עברו בהצלחה"
		},
		promotion: {
			title: "סיכום יצירת מבצעים",
			successMsg: "מבצעים נוצרו בהצלחה"
		},
		settings: {
			setSettings: "הגדרות עודכנו בהצלחה",
			setSettingsFailed: "הגדרות לא עודכנו ",
			updateAlgoRetailToken: "טוקן נשמר בהצלחה"
		},

		planogram: {
			create: {
				successMsg: 'פלנוגרמה נוצרה בהצלחה',
				failMsg: ' יצירת פלנוגרמה נכשלה'

			},
			edit: {
				successMsg: ' פלונגרמה נערכה בהצלחה',
				failMsg: ' עריכת פלנוגרמה נכשלה'

			},
			replace: {
				successMsg: ' פלנוגרמות הוחלפו בהצלחה',
				failMsg: ' החלפת פלנוגרמות נכשלה'

			}
		},
		profile: {
			edit: {
				successMsg: 'פרופיל נערך בהצלחה',
				failMsg: ' עריכת פרופיל נכשלה'

			},
			create: {
				successMsg: 'פרופיל נוצר בהצלחה',
				failMsg: ' יצירת פרופיל נכשלה'

			},
			remove: {
				successMsg: 'המקרר הוסר בהצלחה מהפרופיל',
				failMsg: 'המקרר לא הוסר מן הפרופיל'
			}

		}
		,
		fridgeStructure: {
			create: {
				successMsg: 'מבנה מקרר נוצר בהצלחה',
				failMsg: 'יצירת מבנה מקרר נכשלה'

			},
			edit: {
				successMsg: ' מבנה מקרר נערך בהצלחה',
				failMsg: 'עריכת מבנה מקרר נכשלה'
			}
		}
	},
	error: {
		transaction: {
			twoFridges: "העברה בין שני מקררים אינה אפשרית"
		},
		order: {
			orderChangeFailed: "העדכון נכשל יש לבחור רכישות מאותו סטטוס ",
			orderNotFound: "הרכישות לא נמצאו"
		},
		inventory: {
			twoWarehouses: "יצירת טרנזקציות לשני מחסנים שונים או יותר אינה אפשרית"
		},
		planogram: {
			minusZero: 'לא ניתן להפחית מכמות 0',
			moreThanQuantity: 'הכמות שהוזנה שונה מהכמות הקיימת על המדף',
			sameExpirationTime: 'קיימות רשומות עם תאריך זהה, יש לאחד את הכמות',
			maximumMustBeBiggerThanStandardQuantity:
				"מקסימום חייב להיות גדול או שווה לכמות התקן"
		},
		product: {
			noImage: "לא קיימת תמונה"
		}
	},
	classification: {
		private: "פרטי",
		public: "ציבורי",
		none: "ללא"
	}
};
