import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { url,staging } from "./config/connection";

const setConstants = async () => {
  const request = new Request(`${url}/status`, {
    method: "GET",
    headers: new Headers({ "Content-Type": "application/json" ,'AB-testing':staging}),
  });
  const response = await fetch(request);
  const data = await response.json();
  const { orderTransactionType, transferTransactionType, encryption,version } = data;
  localStorage.setItem("orderTransactionType", orderTransactionType);
  localStorage.setItem("static_data", encryption);
  localStorage.setItem("version", version);
  localStorage.setItem("transferTransactionType", transferTransactionType);
};

(function () {
  setConstants();
})();

ReactDOM.render(<App  />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
